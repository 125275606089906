import React from 'react';
import { ActivityIndicator, ImageURISource, TouchableOpacity, View } from 'react-native';
import ImageView from 'react-native-image-viewing';
import { RootSiblingParent } from 'react-native-root-siblings';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import useDownloadImage from '../hooks/useDownloadImage';

import { Ionicons } from '@/modules/shared/libs/icons';

type ImageViewProps = React.ComponentProps<typeof ImageView>;

type Props = {
  imageIndex: number;
  images: ImageViewProps['images'];
  visible: ImageViewProps['visible'];
  onRequestClose: ImageViewProps['onRequestClose'];
  showDownload?: boolean;
};

const HeaderComponent = ({
  imageIndex,
  images,
  onClose,
  showDownload = true,
}: {
  imageIndex: number;
  onClose: () => void;
  images: string[];
  showDownload?: boolean;
}) => {
  const inset = useSafeAreaInsets();
  const { handleDownload, pending } = useDownloadImage();

  return (
    <View style={{ paddingTop: inset.top }} className="pt-4 bg-white">
      <View className="h-12 flex-row px-4 items-center justify-between">
        <TouchableOpacity className="p-2" onPress={onClose}>
          <Ionicons name="close" size={24} />
        </TouchableOpacity>
        {showDownload && (
          <TouchableOpacity
            className="p-2 "
            onPress={() => {
              handleDownload(images[imageIndex]);
            }}>
            {pending ? <ActivityIndicator /> : <Ionicons name="download-outline" size={24} />}
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

const ImageViewerModal = ({ showDownload = true, ...props }: Props) => {
  return (
    <ImageView
      {...props}
      HeaderComponent={({ imageIndex }) => (
        <HeaderComponent
          images={props.images.map((i) => (i as ImageURISource).uri) as string[]}
          imageIndex={imageIndex}
          onClose={props.onRequestClose}
          showDownload={showDownload}
        />
      )}
      FooterComponent={() => {
        return (
          <RootSiblingParent>
            <></>
          </RootSiblingParent>
        );
      }}
      backgroundColor="rgba(0,0,0,0.8)"
      presentationStyle="overFullScreen"
    />
  );
};

export default ImageViewerModal;
