import React from 'react';
import { Image, Pressable, Text, View } from 'react-native';

import { assetList } from '@/modules/shared/types';

type Props = {
  onCancel: () => void;
  onOk: () => void;
};

const CancelLobbyBottomSheet = ({ onCancel, onOk }: Props) => {
  return (
    <View className="px-4 pt-4">
      <Image className="h-32 w-32 self-center" source={{ uri: assetList.WARN }} />
      <Text className="text-center font-semibold mt-4">Batalkan Pesanan?</Text>
      <Text className="text-center my-4 text-gray-500">
        Kamu tidak akan menerima pengembalian dana karena sudah melebih batas waktu
      </Text>
      <Pressable className="bg-red-500 rounded-md w-full items-center py-3">
        <Text className="text-white font-semibold text-base" onPress={onOk}>
          Batalkan
        </Text>
      </Pressable>
      <Pressable className="rounded-md w-full items-center py-3 mt-3" onPress={onCancel}>
        <Text className="text-playard font-semibold text-base">Kembali</Text>
      </Pressable>
    </View>
  );
};

export default CancelLobbyBottomSheet;
