import {
  BottomSheetBackdrop,
  BottomSheetModal,
  BottomSheetModalProps,
  BottomSheetView,
  useBottomSheetDynamicSnapPoints,
} from '@gorhom/bottom-sheet';
import React, { useCallback, useImperativeHandle, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export type BottomSheetProps = Omit<
  BottomSheetModalProps,
  'snapPoints' | 'handleHeight' | 'contentHeight' | 'children'
> & { enableBackdropPress?: boolean };
export type BottomSheetContentElement = ({ close }: { close: () => void }) => React.ReactElement;

export type DynamicBottomSheetRef = {
  show: (args: {
    content?: BottomSheetContentElement;
    bottomSheetProps?: BottomSheetProps;
  }) => void;
};

const DynamicBottomSheet = React.forwardRef<DynamicBottomSheetRef, object>((_, ref) => {
  const bottomSheetRef = React.useRef<BottomSheetModal>(null);
  const { bottom } = useSafeAreaInsets();
  const [content, setContent] = React.useState<React.ReactElement | undefined>();
  const [props, setProps] = React.useState<BottomSheetProps>({
    enableBackdropPress: true,
  });

  const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], []);

  const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
    useBottomSheetDynamicSnapPoints(initialSnapPoints);

  const handleClose = useCallback(() => {
    bottomSheetRef.current?.dismiss();
    setTimeout(() => {
      setContent(undefined);
      setProps({});
    }, 200);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        show: ({ content, bottomSheetProps = {} }) => {
          setContent(content?.({ close: handleClose }));
          setProps({ ...props, ...bottomSheetProps });
          bottomSheetRef.current?.present();
        },
      };
    },
    [handleClose],
  );

  return (
    <BottomSheetModal
      ref={bottomSheetRef}
      {...props}
      snapPoints={animatedSnapPoints}
      handleHeight={animatedHandleHeight}
      contentHeight={animatedContentHeight}
      backdropComponent={(backdropProps) => (
        <BottomSheetBackdrop
          {...backdropProps}
          pressBehavior={props?.enableBackdropPress ? 'close' : 'none'}
          opacity={0.5}
          enableTouchThrough={false}
          appearsOnIndex={0}
          disappearsOnIndex={-1}
          style={[{ backgroundColor: 'rgba(0, 0, 0, 1)' }, StyleSheet.absoluteFillObject]}
        />
      )}>
      <BottomSheetView style={{ paddingBottom: bottom }} onLayout={handleContentLayout}>
        {Boolean(content) && content}
      </BottomSheetView>
    </BottomSheetModal>
  );
});

export default DynamicBottomSheet;
