import { zodResolver } from '@hookform/resolvers/zod';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import * as AppleAuthentication from 'expo-apple-authentication';
import { Image } from 'expo-image';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { z } from 'zod';

import AnimatedTextInput from '../components/AnimatedTextInput';
import PlButton from '../components/PlButton';
import { checkInAppBrowser } from '../utils/checkInAppBrowser';

import { UnauthorizedStackParamList } from '@/modules/navigation/types';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

const registerSchema = z.object({
  email: z
    .string({
      required_error: 'Email tidak boleh kosong',
      invalid_type_error: 'Email tidak valid',
    })
    .email(),
  password: z
    .string({
      required_error: 'Password tidak boleh kosong',
      invalid_type_error: 'Password Minimal 6 karakter',
    })
    .min(6),
});

type RegisterSchema = z.infer<typeof registerSchema>;

export const RegisterScreen = () => {
  const { signInWithApple, registerWithEmailAndPassword, signInGoogle } = useAuth();
  const { bottom } = useSafeAreaInsets();
  const [isAppleAuthAvailable, setIsAppleAuthAvailable] = useState(false);
  const [isInAppBrowser] = useState(checkInAppBrowser());
  const navigation = useNavigation<NavigationProp<UnauthorizedStackParamList>>();

  const { control, handleSubmit } = useForm<RegisterSchema>({
    resolver: zodResolver(registerSchema),
  });

  const handleLogin = () => {
    navigation.reset({
      index: 1,
      routes: [{ name: 'WelcomeScreen' }, { name: 'LoginScreen' }],
    });
  };

  const onRegister = async (data: RegisterSchema) => {
    await registerWithEmailAndPassword(data.email, data.password);
  };

  useEffect(() => {
    const checkAppleAuth = async () => {
      const isAvailable = await AppleAuthentication.isAvailableAsync();
      setIsAppleAuthAvailable(isAvailable);
    };

    checkAppleAuth();
  }, []);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{ backgroundColor: '#fff', flex: 1, paddingHorizontal: 20 }}
        keyboardShouldPersistTaps="handled">
        <View style={{ marginTop: 48 }}>
          {isAppleAuthAvailable && (
            <PlButton
              onPress={() =>
                isInAppBrowser ? navigation.navigate('InAppBrowserErrorScreen') : signInWithApple()
              }
              title="Daftar dengan Apple"
              type="black"
              customStyle={{ borderRadius: 100, marginBottom: 20 }}
              Icon={() => (
                <Image
                  source={require('../assets/apple_logo.png')}
                  style={{ height: 20, width: 20 }}
                />
              )}
            />
          )}

          <PlButton
            onPress={() =>
              isInAppBrowser ? navigation.navigate('InAppBrowserErrorScreen') : signInGoogle()
            }
            title="Daftar dengan Google"
            type="normal"
            Icon={() => (
              <Image
                source={require('../assets/google_logo.png')}
                style={{ height: 20, width: 20 }}
              />
            )}
          />
        </View>

        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 38 }}>
          <View style={styles.line} />
          <View style={{ marginHorizontal: 16 }}>
            <Text>Atau</Text>
          </View>
          <View style={styles.line} />
        </View>

        <View style={{ marginTop: 40 }}>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <AnimatedTextInput
                isError={!!error?.message}
                message={error?.message}
                label="Email"
                required
                onChangeText={(text) => field.onChange(text)}
                value={field.value}
              />
            )}
          />
        </View>
        <View style={{ marginTop: 20 }}>
          <Controller
            control={control}
            name="password"
            render={({ field, fieldState: { error } }) => (
              <AnimatedTextInput
                isError={!!error?.message}
                message={error?.message}
                label="Password"
                required
                secureTextEntry
                onChangeText={(text) => field.onChange(text)}
                value={field.value}
              />
            )}
          />
        </View>

        <Text style={{ textAlign: 'center', marginTop: 32 }}>
          Sudah Punya Akun ?{' '}
          <Text style={{ color: '#5F48B9' }} onPress={handleLogin}>
            Login
          </Text>
        </Text>
      </ScrollView>

      <View style={{ paddingHorizontal: 20, paddingBottom: bottom + 16 }}>
        <PlButton
          title="Daftar"
          customStyle={{ borderRadius: 8 }}
          onPress={handleSubmit(onRegister)}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  line: {
    height: 1,
    backgroundColor: '#eee',
    width: '100%',
    flex: 1,
  },
});
export default RegisterScreen;
