import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';

// import { db } from '../libs/firebase/app';

import { UserDetail } from '../types';

import { db } from '@/modules/shared/libs/firebase/app';

export const detailUser = async (userId: string) => {
  const userRef = doc(db, 'users', userId);
  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists) {
    return;
  }

  return userSnapshot.data() as UserDetail;
};

export const updateUser = async (
  userId: string,
  data: Partial<UserDetail>,
  skipCheck = false,
  hasPhoto = false,
) => {
  console.log('updating user', userId, data, skipCheck, hasPhoto);
  if (!skipCheck) {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('username', '==', data.username));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const existingUser = querySnapshot.docs[0].data();
      if (existingUser.id !== userId) {
        throw new Error('Username sudah terpakai');
      }
    }
  }
  const userRef = doc(db, 'users', userId);
  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists) {
    throw new Error('User not found');
  }

  await updateDoc(userRef, {
    ...data,
  });
};
