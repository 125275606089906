import { Pressable, Text, View } from 'react-native';

import { cn } from '../utils/cn';

type Props = {
  activeIndex: number;
  onChange: (index: number) => void;
  segments: string[];
};

const SegmentedControl = ({ activeIndex, onChange, segments }: Props) => {
  return (
    <View className="flex-row p-1 bg-gray-200 rounded-lg space-x-2">
      {segments.map((segment, index) => (
        <Pressable
          onPress={() => onChange?.(index)}
          key={String(index)}
          className={cn('flex-1 rounded-md p-1', {
            'bg-playard': activeIndex === index,
          })}>
          <Text
            className={cn('text-white text-center font-medium', {
              'text-gray-500': activeIndex !== index,
            })}>
            {segment}
          </Text>
        </Pressable>
      ))}
    </View>
  );
};

export default SegmentedControl;
