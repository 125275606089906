import { doc, DocumentData, getDoc } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';

import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { db } from '@/modules/shared/libs/firebase/app';

type UserDetail = {
  followers: string[];
  following: string[];
  joinedLobbies: string[];
  photoURL: string;
  displayName: string;
  uid: string;
};

const useUserFollowers = (userId?: string) => {
  const { currentUser } = useAuth();
  const [followers, setFollowers] = useState<DocumentData[]>([]);

  const fetchProfileDetail = useCallback(async () => {
    if (!currentUser) return;

    const userRef = doc(db, 'users', userId ? userId : currentUser?.uid);
    const snapshot = await getDoc(userRef);
    const data = (snapshot.data() || null) as UserDetail | null;
    const userFollowers = data?.followers ?? [];

    const followers = await Promise.all(
      userFollowers.map(async (uid) => {
        const lobbyRef = doc(db, 'users', uid);
        const lobbyDoc = await getDoc(lobbyRef);
        return {
          ...lobbyDoc.data(),
          uid,
        };
      }),
    );

    setFollowers(followers);
  }, [currentUser, userId]);

  useEffect(() => {
    fetchProfileDetail();
  }, [fetchProfileDetail]);

  return { followers, refetch: fetchProfileDetail };
};

export default useUserFollowers;
