import { Image } from 'expo-image';
import React from 'react';
import { Pressable, View } from 'react-native';

import Text from '@/modules/shared/components/basic/Text';
import useImagePicker from '@/modules/shared/hooks/useImagePicker';

type Props = {
  onChange?: (value: string) => void;
  value?: string;
};

const CreateGroupCover = ({ onChange, value }: Props) => {
  const { pickImage } = useImagePicker();

  const handlePress = async () => {
    const image = await pickImage();
    if (image) {
      onChange?.(image);
      console.log('cover image', image);
    }
  };

  if (value) {
    return (
      <Pressable
        onPress={handlePress}
        className="bg-neutral-100/80 overflow-hidden rounded-lg border border-neutral-200">
        <Image
          cachePolicy="memory-disk"
          source={{ uri: value }}
          className="w-full h-40 object-cover"
        />
      </Pressable>
    );
  }

  return (
    <Pressable
      onPress={handlePress}
      className="bg-neutral-100/80 p-3 rounded-lg border border-neutral-200">
      <View className="p-6 border border-neutral-200 rounded-md border-dashed">
        <Text fontFamily="InterBold" className="text-center text-neutral-400">
          Gambar Cover Grup
        </Text>
      </View>
    </Pressable>
  );
};

export default CreateGroupCover;
