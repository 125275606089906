import { doc, getDoc } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';

import { db } from '@/modules/shared/libs/firebase/app';
import { Lobby } from '@/modules/shared/types';

const useDetailLobby = (id: string) => {
  const [lobby, setLobby] = useState<Lobby | null>(null);
  const [loading, setLoading] = useState(true);
  const [shouldRefetch, refetch] = useState({});

  const fetchLobby = useCallback(async () => {
    setLoading(true);
    const ref = doc(db, 'lobbies', id);
    const snapshot = await getDoc(ref);
    const data = snapshot.data() || null;

    setLobby({ id, ...data } as Lobby);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    console.log('refetching', shouldRefetch);
    fetchLobby();
  }, [fetchLobby, shouldRefetch]);

  return { loading, lobby, refetch };
};

export default useDetailLobby;
