import { useMutation, useQueryClient } from '@tanstack/react-query';
import Toast from 'react-native-root-toast';

import { useNavigation } from '@/modules/navigation/RootNavigation';
import { updateGroup, GroupSchema } from '@/modules/shared/api/group';
import {
  K_GROUPS_QUERY_KEY,
  K_GROUP_DETAIL_QUERY_KEY,
} from '@/modules/shared/constants/react-query';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

const useMutateUpdateGroup = () => {
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: (input: GroupSchema & { id: string }) =>
      updateGroup(input, String(currentUser?.uid)),
    onError: () => {
      Toast.show('Gagal update grup', {
        backgroundColor: '#ef4444',
        textColor: '#fef2f2',
        opacity: 1,
      });
    },
    onSuccess: (_, { id }) => {
      Toast.show('Berhasil update grup', {
        backgroundColor: '#22c55e',
        textColor: '#f0fdf4',
        opacity: 1,
      });

      queryClient.invalidateQueries({
        queryKey: [K_GROUPS_QUERY_KEY],
      });

      queryClient.invalidateQueries({
        queryKey: [K_GROUP_DETAIL_QUERY_KEY],
      });

      // if (!__DEV__) {
      //   customEvent('update-group', {
      //     description: `updated group`,
      //     user: currentUser,
      //   });
      // }
      navigation?.reset({
        index: 1,
        routes: [
          {
            name: 'HomeTabs',
            params: {
              screen: '',
            },
          },
          {
            name: 'GroupDetailScreen',
            params: {
              groupId: id,
              isOwner: true,
            },
          },
        ],
      });
    },
  });
};

export default useMutateUpdateGroup;
