import { Image } from 'expo-image';
import { useState } from 'react';
import { Pressable } from 'react-native';

import { TeamIndicator } from './TeamIndicator';
import { s } from '../styles';

import Text from '@/modules/shared/components/basic/Text';
import { Player, assetList } from '@/modules/shared/types';

type PlayerPillProps = {
  player: Player;
  routeFunc: (userId: string) => void;
};
export const PlayerPill = ({ player, routeFunc }: PlayerPillProps) => {
  const [isImageError, setIsImageError] = useState(false);
  return (
    <Pressable style={s.pillContainer} onPress={() => routeFunc(player?.userId)}>
      <Image
        style={s.pillPicture}
        source={player.photoURL && !isImageError ? player.photoURL : assetList.PLACEHOLDER_USER}
        onError={() => setIsImageError(true)}
        contentFit="cover"
        transition={300}
      />

      <Text
        numberOfLines={1}
        className="text-sm"
        style={{ paddingLeft: 16, flexDirection: 'row', maxWidth: '75%' }}>
        {player.displayName ? player.displayName : player.email}
      </Text>
      {player.team && <TeamIndicator colour={player.team} />}
    </Pressable>
  );
};
