import { Mixpanel } from "mixpanel-react-native";
const isEnvProductionWeb = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
const isEnvProductionNative = process.env.EXPO_PUBLIC_CHANNEL === 'production';

export class MixpanelManager {
    instance: Mixpanel;
    static shared: MixpanelManager = new MixpanelManager();
    constructor() {
        const trackAutomaticEvents = false;
        const mixpanelToken = !__DEV__ && (isEnvProductionNative || isEnvProductionWeb) ? "0be804292bbb5a92649974dc305dc67e" : "daac72564c37f13eaa5e914116cf1c24";
        if (isEnvProductionNative) {
            this.instance = new Mixpanel(mixpanelToken, trackAutomaticEvents, true);            
        } else {
            this.instance  = new Mixpanel(mixpanelToken, trackAutomaticEvents, false);
        }
    };

    initialize = () => {
        this.instance.init();
    }

    static track = (eventName: string, userId?: string, extraData: any = {}) => {
        MixpanelManager.shared.instance.track(eventName, {
            ...extraData,
            userId: userId
        });
    }
}
