import { Text } from 'react-native';

import { TeamColours } from '@/modules/shared/types';

type TeamIndicatorProps = {
  colour: TeamColours;
};
export const TeamIndicator = ({ colour }: TeamIndicatorProps) => {
  return <Text style={{ color: (TeamColours as any)[colour], padding: 4, fontSize: 10 }}>●</Text>;
};
