import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import Text from '@/modules/shared/components/basic/Text';

type Props = {
  onPress: (value: boolean) => void;
  value: boolean;
};

export function PrivacySelection({ value, onPress }: Props) {
  return (
    <View>
      <TouchableOpacity className="flex-row items-center space-x-4" onPress={() => onPress(false)}>
        <View className="h-8 w-8 rounded-full border border-gray-300 items-center justify-center">
          {value === false && <View className="bg-playard h-5 w-5 rounded-full" />}
        </View>
        <View className="flex-1">
          <Text fontFamily="InterSemiBold">Terbuka Untuk Semua</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        className="flex-row items-center space-x-4 mt-4"
        onPress={() => onPress(true)}>
        <View className="h-8 w-8 rounded-full border border-gray-300 items-center justify-center">
          {value === true && <View className="bg-playard h-5 w-5 rounded-full" />}
        </View>
        <View className="flex-1">
          <Text fontFamily="InterSemiBold">Hanya bisa diakses lewat link invitasi</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}
