import axios from 'axios';
import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../libs/firebase/app';
import { UserDetail, Notification } from '../types';

export const sendPushNotification = async (pushTokenList: string[], notification: Notification) => {
  if (!pushTokenList.length) return console.log('no push tokens to send notification to');
  try {
    await axios.request({
      method: 'POST',
      baseURL:
        process.env.NODE_ENV === 'production'
          ? 'https://moneys-bucket-prod.fly.dev'
          : 'https://moneys-bucket-staging.fly.dev',
      url: '/api/notify',
      data: {
        pushTokens: pushTokenList,
        title: notification.title,
        body: notification.body,
        appLink: notification.appLink,
      },
    });
  } catch (e) {
    console.log('error with push notification POST request', e);
  }
};
export const saveNotificationToUsers = async (userIdList: string[], notification: Notification) => {
  try {
    return userIdList.map(async (userId: string) => {
      return await addDoc(collection(db, 'users', userId, 'notifications'), notification);
    });
  } catch (e) {
    console.log('error saving notification to users', e);
  }
};
export const getPushTokens = async (userIdList: string[]) => {
  try {
    return userIdList.map(async (userId: string) => {
      const userRef = doc(db, 'users', userId);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data() as UserDetail;
      if (!userData || !userData.pushToken) return;
      return userData.pushToken;
    });
  } catch (e) {
    console.log('error getting push tokens', e);
    return [];
  }
};
