import { Image } from 'expo-image';
import { DocumentData } from 'firebase/firestore';
import React from 'react';
import { Pressable, Text, View } from 'react-native';

import { useNavigation } from '@/modules/navigation/RootNavigation';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

type Props = {
  user: DocumentData;
};

const ProfileCell = ({ user }: Props) => {
  const { currentUser } = useAuth();
  const navigation = useNavigation();
  const routeToProfile = async (userId: string) => {
    if (userId === currentUser?.uid) return;
    navigation?.navigate('ProfileScreen', { uid: userId });
  };
  return (
    <Pressable className="items-stretch mt-4" onPress={() => routeToProfile(user?.uid)}>
      <View className="flex-row items-center space-x-4">
        <Image
          className="h-12 w-12 rounded-full bg-gray-200"
          source={user?.photoURL}
          contentFit="contain"
          transition={300}
        />
        <View className="flex-1">
          <Text className="text-xs text-gray-900">{user?.displayName}</Text>
          <Text className="text-xs text-gray-900">{user?.email}</Text>
        </View>
      </View>
      <View className="h-px bg-gray-200/80 w-full mt-2" />
    </Pressable>
  );
};

export default ProfileCell;
