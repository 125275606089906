import { Image } from 'expo-image';
import { DateTime } from 'luxon';
import React from 'react';
import { 
  Pressable, 
  Text, 
  View, 
  StyleSheet,
  useWindowDimensions,
  ActivityIndicator 
} from 'react-native';
import { useState } from 'react';

import { useNavigation } from '@/modules/navigation/RootNavigation';
import { type UserActivity } from '@/modules/account/hooks/useUserActivities';

type ActivityCellProps = {
  userName: string;
  activity: UserActivity;
};

type ImageDownloadState = 'downloading' | 'error' | 'finish';

const ActivityCell = ({ userName, activity }: ActivityCellProps) => {
  const navigation = useNavigation();
  const { width: windowWidth } = useWindowDimensions();
  const [downloadState, setDownloadState] = useState<ImageDownloadState>('downloading');

  const lobbyDetailStack = () => {
      return <View style = { styles.lobbyDetailContainer }>
      <Image style = { styles.groupImage } 
            source={activity.lobby.creator.photoUrl}
            cachePolicy="memory-disk" />
      <View className="flex-1">
        <View>
          <Text className="font-semibold text-xs text-gray-900">
            {userName}
          </Text>
          <Text className="text-xs text-gray-500">Bermain dalam lobi {activity.lobby.creator.name}</Text>
        </View>
        <Text className="text-xs text-gray-500">
          {DateTime.fromISO(new Date(activity.lobby.details.eventDate.toDate()).toISOString()).toFormat(
            'dd LLL yyyy • t',
          )}
        </Text>
      </View>
    </View>
  };

  return (
    <Pressable
      className="items-stretch"
      style = {{ marginBottom: 8 }}
      onPress={() => {
        if (activity.post == null) {
          navigation?.navigate('Lobby', {
            lobbyId: activity.lobby.id,
          });
        } else {
          console.log('post id is ', activity.post!);
          navigation?.navigate('GroupPostDetailScreen', {
            postId: activity.post!.id
          });
        }
      }}>
      <View style = { styles.container }>
        {activity.post != null && (
          <View className = "relative">
          <Image
          source={{ uri: activity.post.photoUrl }}
          style={{ 
            height: (windowWidth - 32),
            backgroundColor: '#000000'
          }}
          cachePolicy="memory-disk"
          contentFit="cover"
          className="flex"
          onProgress={(event) => {
            if (event.loaded === event.total) {
              setDownloadState('finish');
            }
          }}
          onLoad={() => {
            setDownloadState('finish');
          }}
          />
          {downloadState === 'downloading' && (
            <ActivityIndicator
            size="small"
            color="black"
            className="absolute transform bg-[#DDDDDD]/75 w-full h-full"
            />
          )}
        </View>
        )}

        { lobbyDetailStack() }

        {activity.post != null && (
          <View style = { styles.postDetail }>
            <Text className="text-sm text-[#121212]" style={{fontFamily: "Inter"}}>
              <Text style={{fontFamily: "InterSemiBold"}}>
                {userName}
              </Text>{' '}
              {activity.post?.caption}
            </Text>
          </View>
        )}
        
      </View>
    </Pressable>
  );
};

export default ActivityCell;

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: "#E2E2E2",
    borderRadius: 8,
    overflow: 'hidden',
    gap: 8
  },
  lobbyDetailContainer: {
    flexDirection: 'row',
    gap: 12,
    paddingVertical: 8,
    paddingHorizontal: 12,
    alignItems: 'center',
  },
  groupImage: { 
    height: 40,
    width: 40,
    borderRadius: 8, 
    borderWidth: 1, 
    borderColor: "#E2E2E2", 
    overflow: 'hidden'
  },
  postDetail: { 
    paddingHorizontal: 12,
    paddingBottom: 16,
    gap: 8
  }
});