import { BottomSheetScrollView } from '@gorhom/bottom-sheet';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useCallback, useMemo, useState } from 'react';
import { View, Pressable, Image, TouchableOpacity } from 'react-native';
import Toast from 'react-native-root-toast';
import { UserResponse } from 'src/domain/UserResponse';
import { useDebounce } from 'use-debounce';

import ImageViewerModal from './ImageViewerModal';

import BottomSheetService from '@/modules/shared/components/BottomSheet/BottomSheetService';
import { Button } from '@/modules/shared/components/basic/Button';
import Text from '@/modules/shared/components/basic/Text';
import TextInput from '@/modules/shared/components/basic/TextInput';
import { useAllUsers } from '@/modules/shared/context/AllUserContext';
import { db } from '@/modules/shared/libs/firebase/app';
import { Ionicons } from '@/modules/shared/libs/icons';
import { Lobby, Player, assetList } from '@/modules/shared/types';

export const joinLobbyFirebase = async (
  lobbyId: string,
  playersToAdd: Player[],
  removePlayers: Player[] = [],
): Promise<Player[] | undefined> => {
  const lobbyRef = doc(db, 'lobbies', lobbyId);
  const lobbyDoc = await getDoc(lobbyRef);

  if (!lobbyDoc.exists()) {
    console.log('no such lobby');
    return undefined;
  }

  try {
    const { players: lobbyPlayers } = lobbyDoc.data() as Lobby;

    // Create a Set for removed player UIDs
    const removePlayerUIDs = new Set(removePlayers.map((p) => p.uid));

    // Filter out players to remove
    const filteredLobbyPlayers = lobbyPlayers.filter((player) => !removePlayerUIDs.has(player.uid));

    // Create a Set for existing player UIDs to ensure uniqueness
    const existingPlayerUIDs = new Set(filteredLobbyPlayers.map((p) => p.uid));

    // Filter new players to add only those not already in the lobby
    const uniquePlayersToAdd = playersToAdd.filter((player) => {
      if (!existingPlayerUIDs.has(player.uid)) {
        existingPlayerUIDs.add(player.uid);
        return true;
      }
      return false;
    });

    // Combine filtered existing players with unique new players
    const updatedPlayers = [...filteredLobbyPlayers, ...uniquePlayersToAdd];

    // Update the lobby document with the updated list of players
    await updateDoc(lobbyRef, {
      players: updatedPlayers,
    });

    // Update each player's joinedLobbies list
    await Promise.all([
      ...updatedPlayers.map(async (player) => {
        const userRef = doc(db, 'users', player.uid);
        const userDoc = await getDoc(userRef);
        const joinedLobbies = Array.from(
          new Set([...(userDoc?.data()?.joinedLobbies || []), lobbyId]),
        );

        return updateDoc(userRef, {
          joinedLobbies,
        });
      }),
      ...removePlayers.map(async (player) => {
        const userRef = doc(db, 'users', player.uid);
        const userDoc = await getDoc(userRef);
        const joinedLobbies = Array.from(
          new Set(userDoc?.data()?.joinedLobbies.filter((id: string) => id !== lobbyId)) ?? [],
        );
        console.log({ joinedLobbies, lobbyId });

        return updateDoc(userRef, {
          joinedLobbies,
        });
      }),
    ]);

    return updatedPlayers;
  } catch (e: any) {
    console.error('Error joining lobby:', e.message);
    throw e;
  }
};

export function AddPartisipanButton({
  lobbyId,
  onSuccess,
  initialParticipants = [],
  paidParticipantIds = [],
}: {
  lobbyId: string;
  onSuccess: () => void;
  initialParticipants?: Player[];
  paidParticipantIds?: string[];
}) {
  const handlePress = useCallback(() => {
    BottomSheetService.show({
      content: ({ close }) => (
        <BottomSheetContent
          close={close}
          lobbyId={lobbyId}
          onSuccess={onSuccess}
          initialParticipants={initialParticipants}
          paidParticipantIds={paidParticipantIds}
        />
      ),
    });
  }, [lobbyId, onSuccess, initialParticipants]);

  return (
    <Pressable onPress={handlePress}>
      <Ionicons name="add-circle" size={26} color="#000" />
    </Pressable>
  );
}

function BottomSheetContent({
  lobbyId,
  close,
  onSuccess,
  initialParticipants = [],
  paidParticipantIds = [],
}: {
  lobbyId: string;
  close: () => void;
  onSuccess: () => void;
  initialParticipants?: Player[];
  paidParticipantIds?: string[];
}) {
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [image, setIamge] = useState('');

  const [addedUsers, setAddedUsers] = useState<Player[]>(initialParticipants);
  const [removedUsers, setRemovedUsers] = useState<Player[]>([]);

  const [debounceSearch] = useDebounce(name, 300);

  const allUsersContext = useAllUsers();

  const userResults = useMemo(() => {
    const results = allUsersContext.searchInstance.search(debounceSearch, { prefix: true });
    return results.filter((user) => !paidParticipantIds.includes(user.uid));
  }, [debounceSearch, allUsersContext]);

  const removePlayer = useCallback(
    (user: UserResponse['data']) => {
      setAddedUsers((prev) => prev.filter((u) => u.uid !== user.uid));
      setRemovedUsers((prev) => {
        const userAlreadyRemoved = prev.find((u) => u.uid === user.uid);

        if (userAlreadyRemoved) {
          return prev.filter((u) => u.uid !== user.uid);
        }
        return [
          ...prev,
          {
            paid: false,
            userId: user.uid,
            uid: user.uid,
            email: user.email,
            photoURL: user?.photoURL ?? '',
            displayName: user?.displayName ?? user?.email,
          },
        ];
      });
    },
    [setRemovedUsers],
  );

  const addPlayer = useCallback(
    (user: UserResponse['data']) => {
      setRemovedUsers((prev) => prev.filter((u) => u.uid !== user.uid));
      setAddedUsers((prev) => {
        const userAlreadyAdded = prev.find((u) => u.uid === user.uid);

        if (userAlreadyAdded) {
          return prev.filter((u) => u.uid !== user.uid);
        }
        return [
          ...prev,
          {
            paid: user?.paid ?? false,
            userId: user.uid,
            uid: user.uid,
            email: user.email,
            photoURL: user?.photoURL ?? '',
            displayName: user?.displayName ?? user?.email,
          },
        ];
      });
    },
    [setAddedUsers],
  );

  const handleSubmit = useCallback(async () => {
    if (addedUsers.length === 0) {
      return;
    }

    setSubmitting(true);

    try {
      await joinLobbyFirebase(lobbyId, addedUsers, removedUsers);

      Toast.show(`Berhasil update ${addedUsers.length} partisipan kedalam lobi`);
      close();
      onSuccess();
    } catch (e) {
      alert('Gagal menambahkan pemain');
    } finally {
      setSubmitting(false);
    }
  }, [addedUsers, setSubmitting, close, onSuccess, lobbyId, removedUsers]);

  return (
    <>
      <View className="p-4 flex-1">
        <View className="border border-slate-500 pr-5 rounded-md overflow-hidden flex-row items-center">
          <TextInput
            value={name}
            onChangeText={setName}
            className="h-14 px-5 flex-1"
            placeholder="Cari pemain disini"
            autoCapitalize="none"
          />
          <Ionicons name="search-outline" size={24} />
        </View>

        <View className="mt-4 border-b border-b-slate-500 pb-4">
          {addedUsers.length > 0 && (
            <Text className="text-slate-500">
              {addedUsers.map((user) => user.displayName ?? user.email).join(', ')}
            </Text>
          )}
        </View>
        <BottomSheetScrollView>
          {!!debounceSearch && userResults.length === 0 && (
            <Text fontFamily="InterSemiBold" className="text-center mt-12">
              Pemain tidak ditemukan
            </Text>
          )}
          {userResults?.map((user) => (
            <TouchableOpacity
              onPress={() => {
                if (addedUsers.map((u) => u.uid).includes(user.uid)) {
                  removePlayer(user as unknown as UserResponse['data']);
                } else {
                  addPlayer(user as unknown as UserResponse['data']);
                }
              }}
              className="flex-row items-center space-x-4 mt-4"
              key={user.uidid}>
              <View className="h-8 w-8 rounded-full border border-gray-300 items-center justify-center">
                <TouchableOpacity
                  onPress={() => setIamge(user.photoURL ?? assetList.PLACEHOLDER_USER)}>
                  <Image
                    source={{ uri: user.photoURL ?? assetList.PLACEHOLDER_USER }}
                    className="h-8 w-8 rounded-full"
                  />
                </TouchableOpacity>
              </View>
              <View className="flex-1">
                <Text fontFamily="Inter">{user.displayName ?? user.email}</Text>
              </View>
              <View className="h-8 w-8 rounded-full border border-gray-300 items-center justify-center">
                {addedUsers.map((u) => u.uid).includes(user.uid) && (
                  <View className="bg-playard h-5 w-5 rounded-full" />
                )}
              </View>
            </TouchableOpacity>
          ))}
        </BottomSheetScrollView>

        <Button size="lg" disabled={addedUsers.length === 0 || submitting} onPress={handleSubmit}>
          Update Partisipan
        </Button>
      </View>
      <ImageViewerModal
        showDownload={false}
        imageIndex={0}
        images={[{ uri: image }]}
        visible={!!image}
        onRequestClose={() => setIamge('')}
      />
    </>
  );
}
