import { zodResolver } from '@hookform/resolvers/zod';
import { useRoute } from '@react-navigation/native';
import { useForm } from 'react-hook-form';

import useMutateUpdateGroup from './useMutateUpdateGroup';
import useQueryGroupDetail from './useQueryGroupDetail';

import { RouteProp } from '@/modules/navigation/types';
import { createGroupSchema, GroupSchema } from '@/modules/shared/api/group';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

const useUpdateGroupForm = () => {
  const { params } = useRoute<RouteProp<'GroupDetailScreen'>>();
  const { groupId } = params;

  const { data: group, isLoading } = useQueryGroupDetail(groupId);
  const { mutateAsync, isPending } = useMutateUpdateGroup();
  const { currentUser } = useAuth();

  const form = useForm<GroupSchema>({
    resolver: zodResolver(createGroupSchema),
    defaultValues: {
      name: group?.name ?? '',
      city: group?.city ?? '',
      year: group?.year ?? '',
      profileImg: group?.profileImg ?? '',
      coverImg: group?.coverImg ?? '',
      description: group?.description ?? '',
      instagram: group?.instagram ?? '',
      whatsapp: group?.whatsapp ?? '',
      userId: currentUser?.uid ?? '',
    },
  });

  const submit = form.handleSubmit(async (data) => {
    await mutateAsync({ ...data, id: groupId });
  });

  return { form, submit, isPending: isPending || isLoading };
};

export default useUpdateGroupForm;
