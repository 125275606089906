import { useQuery } from '@tanstack/react-query';

import useFetchUserData from '@/modules/account/hooks/useFetchUserData';
import { getFollowedGroups } from '@/modules/shared/api/group';
import { K_FOLLOWED_GROUPS_QUERY_KEY } from '@/modules/shared/constants/react-query';

const useQueryFollowedGroups = () => {
  const { data: currentUser } = useFetchUserData();

  return useQuery({
    queryKey: [K_FOLLOWED_GROUPS_QUERY_KEY, currentUser?.uid, currentUser?.groupAdmin],
    queryFn: () => getFollowedGroups(String(currentUser?.uid)),
    enabled: Boolean(currentUser?.uid),
    select: (data) => {
      return data.filter((d) => d.id !== String(currentUser?.groupAdmin));
    },
  });
};

export default useQueryFollowedGroups;
