import { create } from 'zustand';

interface SuccessPostState {
  needRefetch: boolean;
  setNeedRefetch: (needRefetch: boolean) => void;
}

const useNeedRefetch = create<SuccessPostState>((set) => ({
  needRefetch: false,
  setNeedRefetch: (needRefetch) => set(() => ({ needRefetch })),
}));

export default useNeedRefetch;
