import {
  type DynamicBottomSheetRef,
  type BottomSheetContentElement,
  type BottomSheetProps,
} from './DynamicBottomSheet';

let ref: DynamicBottomSheetRef | null = null;

const setBottomSheet = (bottomSheetRef: DynamicBottomSheetRef | null) => {
  ref = bottomSheetRef;
};

const getBottomSheet = (): Promise<DynamicBottomSheetRef> => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (ref) {
        resolve(ref);
        clearInterval(interval);
      }
    }, 50);
  });
};

const show = ({
  content,
  bottomSheetProps,
}: {
  content?: BottomSheetContentElement;
  bottomSheetProps?: BottomSheetProps;
}) => {
  getBottomSheet().then((sheet) => sheet.show({ content, bottomSheetProps }));
};

const DynamicBottomSheetService = {
  setBottomSheet,
  show,
};

export default DynamicBottomSheetService;
