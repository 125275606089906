import { Image } from 'expo-image';
import { GestureResponderEvent, Pressable, View } from 'react-native';

import Gradient from '@/modules/shared/components/Gradient';
import Text from '@/modules/shared/components/basic/Text';
import { PlayerWithCredit } from '@/modules/shared/types';

export const TaggedPlayerCellWithCredit = ({
  user,
  onPress,
  colors,
}: {
  user: PlayerWithCredit;
  onPress: null | ((event: GestureResponderEvent) => void) | undefined;
  colors: string[];
}) => {
  return (
    <Pressable className="flex-row space-x-2 my-2 items-center" onPress={onPress}>
      <Image
        className="h-10 w-10 rounded-full bg-gray-200"
        source={user.photoURL}
        contentFit="contain"
        transition={300}
        cachePolicy="memory-disk"
      />
      <View className={`p-[4px] bg-white `}>
        <Text className="text-sm text-gray-900">
          {user.displayName && user.displayName !== '' ? user.displayName : user.email}
        </Text>
      </View>

      {user.credit !== '' && (
        <Gradient
          style={{
            borderRadius: 15,
            justifyContent: 'center',
            paddingHorizontal: 10,
            paddingVertical: 5,
          }}>
          <Text className="text-xs ">{user.credit}</Text>
        </Gradient>
      )}
    </Pressable>
  );
};
