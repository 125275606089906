import React from 'react';
import { Image, Pressable, View } from 'react-native';

import Text from '@/modules/shared/components/basic/Text';
import useImagePicker from '@/modules/shared/hooks/useImagePicker';
import { Ionicons } from '@/modules/shared/libs/icons';

type Props = {
  onChange?: (value: string) => void;
  value?: string;
};

const CreateGroupAvatar = ({ onChange, value }: Props) => {
  const { pickImage } = useImagePicker();

  const handlePress = async () => {
    const image = await pickImage();
    if (image) {
      onChange?.(image);
      console.log('avatar image', image);
    }
  };

  return (
    <View className="items-center flex-row">
      <Pressable
        onPress={handlePress}
        className="rounded-full bg-neutral-200 overflow-hidden w-[60px] h-[60px] flex items-center justify-center">
        {value ? (
          <Image source={{ uri: value }} className="w-[60px] h-[60px] object-cover" />
        ) : (
          <Ionicons name="add-outline" size={24} color="#fff" />
        )}
      </Pressable>

      <Text fontFamily="InterBold" className="ml-3 text-neutral-400">
        Gambar Profil Grup
      </Text>
    </View>
  );
};

export default CreateGroupAvatar;
