import Ionicons from '@expo/vector-icons/Ionicons';
import React, { useCallback } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

type Category = 'Jakarta' | 'Bogor' | 'Bandung';

type Props = {
  value?: Category;
  onChange?: (v: Category) => void;
  close?: () => void;
};

const SelectCityBottomSheetContent = ({ value, onChange, close }: Props) => {
  const handleChange = useCallback(
    (currentValue: Category) => {
      onChange?.(currentValue);
      close?.();
    },
    [onChange, close],
  );

  return (
    <View style={{ paddingHorizontal: 16, marginBottom: 32 }}>
      <Text style={{ fontSize: 18, fontWeight: '700' }}>Pilih Kota</Text>
      {(['Jakarta', 'Bogor', 'Bandung'] as const).map((item) => (
        <TouchableOpacity
          key={item}
          onPress={() => handleChange(item)}
          style={{ paddingTop: 20, flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              color: value === item ? '#5F48B9' : '#888',
              fontWeight: '600',
              marginRight: 4,
            }}>
            {item}
          </Text>
          {value === item && <Ionicons name="checkmark" size={16} color="#5F48B9" />}
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default SelectCityBottomSheetContent;
