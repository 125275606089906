import {
  BottomSheetBackdrop,
  BottomSheetModal,
  BottomSheetModalProvider,
} from '@gorhom/bottom-sheet';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useFonts } from 'expo-font';
import { Image } from 'expo-image';
import * as WebBrowser from 'expo-web-browser';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { NativeWindStyleSheet } from 'nativewind';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { Text, View, StyleSheet, Platform } from 'react-native';
import { initialize } from 'react-native-clarity';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { RootSiblingParent } from 'react-native-root-siblings';
import Toast from 'react-native-root-toast';
import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context';

import PlButton from './components/PlButton';
import MainNavigation from './modules/navigation/MainNavigation';
import BottomSheet from './modules/shared/components/BottomSheet/BottomSheet';
import BottomSheetService from './modules/shared/components/BottomSheet/BottomSheetService';
import DynamicBottomSheet from './modules/shared/components/DynamicBottomSheet/DynamicBottomSheet';
import DynamicBottomSheetService from './modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import { AllUserProvider } from './modules/shared/context/AllUserContext';
import { useAuth } from './modules/shared/context/auth/AuthContext';
import AuthProvider from './modules/shared/context/auth/AuthProvider';
import { app } from './modules/shared/libs/firebase/app';
import { QueryClientProvider } from './modules/shared/libs/react-query';
import { LobbyProvider } from './providers/LobbyProvider';
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';
import { s } from './styles';

import { assetList } from '@/modules/shared/types';
import { MixpanelManager } from './src/MixpanelManager';

NativeWindStyleSheet.setOutput({
  default: 'native',
});

initialize('jbib40ohsb');
const WebLayout = () => {

  MixpanelManager.shared.initialize();
  
  const [isTokenFound, setTokenFound] = useState(false);
  const [prompted, setPrompted] = useState(false);
  const { isInAppBrowser, setLoading, setNotificationPermission } = useAuth();
  const bottomSheetModalRef = useRef<BottomSheetModal>(null);

  const insets = useSafeAreaInsets();
  //  TODO add type
  const [notification, setNotification] = useState<any | undefined>();
  let messaging: any;
  if (Platform.OS === 'web') {
    try {
      messaging = getMessaging(app);
    } catch (e) {
      console.log('error getting messaging', e);
    }
  }

  const getTokenClient = async (setTokenFound: any) => {
    getToken(messaging, {
      vapidKey:
        'BHuvmGORbNGAWSEtwAi-DJATfBUaZOmP2Kwe3XyWw4YHdSQDbazq2Kp2JJHa4yYqhO6K6wYoATXu4qE-OfbkG_k',
    })
      .then(async (currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log('No registration token available. Request permission to generate one.');
          setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
  };

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        console.log('new message', payload);
        resolve(payload);
      });
    });

  const prepare = async () => {
    try {
      console.log('prepare funciton');
      serviceWorkerRegistration.register();
      if (!('Notification' in window)) {
        console.log('Browser does not support desktop notification');
        // IOS safari
        setPrompted(true);
        return setNotificationPermission?.(false);
      } else {
        let permission = '';
        try {
          permission = await Notification.requestPermission();
        } catch (e) {
          console.log('error getting permissions', e);
        }

        console.log('permission ', permission);
        Toast.show(`permission ${permission}`, {
          duration: Toast.durations.SHORT,
        });
        if (permission === 'granted') {
          // dont fire bottom sheet
          setPrompted(true);
          getTokenClient(setTokenFound);
          return setNotificationPermission?.(true);
        } else {
          return setNotificationPermission?.(false);
        }
      }
    } catch (e) {
      console.warn(e);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    // TODO add google play store stuff for android
    DynamicBottomSheetService.show({
      content: ({ close }) => (
        <View style={[s.bottomSheetContainer, { marginBottom: 14 }]}>
          <View>
            <Image
              style={{ width: 200, height: 200, alignSelf: 'center' }}
              source={assetList.NEW_APP}
            />
            <View style={s.newBottomSheetTextContainer}>
              <Text style={{ ...s.lobbyTitleText, padding: 8 }}>Lebih Baik Lewat Aplikasi</Text>
              <Text style={{ ...s.capacityText, textAlign: 'center' }}>
                Dapatkan lebih banyak update dan akses lebih mudah ke Playard melalui aplikasi kami
              </Text>
            </View>
          </View>
          <View style={s.newBottomSheetButtonContainer}>
            <PlButton
              title="Unduh Sekarang"
              type="rounded"
              onPress={async () => {
                // TODO add link to app store, only show if ios for now
                await WebBrowser.openBrowserAsync('https://playard.id');
                close();
              }}
            />
          </View>
        </View>
      ),
    });
  }, []);
  useEffect(() => {
    console.log('initial, useeffect');
    if (isSafari || isInAppBrowser) return;

    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          // dont fire bottom sheet
          console.log('permission already granted');
          setPrompted(true);
          getTokenClient(setTokenFound);
          return setNotificationPermission?.(true);
        } else {
          console.log('permission not granted', permission);
          bottomSheetModalRef.current?.present();
        }
      });
    }
  }, []);

  onMessageListener()
    .then((payload: any) => {
      console.log('🙋‍♀️', payload);
      // @ts-ignore
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err: any) => console.log('failed: ', err));
  // ref

  // variables
  const snapPoints = useMemo(() => ['25%', '40%'], []);

  // callbacks
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        backgroundColor: 'white',
        paddingTop: insets.top,
        paddingEnd: insets.right,
        paddingStart: insets.left,
        paddingBottom: insets.bottom,
      }}>
      <MainNavigation />
      {!prompted && (
        <BottomSheetModal
          ref={bottomSheetModalRef}
          index={1}
          snapPoints={snapPoints}
          enableDismissOnClose={false}
          enablePanDownToClose={false}
          backdropComponent={(props) => (
            <BottomSheetBackdrop
              {...props}
              opacity={0.5}
              enableTouchThrough={false}
              appearsOnIndex={0}
              disappearsOnIndex={-1}
              style={[{ backgroundColor: 'rgba(0, 0, 0, 1)' }, StyleSheet.absoluteFillObject]}
            />
          )}>
          <View style={s.newBottomSheetContainer}>
            <View style={s.newBottomSheetTextContainer}>
              <Text style={{ ...s.lobbyTitleText, padding: 12 }}>Izinkan Notifikasi</Text>
              <Text style={s.capacityText}>
                Izinkan notifikasi untuk menerima update dari Playard jika ada lobi baru yang
                dibuka.
              </Text>
            </View>
            <View style={s.newBottomSheetButtonContainer}>
              <PlButton
                customStyle={{ marginBottom: 16 }}
                type="warn"
                title="Izinkan"
                onPress={() => {
                  setPrompted(true);
                  bottomSheetModalRef.current?.close();
                  prepare();
                }}
              />
              <PlButton
                type="secondary"
                title="Batal"
                onPress={() => {
                  setPrompted(true);
                  bottomSheetModalRef.current?.close();
                }}
              />
            </View>
          </View>
        </BottomSheetModal>
      )}
    </View>
  );
};

export const WebApp = () => {
  const [loaded] = useFonts({
    Inter: require('./assets/fonts/Inter.ttf'),
    InterMedium: require('./assets/fonts/Inter_Medium.ttf'),
    InterSemiBold: require('./assets/fonts/Inter_SemiBold.ttf'),
    InterBold: require('./assets/fonts/Inter_Bold.ttf'),
  });

  if (!loaded) {
    return null;
  } else {
    // @ts-ignore
    Text.defaultProps = {
      style: {
        fontFamily: 'Inter',
      },
    };
  }
  return (
    <QueryClientProvider>
      <ReactQueryDevtools initialIsOpen={false} />

      <AllUserProvider>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <BottomSheetModalProvider>
            <SafeAreaProvider>
              <RootSiblingParent>
                <AuthProvider>
                  <LobbyProvider>
                    <WebLayout />
                    <DynamicBottomSheet
                      ref={(ref) => DynamicBottomSheetService.setBottomSheet(ref)}
                    />
                    <BottomSheet ref={(ref) => BottomSheetService.setBottomSheet(ref)} />
                  </LobbyProvider>
                </AuthProvider>
              </RootSiblingParent>
            </SafeAreaProvider>
          </BottomSheetModalProvider>
        </GestureHandlerRootView>
      </AllUserProvider>
    </QueryClientProvider>
  );
};

export default WebApp;
