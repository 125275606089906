import React, { createContext, useContext, useEffect, useState } from 'react';
import { Text } from 'react-native';

// TODO
import {
  getAllLobbies,
  getCarouselData,
  getLobbyById,
  joinLobbyFirebase,
  joinedStatusLobbyFirebase,
  leaveLobbyFirebase,
  logInToLobby
} from '../firebase';

import { CarouselItem, Lobby, Player } from '@/modules/shared/types';

export const LobbyContext = createContext<any>(null);

export const useLobby = () => {
  return useContext(LobbyContext);
};

export const LobbyProvider = ({ children }: any) => {
  const [lobbyList, setLobbyList] = useState<Lobby[] | any>();
  const [carouselData, setCarouselData] = useState<CarouselItem[] | []>();

  const [lobbyLoading, setLobbyLoading] = useState(false);
  const getLobby = (id: string) => getLobbyById(id);
  const getJoinedStatus = (lobbyId: string, userId: string) =>
    joinedStatusLobbyFirebase(lobbyId, userId);

  const getLobbies = () => getAllLobbies();

  const joinLobby = (lobbyId: string, userId: string): Promise<Player[] | undefined> =>
    joinLobbyFirebase(lobbyId, userId);

  const leaveLobby = (lobbyId: string, userId: string): Promise<Player[] | undefined> =>
    leaveLobbyFirebase(lobbyId, userId);

  const logInOnLobby = (lobbyId: string, userId: string) => logInToLobby(lobbyId, userId);

  const refreshData = async () => {
    console.log('refreshing data');
    const lobbies = await getAllLobbies();
    const carouselData = await getCarouselData();
    setLobbyList(lobbies);
    setCarouselData(carouselData);
  };
  useEffect(() => {
    const getInitialData = async () => {
      const lobbies = await getAllLobbies();
      const carouselData = await getCarouselData();
      setLobbyList(lobbies);
      setCarouselData(carouselData);
      setLobbyLoading(false);
    };
    getInitialData();
  }, []);
  const value = {
    refreshData,
    getLobby,
    getLobbies,
    joinLobby,
    carouselData,
    setCarouselData,
    leaveLobby,
    lobbyList,
    setLobbyList,
    lobbyLoading,
    getJoinedStatus,
    setLobbyLoading,
    logInOnLobby
  };
  return (
    <>
      <LobbyContext.Provider value={value}>
        {!lobbyLoading && children}
        {lobbyLoading && <Text> wait g</Text>}
      </LobbyContext.Provider>
    </>
  );
};
