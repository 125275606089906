import { useEffect, useState } from 'react';

import { getAllCreditTagDocuments } from '../../../firebase';
interface UseCreditTagsResult {
  credits: string[];
  loading: boolean;
  error: boolean;
}
export const useCreditTags = (): UseCreditTagsResult => {
  const [credits, setCredits] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchCredits = async () => {
      setLoading(true);
      try {
        const credits = await getAllCreditTagDocuments().then((res) => res);
        setCredits(credits.credits);
        setLoading(false);
        setError(false);
      } catch (e) {
        setCredits([]);
        setLoading(false);
        setError(true);
      }
    };

    fetchCredits();
  }, [credits.length]);

  return { credits, loading, error };
};
