import { useNavigation, useRoute } from '@react-navigation/native';
import { Image } from 'expo-image';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  ListRenderItem,
  Pressable,
  ScrollView,
  TouchableOpacity,
  View,
  useWindowDimensions,
  KeyboardAvoidingView,
  Dimensions,
  Platform
} from 'react-native';
import {
  FlatList,
  HandlerStateChangeEvent,
  State,
  TapGestureHandler,
  TapGestureHandlerEventPayload,
} from 'react-native-gesture-handler';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSequence,
  withTiming,
} from 'react-native-reanimated';
import { UserResponseData } from 'src/domain/UserResponse';

import LikeIcon from '../../../assets/like.png';
import LikedIcon from '../../../assets/liked.png';
import CommentIcon from '../../../assets/comment.png';
import {
  useMutateCreateComment,
  useMutateUpdateLike,
  useQueryGetLobbyById,
  useQueryGetPostComments,
  useQueryGetPostDetail,
  useQueryGetUserByIds,
} from '../hooks/useQueryGroupPost';

import useProfileDetail from '@/modules/account/hooks/useProfileDetail';
import { RouteProp } from '@/modules/navigation/types';
import { LikeState } from '@/modules/shared/api/post';
import Text from '@/modules/shared/components/basic/Text';
import TextInput from '@/modules/shared/components/basic/TextInput';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { Ionicons } from '@/modules/shared/libs/icons';
import { Comment } from '@/modules/shared/types';
import { MixpanelManager } from '../../../src/MixpanelManager';

// @ts-ignore
// @ts-ignore

type ImageDownloadState = 'downloading' | 'error' | 'finish';


export const CommentInputField = ({ postId }: { postId: string }) => {
  const { currentUser } = useAuth();

  const { height } = Dimensions.get('window');
  const { refetch } = useQueryGetPostDetail(postId);

  const { refetch: refetchComments } = useQueryGetPostComments(postId);
  const { mutateAsync, isPending } = useMutateCreateComment(postId, currentUser?.uid);
  const [commentValue, setCommentValue] = useState('');

  const handleSubmit = async () => {
    if (!commentValue) {
      return;
    }
    await mutateAsync({
      content: commentValue,
      postId,
      userId: currentUser?.uid ?? '',
    });
    // TODO look at these refetches
    refetch();
    refetchComments();
    setCommentValue('');
  };
  return (
    currentUser && (
      <View style={{ paddingBottom: 32 }}>
        <View className="pt-4 pl-4 pr-4">
          <View className="h-11 border border-gray-400 rounded-lg overflow-hidden flex-row items-center pr-2">
            <TextInput
              placeholder="Tulis komentar disini"
              placeholderTextColor="#8A8A8A"
              className="h-full flex-1 rounded-lg px-3"
              fontFamily="InterMedium"
              onSubmitEditing={handleSubmit}
              editable={!isPending}
              value={commentValue}
              onChangeText={setCommentValue}
            />
            <TouchableOpacity
              disabled={isPending}
              onPress={handleSubmit}
              className="h-8 w-8 bg-white rounded-md items-center justify-center">
              <Ionicons name="send" size={16} className="text-white" color='red' />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  );
};
export const renderComment = (item: Comment, goToProfile: (uid: string)=> void) => {
  return (
    <Pressable
      className="mb-4 pl-4 pr-4 flex-row items-center"
      onPress={() => {
        goToProfile(item.userId)
      }}>
      <Image source = { {uri: item.userDetail?.photoURL } } style = {{ width: 24, height: 24, borderRadius: 12}}/>
      <View style = {{ marginLeft: 8 }}>
        <View className="flex-row justify-start">
          <Text className="font-black" fontFamily="InterSemiBold">
            {item.userDetail?.displayName ? item.userDetail?.displayName : item.userDetail?.email}
          </Text>
          <View style = {{ justifyContent: 'center'}}>
            <Text className="text-greyish pl-2" style = {{ fontSize: 10 }}>
              {DateTime.fromISO(new Date(item.createdAt.toDate()).toISOString()).toFormat(
                'dd LLL • t',
              )}
            </Text>
          </View>
        </View>
        <Text className="text-[#727272]">{item.content}</Text>
      </View>
    </Pressable>
  );
};

// TODO handle undefined case
export const CommentSection = (
  { comments, 
    postId,
    hideCommentsInitially,
    goToProfile
  }: { comments: Comment[]; postId: string; hideCommentsInitially: boolean, goToProfile: (userId: string) => void; }) => {
  const navigation = useNavigation();
  const goToComments = useCallback(() => {
    navigation?.navigate('GroupPostCommentScreen', { postId, goToProfile });
  }, []);
  return (
    <View className="pb-3">
      <View style = {{ width: '100%', height: 2, backgroundColor: '#F0F3f7', marginVertical: 16}}/>

      { hideCommentsInitially && comments?.length > 0 && (
        <Pressable className= "pl-4 pr-4" onPress={() => goToComments()}>
          <Text style = {{ fontSize: 12, color: '#727272'}}> Lihat {comments?.length || 0} komentar</Text>
        </Pressable>
      )}

      { !hideCommentsInitially && comments?.length > 0 && (
        <View className="flex-row justify-between pl-4 pr-4 pb-2 items-center">
          <Text className="text-base text-[#121212] font-bold" fontFamily="InterSemiBold">
            Komentar
          </Text>
          <Pressable onPress={() => goToComments()} style = {{ justifyContent: 'center'}}>
            <Text className="text-[#121212]" style = {{ fontFamily: 'InterSemiBold', fontSize: 12}}> Lihat Semua ({comments?.length || 0})</Text>
          </Pressable>
      </View>
      )}
      
      {comments?.length > 0 && (
      <FlatList
        data= {comments.slice(0, 3)}
        keyExtractor={ (comment) => comment.uid }
        renderItem={ ( {item}) => {
          return renderComment(item, (userId) => {
            navigation?.navigate('ProfileScreen', { uid: userId })
          });
        } }
      />)}

      <CommentInputField postId={postId} />
    </View>
  );
};

interface PostLikeState {
  state: LikeState | null;
  likesByUsers: string[];
}

const SubstringText = (props) => {
  const arr = props.text.split(' ');
   const reducer = (acc, cur, index) => {
     let previousVal = acc[acc.length - 1];
     if (
       previousVal &&
       previousVal.startsWith('**') &&
       !previousVal.endsWith('**')
     ) {
       acc[acc.length - 1] = previousVal + ' ' + cur;
     } else {
       acc.push(cur);
     }
     return acc;
   };
 
   const text = arr.reduce(reducer, []);
 
   return (
     <Text style = { props.style }>
       {text.map((text) => {
         if (text.startsWith('**')) {
           return (
             <Text style={ props.substringStyle }>
               {text.replaceAll('**', '')}{' '}
             </Text>
           );
         }
         return `${text} `;
       })}
     </Text>
   );
 };

const GroupPostDetailScreen = () => {
  const { params } = useRoute<RouteProp<'GroupPostDetailScreen'>>();
  const { postId } = params;

  const { height, width: windowWidth } = useWindowDimensions();

  const { data: response, isLoading } = useQueryGetPostDetail(postId);
  const { profileDetail } = useProfileDetail(response?.userId);

  const [downloadState, setDownloadState] = useState<ImageDownloadState>('downloading');

  const { data: lobbyResponse } = useQueryGetLobbyById(response?.lobbyId);
  const { data: taggedResponse } = useQueryGetUserByIds(postId, response?.tagPlayerIDs);
  const [likeState, setLikeState] = useState<PostLikeState | null>(null);
  const { currentUser } = useAuth();

  const navigation = useNavigation();
  const goToProfile = (userId: string) => {
    navigation?.navigate('ProfileScreen', { uid: userId });
  };

  const { mutate } = useMutateUpdateLike(likeState?.likesByUsers ?? [], postId);

  useEffect(() => {
    if (likeState && likeState.state !== 'initial-like') {
      mutate();
    }
  }, [likeState]);

  useEffect(() => {
    if (response && currentUser) {
      const idx = response.likes.findIndex((v) => v === currentUser.uid);
      setLikeState({ likesByUsers: response.likes, state: idx === -1 ? null : 'initial-like' });
    }
  }, [response, currentUser]);

  const opacityAnimationValue = useSharedValue(0);

  const opacityStyle = useAnimatedStyle(() => {
    return {
      opacity: opacityAnimationValue.value,
    };
  });

  const handleDoubleTap = (event: HandlerStateChangeEvent<TapGestureHandlerEventPayload>) => {
    if (event.nativeEvent.state === State.ACTIVE) {
      handeLike();
    }
  };

  const likeAnimation = () => {
    opacityAnimationValue.value = withSequence(
      withTiming(1, { duration: 500 }),
      withTiming(0, { duration: 500 }),
    );
  };

  const handeLike = () => {
    const userId = currentUser?.uid ?? '0';
    if (response && likeState) {
      const idx = likeState.likesByUsers.findIndex((v) => v === userId);
      // Current user haven't like
      if (idx === -1) {
        const likes = [...response.likes, userId];
        setLikeState({
          state: 'like',
          likesByUsers: likes,
        });
        likeAnimation();
      } else {
        const likes = [
          ...likeState.likesByUsers.slice(0, idx),
          ...likeState.likesByUsers.slice(idx + 1),
        ];
        setLikeState({ state: 'dislike', likesByUsers: likes });
      }
    }
  };

  const constructTaggedPlayers = () => {
    if (taggedResponse) {
      const taggedPlayers = taggedResponse.map((user) => user.displayName).join(", ");
      return (
        <SubstringText 
          style = {{ FontFamily: 'InterBold', fontWeight: 'bold', fontSize: 12, color: '#121212', paddingHorizontal: 16, marginTop: 12 }}
          substringStyle = {{ fontFamily: 'Inter', fontWeight: 'regular', color: '#6B7280' }}
          text = {`Bersama dengan **${taggedPlayers}**`}
       />
      );
    }

    return null;
  };

  const handlePressLobby = () => {
    if (lobbyResponse) {
      navigation?.navigate('Lobby', {
        lobbyId: response?.lobbyId ?? ''
      });
    }
  };

  if (isLoading) {
    return (
      <View className="flex-1 items-center justify-center bg-white">
        <ActivityIndicator color="#5F48B9" />
      </View>
    );
  }

  if (!response) {
    return (
      <View className="flex-1 items-center justify-center bg-white">
        <Text className="text-2xl text-neutral-900">Postingan tidak ditemukan</Text>
      </View>
    );
  }

  return (
    <KeyboardAvoidingView
        className = 'bg-white'
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset= { 120 }
        style={{ flex: 1 }}>
      <ScrollView className="bg-white">
        <TapGestureHandler onHandlerStateChange={handleDoubleTap} numberOfTaps={2}>
          <View className="relative">
            <Image
              source={{ uri: response.photoUrl }}
              style={{ height: windowWidth, backgroundColor: '#000000' }}
              cachePolicy="memory-disk"
              contentFit="contain"
              className="flex"
              onProgress={(event) => {
                if (event.loaded === event.total) {
                  setDownloadState('finish');
                }
              }}
              onLoad={() => {
                setDownloadState('finish');
              }}
            />
            <Animated.View
              className="absolute transform w-full h-full justify-center items-center"
              style={opacityStyle}>
              <Image source={LikedIcon} className="w-24 h-24" />
            </Animated.View>
            {downloadState === 'downloading' && (
              <ActivityIndicator
                size="small"
                color="black"
                className="absolute transform bg-[#DDDDDD]/75 w-full h-full"
              />
            )}
          </View>
        </TapGestureHandler>

        <View>
          <View className="pl-4 pr-4 flex-row justify-between items-center" style = {{ marginTop: 8 }}>
            <View className = "flex-row" style = {{ alignContent: 'center', gap: 8}}>
              <TouchableOpacity className= "flex-row items-center"
                  onPress= { () => {
                    MixpanelManager.track('clickSeeAllComment', currentUser?.uid, { page: 'GroupPostDetailScreen' });
                    navigation?.navigate('GroupPostCommentScreen', { postId, goToProfile });
                  } }>
                <Image source = {CommentIcon} className = "w-6 h-6" cachePolicy="memory"/>
              </TouchableOpacity>
              <Pressable onPress= { handeLike } style = {{flexDirection: 'row', alignContent: 'center', gap: 8}} >
                <Image
                  source={
                    likeState && (likeState.state === 'initial-like' || likeState.state === 'like')
                    ? LikedIcon
                    : LikeIcon
                  }
                  className="w-6 h-6"
                  cachePolicy="memory"
                />
                <View style = {{ justifyContent: 'center' }}>
                  <Text style = {{ fontFamily: 'InterSemiBold', fontSize: 12, color: '#121212'}}>
                  {likeState?.likesByUsers.length ?? 0} Menyukai
                  </Text>
                </View>
              </Pressable>
            </View>
            <View style = {{ justifyContent: 'center'}}>
              <Text style = {{ fontFamily: 'InterSemiBold', fontSize: 12, color: '#121212'}}>
                {DateTime.fromISO(new Date(response.createdAt.toDate()).toISOString()).toFormat(
                  'dd LLL yyyy',
                )}
              </Text>
            </View>
          </View>

          <TouchableOpacity onPress = { () => {
            MixpanelManager.track('clickUserNameOnPostDetailPage', currentUser?.uid, { page: 'PostDetailPage' });
            navigation?.navigate('ProfileScreen', { uid: profileDetail?.userId });
          }}>
            <Text className= "pl-4 pr-4 text-sm text-[#121212] mt-4" fontFamily="Inter">
              <Text fontFamily="InterSemiBold">
                {profileDetail?.displayName ? profileDetail?.displayName : profileDetail?.email}
              </Text>{' '}
              {response.caption}
            </Text>
          </TouchableOpacity>

          { lobbyResponse && (
            <View className="flex flex-row pl-4 pr-4">
              <TouchableOpacity className="flex-col just items-start" style = {{ paddingTop: 8, marginTop: 4 }}
              onPress= {handlePressLobby}>
                <View className= "flex-row items-center" style = {{ gap: 8 }}>
                  <Image source={{uri: lobbyResponse.creator.photoUrl}} 
                        style = {{ 
                          height: 28, 
                          width: 28, 
                          borderRadius: 8, 
                          borderWidth: 1,
                          borderColor: '#d9d9d9',
                          overflow: 'hidden'
                        }}/>
                  <View>
                    <Text style = {{ fontFamily: "InterSemiBold", fontSize: 12}}>Diambil dari lobi {lobbyResponse.creator.name}</Text>
                    <Text className="text-[#6B7280] font-normal" style = {{ fontFamily: "Inter", fontSize: 12}}>
                      {lobbyResponse.venue.title}
                    </Text>
                  </View>
                  
                </View>
              </TouchableOpacity>
            </View>
          )}

          {taggedResponse && constructTaggedPlayers()}

          { (profileDetail) && (
            <CommentSection 
              comments={response.comments as Comment[]} 
              postId={postId} 
              hideCommentsInitially= {false}
              goToProfile={goToProfile}
            />
          )}

        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default GroupPostDetailScreen;
