const useDownloadImage = () => {
  const handleDownload = async (uri: string) => {
    const image = await fetch(uri);
    const imageBlob = await image.blob();
    const imageURL = URL.createObjectURL(imageBlob);

    const link = document.createElement('a');
    link.href = imageURL;
    link.target = '_blank';
    link.download = 'image';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return { handleDownload, pending: false };
};

export default useDownloadImage;
