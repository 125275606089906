import React from 'react';
import { Text as RNText, TextProps } from 'react-native';

type Props = {
  fontFamily?: 'Inter' | 'InterMedium' | 'InterSemiBold' | 'InterBold';
} & TextProps;

const Text = ({ fontFamily = 'Inter', style, ...props }: Props) => {
  return (
    <RNText {...props} style={[{ fontFamily }, style]}>
      {props.children}
    </RNText>
  );
};

export default Text;
