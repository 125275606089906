import { StyleSheet } from 'react-native';

export const s = StyleSheet.create({
  bigHostIcon: {
    marginLeft: 16,
    borderRadius: 55,
    alignSelf: 'center',
    resizeMode: 'contain',
    height: 40,
    width: 40,
  },
  hamburgerIcon: {
    width: 24,
    height: 24,
  },
  image: {
    flex: 2,
    justifyContent: 'flex-start',
    // TODO breaks native, changing to %
    // width: "60vw",
    width: '60%',
  },
  newBottomSheetContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
  },
  newBottomSheetTextContainer: {
    alignSelf: 'center',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 20,
    flex: 1,
  },
  newBottomSheetButtonContainer: {
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    paddingBottom: 20,
    marginBottom: 'auto',
  },
  bottomSheetContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    // height: "80% ",
    padding: 20,
  },
  bottomSheetTextContainer: {
    alignSelf: 'center',
    textAlign: 'center',
    alignItems: 'center',
    // justifyContent: "center",
    flex: 1,
  },
  bottomSheetButtonContainer: {
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
  },
  pillPicture: {
    width: 24,
    height: 24,
    borderRadius: 36,
    backgroundColor: '#ebebeb',
  },
  pillContainer: {
    height: 28,
    marginTop: 2,
    marginBottom: 8,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    padding: 4,
    flex: 1,
    flexGrow: 1,
    flexBasis: '50%',
  },
  container: {
    flexDirection: 'column',
    width: '100%',
    // height: 60,
    paddingBottom: 16,
    paddingTop: 16,
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1,
  },
  alignRight: {
    alignItems: 'flex-end',
  },
  alignLeft: {
    alignItems: 'flex-start',
  },
  apart: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  lobbyTitleText: {
    fontWeight: '700',
    fontSize: 16,
  },
  lobbyThinText: {
    fontWeight: 'normal',
    color: '#565656',
    fontSize: 14,
  },
  capacityText: {
    fontWeight: '300',
    fontSize: 12,
  },
  lightText: {
    fontWeight: 'normal',
    color: '#565656',
    fontSize: 10,
  },
  // userIcon: {
  //   padding: 2,
  //   alignSelf: "center",
  //   height: 16,
  //   width: 16,
  // },
  sameLine: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
  },
  userIcon: {
    marginLeft: 4,
    alignSelf: 'center',
    height: 16,
    width: 16,
    borderRadius: 52,
  },
  lockIcon: {
    // marginLeft: 4,
    alignSelf: 'center',
    height: 12,
    width: 12,
    // borderRadius: 52,
  },
  lockIconLobbyScreen: {
    // marginLeft: 4,
    alignSelf: 'center',
    height: 12,
    width: 12,
    paddingLeft: 2,
    // borderRadius: 52,
  },
  hostIcon: {
    // padding: 15,
    alignSelf: 'center',
    resizeMode: 'contain',
    height: 40,
    width: 40,
    borderRadius: 8,
  },
  bold14: {
    fontWeight: '700',
    color: '#121212',
    fontSize: 14,
  },
  bold16: {
    fontWeight: '700',
    color: '#121212',
    fontSize: 16,
  },
  bold18: {
    fontWeight: '700',
    color: '#121212',
    fontSize: 18,
  },
});
