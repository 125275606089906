import { useEffect, useState } from 'react';

import { getWarehouseByUserId } from '../../../firebase';

const useWarehouseList = (userId: string | null) => {
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWarehouses = async () => {
      if (userId) {
        try {
          const warehousesData = await getWarehouseByUserId(userId);
          setWarehouses(warehousesData);
        } catch (error) {
          setError('Error fetching warehouses');
          console.error('Error fetching warehouses: ', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchWarehouses();
  }, [userId]);

  return { warehouses, loading, error };
};

export default useWarehouseList;
