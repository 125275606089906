import { useLinkTo } from '@react-navigation/native';
import {
  NativeStackNavigationOptions,
  createNativeStackNavigator,
} from '@react-navigation/native-stack';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Platform } from 'react-native';

import AuthedTabRoutes from './AuthedTabRoutes';
import HeaderLeft from './components/HeaderLeft';
import { RootStackParamList, UnauthorizedStackParamList } from './types';
import { addNotifToken } from '../../firebase';
import { InAppBrowserScreen } from '../../screens/ErrorScreen';
import LoginScreen from '../../screens/LoginScreen';
import RegisterScreen from '../../screens/RegisterScreen';
import WelcomeScreen from '../../screens/WelcomeScreen';
import EditProfileScreen from '../account/screens/EditProfileScreen';
import CreateGroupPostScreen from '../group/screens/CreateGroupPostScreen';
import EditGroupScreen from '../group/screens/EditGroupScreen';
import GroupDetailScreen from '../group/screens/GroupDetailScreen';
import GroupPostCommentScreen from '../group/screens/GroupPostCommentScreen';
import GroupPostDetailScreen from '../group/screens/GroupPostDetailScreen';
import GivenCreditScreen from '../lobby/screen/Credit/GivenCreditScreen';
import LobbyScreen from '../lobby/screen/LobbyScreen';
import UpdateLobbyScreen from '../lobby/screen/UpdateLobbyScreen';
import { RouteNavigation } from '../lobby/utils/route';
import ProfileScreen from '../profile/screens/ProfileScreen';
import LobbyListScreen from '../../screens/LobbyListScreen';
import AddItem from '../warehouse/AddItem';
import OpenWareHouse from '../warehouse/OpenWareHouse';

import CreateGroupScreen from '@/modules/group/screens/CreateGroupScreen';
import CreateLobbyScreen from '@/modules/lobby/screen/CreateLobbyScreen';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

const Stack = createNativeStackNavigator<RootStackParamList & UnauthorizedStackParamList>();

const stackNavigationOptions: NativeStackNavigationOptions = {
  headerTransparent: false,
  headerShadowVisible: false,
  headerBackVisible: false,
  headerShown: false,
  title: '',
  headerTitleAlign: 'center',
  headerLeft: HeaderLeft,
  headerTitleStyle: {
    fontWeight: 'bold',
    fontSize: 20,
  },
};

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

async function registerForPushNotificationsAsync() {
  try {
    // let token;

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      // alert("Failed to get push token for push notification!");
      return;
    }

    const token = await Notifications.getExpoPushTokenAsync({
      projectId: Constants?.expoConfig?.extra?.eas.projectId,
    });

    return token;
  } catch (e) {
    console.log('error registering for notifs', e);
  }
}

const MainRoute = () => {
  const { currentUser, initializing } = useAuth();

  const [notification, setNotification] = useState<any>(false);
  const notificationListener = useRef<any>();
  const responseListener = useRef<any>();
  const linkTo = useLinkTo();

  const navigateToLink = (url: string) => {
    let applinkFormatted = url.replace('https://playard.id', '');

    if (url.includes('playard://')) {
      applinkFormatted = url.replace('playard:/', '');
    } else if (url.includes('https://app.playard.id')) {
      applinkFormatted = url.replace('https://app.playard.id', '');
    } else {
      applinkFormatted = url.replace('https://playard.id', '');
    }

    console.log('navigating to', url);
    try {
      linkTo(applinkFormatted);
    } catch (e) {
      console.log('error navigating to', url, e);
    }
  };

  useEffect(() => {
    if (currentUser) {
      registerForPushNotificationsAsync()
        .then(async (token) => {
          if (token) {
            await addNotifToken(token?.data as string, currentUser?.uid);
          }
        })
        .catch((e) => console.log('error registering for notifs', e));
      notificationListener.current = Notifications.addNotificationReceivedListener(
        (notification) => {
          setNotification(notification);
        },
      );
      responseListener.current = Notifications.addNotificationResponseReceivedListener(
        (response) => {
          const appLink = response.notification.request.content.data.appLink;
          if (appLink && appLink !== '') {
            navigateToLink(appLink);
          }
          console.log(JSON.stringify(response));
        },
      );
      return () => {
        notificationListener.current &&
          Notifications.removeNotificationSubscription(notificationListener.current);
        responseListener.current &&
          Notifications.removeNotificationSubscription(responseListener.current);
      };
    }
  }, [currentUser]);

  useEffect(() => {
    const handleReady = async () => {
      if (initializing) return;

      const link = await Linking.getInitialURL();

      console.log('<<<<<<<<<<<<<', link);
      await SplashScreen.hideAsync();
      if (link && currentUser) {
        navigateToLink(link);
      }
    };

    handleReady();
  }, [initializing]);

  return (
    <Stack.Navigator screenOptions={stackNavigationOptions} initialRouteName="WelcomeScreen">
      {!currentUser ? (
        <>
          <Stack.Screen
            name="WelcomeScreen"
            component={WelcomeScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="InAppBrowserErrorScreen"
            component={InAppBrowserScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="LoginScreen"
            component={LoginScreen}
            options={{ title: 'Login', headerShown: true }}
          />
          <Stack.Screen
            name="RegisterScreen"
            component={RegisterScreen}
            options={{ title: 'Daftar', headerShown: true }}
          />
        </>
      ) : Platform.OS !== 'web' && !currentUser?.username ? (
        <Stack.Screen
          name="EditProfileScreen"
          component={EditProfileScreen}
          initialParams={{ canBack: false }}
        />
      ) : (
        <>
          <Stack.Screen name="HomeTabs" component={AuthedTabRoutes} />
          <Stack.Screen
            name="Lobby"
            component={LobbyScreen}
            getId={({ params }: any) => params?.lobbyId}
            options={{ headerShown: true }}
          />
          <Stack.Screen
            name="CreateLobbyScreen"
            component={CreateLobbyScreen}
            options={{ headerShown: true, title: 'Buat Lobi' }}
          />
          <Stack.Screen
            name="UpdateLobbyScreen"
            component={UpdateLobbyScreen}
            options={{ headerShown: true, title: 'Update Lobi' }}
          />
          <Stack.Screen
            name={RouteNavigation.CreditGiven}
            component={GivenCreditScreen}
            options={{ headerShown: true, title: 'Beri Kredit' }}
          />
          <Stack.Screen
            name={RouteNavigation.OpenWareHouse}
            component={OpenWareHouse}
            options={{ headerShown: true, title: 'Buka Gudang' }}
          />
          <Stack.Screen
            name={RouteNavigation.AddItem}
            component={AddItem}
            options={{ headerShown: true, title: 'Tambah Barang' }}
          />
          <Stack.Screen
            name="ProfileScreen"
            component={ProfileScreen}
            options={{ headerShown: true, title: '' }}
          />
          <Stack.Screen name="EditProfileScreen" component={EditProfileScreen} />
          <Stack.Screen
            name="CreateGroupScreen"
            component={CreateGroupScreen}
            options={{ headerShown: true, title: 'Buat Grup' }}
          />
          <Stack.Screen
            name="EditGroupScreen"
            component={EditGroupScreen}
            options={{ headerShown: true, title: 'Atur Grup' }}
          />
          <Stack.Screen
            name="CreateGroupPostScreen"
            component={CreateGroupPostScreen}
            options={{
              headerShown: true,
              title: 'Buat Postingan',
              presentation: 'fullScreenModal',
              headerStyle: {
                backgroundColor: '#5F48B9',
              },
              headerTitleStyle: {
                color: '#ffffff',
              },
            }}
          />
          <Stack.Screen
            name="GroupPostDetailScreen"
            component={GroupPostDetailScreen}
            options={{
              headerShown: true,
              title: 'Postingan',
            }}
          />
          <Stack.Screen
            name="GroupPostCommentScreen"
            component={GroupPostCommentScreen}
            options={{
              headerShown: true,
              title: 'Komentar',
            }}
          />
          <Stack.Screen
            name="GroupDetailScreen"
            component={GroupDetailScreen}
            options={{
              headerShown: true,
              title: 'Detil Grup',
            }}
          />
          <Stack.Screen
            name="LobbyListScreen"
            component={LobbyListScreen}
            options= {{
              headerShown: true,
              title: 'Lobi Aktif'
            }}
          />
        </>
      )}
    </Stack.Navigator>
  );
};

export default MainRoute;
