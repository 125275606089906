// import { useNavigation } from '@react-navigation/native';
// import { Image } from 'expo-image';
// import { DateTime } from 'luxon';
// import { useMemo } from 'react';
// import { Pressable, StyleSheet, Text, View } from 'react-native';

// import PlButton from './PlButton';
// import SkillLabel, { SkillLevelLabel } from './SkillLabel';
// import { s } from '../styles';

// import { RouteNavigation } from '@/modules/lobby/utils/route';
// import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
// import CustomText from '@/modules/shared/components/basic/Text';
// import { assetList, FirestoreLobby } from '@/modules/shared/types';

// type LobbyCardProps = {
//   firestoreLobby: FirestoreLobby;
//   currentUserId: string;
//   lobbyClickedFromProfile?: any;
// };

// const FullLabel = () => {
//   return (
//     <View
//       style={{
//         backgroundColor: '#7B7FB1CC',
//         borderRadius: 4,
//         paddingVertical: 2,
//         paddingHorizontal: 12,
//       }}>
//       <Text style={{ fontWeight: '600', fontSize: 10, color: '#fff' }}>Full</Text>
//     </View>
//   );
// };

// export const LadiesLabel = () => {
//   return (
//     <View
//       style={{
//         backgroundColor: '#FFC1F5',
//         borderRadius: 4,
//         paddingVertical: 2,
//         paddingHorizontal: 12,
//         marginLeft: 5,
//         marginRight: 5,
//       }}>
//       <Text style={{ fontWeight: '600', fontSize: 10, color: '#fff' }}>Ladies</Text>
//     </View>
//   );
// };

// export const BoysLabel = () => {
//   return (
//     <View
//       style={{
//         backgroundColor: '#343434',
//         borderRadius: 4,
//         paddingVertical: 2,
//         paddingHorizontal: 12,
//         marginLeft: 5,
//         marginRight: 5,
//       }}>
//       <Text style={{ fontWeight: '600', fontSize: 10, color: '#fff' }}>Boys</Text>
//     </View>
//   );
// };

// function nFormatter(num: number) {
//   if (num >= 1000000000) {
//     return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
//   }
//   if (num >= 1000000) {
//     return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
//   }
//   if (num >= 1000) {
//     return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
//   }
//   return num;
// }

// export const LobbyCard = ({
//   firestoreLobby,
//   currentUserId,
//   lobbyClickedFromProfile,
// }: LobbyCardProps) => {
//   const navigation = useNavigation();
//   const { id, data: lobby } = firestoreLobby;

//   const faces = useMemo(() => {
//     return lobby.players
//       .map((v) => ({
//         id: v.userId,
//         imageUrl: v.photoURL
//           ? v.photoURL.startsWith('https://') || v.photoURL.startsWith('http://')
//             ? v.photoURL
//             : assetList.PLACEHOLDER_USER
//           : assetList.PLACEHOLDER_USER,
//       }))
//       .slice(0, 3);
//   }, [lobby]);

//   const determineSkillLabel = (): SkillLevelLabel => {
//     const categoryString: string = lobby.details.category;
//     let skillLabel: SkillLevelLabel = 'all';
//     switch (categoryString) {
//       case 'Semua':
//         skillLabel = 'all';
//         break;
//       case 'Pemula':
//         skillLabel = 'newbie';
//         break;
//       case 'Menengah':
//         skillLabel = 'intermediate';
//         break;
//       case 'Expert':
//         skillLabel = 'expert';
//     }

//     return skillLabel;
//   };

//   return (
//     <Pressable
//       onPress={() => {
//         const isJoined = lobby.players.find((player) => player.userId === currentUserId);
//         const isAdmin = lobby.creator.userId === currentUserId;

//         if (lobby.details.isPrivate && !isJoined && !isAdmin) {
//           DynamicBottomSheetService.show({
//             content: ({ close }) => (
//               <View>
//                 <View
//                   className="flex-row items-center mt-4 pl-5 pb-5"
//                   style={{
//                     borderBottomWidth: 1,
//                     borderBottomColor: '#eee',
//                   }}>
//                   <CustomText fontFamily="InterBold" className="flex-1">
//                     Cara Mengakses Lobi Privat
//                   </CustomText>
//                   <View className="rounded-l-full h-8 w-12 bg-[#25165E] items-center justify-center">
//                     <Image source={require('../assets/icon-lock.png')} className="h-5 w-5" />
//                   </View>
//                 </View>

//                 <View className="p-5">
//                   {[
//                     '1. Pastikan kamu sudah menggunakan aplikasi dengan versi terbaru',
//                     '2. Klik link invitasi dari pembuka lobi, maka kamu akan dengan otomatis membuka lobi tersebut',
//                   ].map((item, index) => (
//                     <View key={index} style={{ marginBottom: 16 }}>
//                       <Text style={{ fontFamily: 'Inter', color: '#3a3745' }}>{item}</Text>
//                     </View>
//                   ))}
//                   <View style={{ height: 16 }} />
//                   <PlButton onPress={close} title="Baik" customStyle={{ borderRadius: 8 }} />
//                 </View>
//               </View>
//             ),
//           });

//           return;
//         }

//         if (lobby.details.lobbyPassword) {
//           // check joined status
//           if (isJoined) {
//             console.log('already joined');
//             //@ts-ignore
//             navigation?.navigate('Lobby', {
//               lobbyId: id,
//             });
//             return;
//           }
//           // console.log(lobby, currentUserId);
//           //fire password modal, check if password is correct
//           const password = prompt('Please enter lobby password');
//           if (password !== lobby.details.lobbyPassword) {
//             alert('Wrong password');
//             return;
//           }

//           if (lobbyClickedFromProfile) {
//             lobbyClickedFromProfile();
//           }
//           //@ts-ignore
//           navigation?.navigate('Lobby', {
//             lobbyId: id,
//           });
//         } else {
//           // console.log('navigation to', id);
//           if (lobbyClickedFromProfile) {
//             lobbyClickedFromProfile();
//           }
//           navigation.navigate(RouteNavigation.OpenWareHouse);
//           //@ts-ignore
//           // navigation?.navigate('Lobby', {
//           //   lobbyId: id,
//           // });
//         }
//       }}>
//       <View style={[styles.container]}>
//         <View style={{ ...styles.apart, paddingBottom: 6 }}>
//           <View style={styles.sameLine}>
//             <View style={{ marginRight: 8 }}>
//               <Image
//                 contentFit="cover"
//                 style={s.hostIcon}
//                 source={lobby.creator.photoUrl ? lobby.creator.photoUrl : assetList.PLAYARD_HOST}
//                 className="border-[1px] border-[#E2E2E2]"
//               />
//             </View>
//             <View>
//               <View className="flex-row items-center">
//                 <Text style={styles.capacityText} numberOfLines={1} ellipsizeMode="tail">
//                   {lobby.details.title.length > 30
//                     ? lobby.details.title.substring(0, 30) + '...'
//                     : lobby.details.title}

//                   {lobby.details.lobbyPassword && (
//                     <Image
//                       style={[s.lockIcon, { marginLeft: 5, marginRight: 5 }]}
//                       source={assetList.ICON_LOCK}
//                     />
//                   )}
//                 </Text>
//                 {lobby.details.genderGroup === 'Laki-Laki' && <BoysLabel />}
//                 {lobby.details.genderGroup === 'Perempuan' && <LadiesLabel />}
//               </View>
//               <Text style={{ ...styles.lightText, marginTop: 2, color: '#433F51' }}>
//                 {lobby.creator.name ?? 'Playard Official'}
//               </Text>
//             </View>
//           </View>
//         </View>

//         <View
//           style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
//           {/* Venue, Date, & Skill Labels Section */}
//           <View>
//             <View style={{ ...styles.alignLeft, marginTop: 8 }}>
//               <Text style={{ ...styles.lightText, color: '#121434' }}>
//                 {DateTime.fromISO(
//                   new Date(lobby.details.eventDate.toDate()).toISOString(),
//                 ).toFormat('dd LLL yyyy • t')}
//               </Text>
//               <Text style={{ ...styles.lightText, marginTop: 2, color: '#121434' }}>
//                 {lobby.venue.title}
//               </Text>
//             </View>

//             <View className="flex-row mt-2 gap-x-1">
//               <SkillLabel skillLevel={determineSkillLabel()} />
//               <View className="py-1 px-2 rounded flex-row items-center bg-[#F4F4F4]">
//                 <Image source={require('../assets/rp-circle.png')} className="w-4 h-4 mr-1" />
//                 <Text
//                   style={{
//                     fontFamily: 'InterBold',
//                     fontSize: 12,
//                     color: '#121434',
//                   }}>
//                   {nFormatter(lobby.details.price)}
//                 </Text>
//               </View>
//             </View>
//           </View>

//           {/* FacePile & Registrant */}
//           <View style={{ flexDirection: 'row', alignItems: 'center' }}>
//             <View className="items-center">
//               <View style={{ flexDirection: 'row', alignItems: 'center' }}>
//                 <View className="flex-row-reverse flex-nowrap items-center flex-1">
//                   {faces.map((f, i) => {
//                     return (
//                       <Image
//                         className="h-9 w-9 rounded-full object-cover border border-white"
//                         style={{ marginLeft: -18 }}
//                         source={{ uri: f.imageUrl }}
//                         key={f.id}
//                       />
//                     );
//                   })}
//                 </View>
//               </View>
//               <View className="flex-row items-center mt-[13px]">
//                 <Text
//                   style={{
//                     fontSize: 12,
//                     fontWeight: '700',
//                     fontFamily: 'InterBold',
//                     color: '#121434',
//                   }}>
//                   {lobby.players.length}/{lobby.details.capacity}
//                 </Text>
//                 <Text
//                   style={{
//                     fontWeight: '400',
//                     fontSize: 12,
//                     fontFamily: 'Inter',
//                     color: '#433F51',
//                   }}>
//                   {' '}
//                   Pendaftar
//                 </Text>
//               </View>
//             </View>
//           </View>
//         </View>

//         {/* <View style={styles.apart}>
//           <View style={styles.alignLeft}>
//             <Text style={{ ...styles.lightText, color: '#121434' }}>
//               {DateTime.fromISO(new Date(lobby.details.eventDate.toDate()).toISOString()).toFormat(
//                 'dd LLL yyyy • t',
//               )}
//             </Text>
//             <Text style={{ ...styles.lightText, marginTop: 2, color: '#121434' }}>
//               {lobby.venue.title}
//             </Text>
//           </View>
//           <View style={styles.alignRight}>
//             <Text style={styles.lightText}>{'Skill: ' + lobby.details.category}</Text>
//             <Text style={{ ...styles.lightText, marginTop: 2 }}>
//               <NumericFormat
//                 value={lobby.details.price}
//                 displayType="text"
//                 thousandSeparator
//                 renderText={(formattedValue) => <Text>{formattedValue} IDR / pax</Text>} // <--- Don't forget this!
//               />
//             </Text>
//           </View>
//         </View> */}
//       </View>
//     </Pressable>
//   );
// };

// const styles = StyleSheet.create({
//   container: {
//     flexDirection: 'column',
//     width: '100%',
//     // height: 60,
//     paddingVertical: 12,
//   },
//   alignRight: {
//     alignItems: 'flex-end',
//   },
//   alignLeft: {
//     alignItems: 'flex-start',
//   },
//   apart: {
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//   },
//   capacityText: {
//     fontWeight: '600',
//     fontSize: 12,
//     color: '#121212',
//   },
//   lightText: {
//     fontWeight: '400',
//     fontSize: 12,
//     fontFamily: 'Inter',
//     lineHeight: 12.37,
//   },

//   sameLine: {
//     justifyContent: 'space-evenly',
//     alignItems: 'flex-start',
//     flexDirection: 'row',
//   },
// });
// export default LobbyCard;

import { useNavigation } from '@react-navigation/native';
import { Image } from 'expo-image';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';

import PlButton from './PlButton';
import SkillLabel, { SkillLevelLabel } from './SkillLabel';
import { s } from '../styles';

import { RouteNavigation } from '@/modules/lobby/utils/route';
import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import CustomText from '@/modules/shared/components/basic/Text';
import { assetList, FirestoreLobby } from '@/modules/shared/types';

type LobbyCardProps = {
  firestoreLobby: FirestoreLobby;
  currentUserId: string;
  lobbyClickedFromProfile?: any;
};

const FullLabel = () => {
  return (
    <View
      style={{
        backgroundColor: '#7B7FB1CC',
        borderRadius: 4,
        paddingVertical: 2,
        paddingHorizontal: 12,
      }}>
      <Text style={{ fontWeight: '600', fontSize: 10, color: '#fff' }}>Full</Text>
    </View>
  );
};

export const LadiesLabel = () => {
  return (
    <View
      style={{
        backgroundColor: '#FFC1F5',
        borderRadius: 4,
        paddingVertical: 2,
        paddingHorizontal: 12,
        marginLeft: 5,
        marginRight: 5,
      }}>
      <Text style={{ fontWeight: '600', fontSize: 10, color: '#fff' }}>Ladies</Text>
    </View>
  );
};

export const BoysLabel = () => {
  return (
    <View
      style={{
        backgroundColor: '#343434',
        borderRadius: 4,
        paddingVertical: 2,
        paddingHorizontal: 12,
        marginLeft: 5,
        marginRight: 5,
      }}>
      <Text style={{ fontWeight: '600', fontSize: 10, color: '#fff' }}>Boys</Text>
    </View>
  );
};

function nFormatter(num: number) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num;
}

export const LobbyCard = ({
  firestoreLobby,
  currentUserId,
  lobbyClickedFromProfile,
}: LobbyCardProps) => {
  const navigation = useNavigation();
  const { id, data: lobby } = firestoreLobby;

  const faces = useMemo(() => {
    return lobby.players
      .map((v) => ({
        id: v.userId,
        imageUrl: v.photoURL
          ? v.photoURL.startsWith('https://') || v.photoURL.startsWith('http://')
            ? v.photoURL
            : assetList.PLACEHOLDER_USER
          : assetList.PLACEHOLDER_USER,
      }))
      .slice(0, 3);
  }, [lobby]);

  const remainingPlayers = useMemo(() => {
    return lobby.players.slice(3).length;
  }, [currentUserId, lobby.players]);

  const determineSkillLabel = (): SkillLevelLabel => {
    const categoryString: string = lobby.details.category;
    let skillLabel: SkillLevelLabel = 'all';
    switch (categoryString) {
      case 'Semua':
        skillLabel = 'all';
        break;
      case 'Pemula':
        skillLabel = 'newbie';
        break;
      case 'Menengah':
        skillLabel = 'intermediate';
        break;
      case 'Expert':
        skillLabel = 'expert';
    }

    return skillLabel;
  };

  return (
    <Pressable
      onPress={() => {
        const isJoined = lobby.players.find((player) => player.userId === currentUserId);
        const isAdmin = lobby.creator.userId === currentUserId;

        if (lobby.details.isPrivate && !isJoined && !isAdmin) {
          DynamicBottomSheetService.show({
            content: ({ close }) => (
              <View>
                <View
                  className="flex-row items-center mt-4 pl-5 pb-5"
                  style={{
                    borderBottomWidth: 1,
                    borderBottomColor: '#eee',
                  }}>
                  <CustomText fontFamily="InterBold" className="flex-1">
                    Cara Mengakses Lobi Privat
                  </CustomText>
                  <View className="rounded-l-full h-8 w-12 bg-[#25165E] items-center justify-center">
                    <Image source={require('../assets/icon-lock.png')} className="h-5 w-5" />
                  </View>
                </View>

                <View className="p-5">
                  {[
                    '1. Pastikan kamu sudah menggunakan aplikasi dengan versi terbaru',
                    '2. Klik link invitasi dari pembuka lobi, maka kamu akan dengan otomatis membuka lobi tersebut',
                  ].map((item, index) => (
                    <View key={index} style={{ marginBottom: 16 }}>
                      <Text style={{ fontFamily: 'Inter', color: '#3a3745' }}>{item}</Text>
                    </View>
                  ))}
                  <View style={{ height: 16 }} />
                  <PlButton onPress={close} title="Baik" customStyle={{ borderRadius: 8 }} />
                </View>
              </View>
            ),
          });

          return;
        }

        if (lobby.details.lobbyPassword) {
          // check joined status
          if (isJoined) {
            console.log('already joined');
            //@ts-ignore
            navigation?.navigate('Lobby', {
              lobbyId: id,
            });
            return;
          }
          // console.log(lobby, currentUserId);
          //fire password modal, check if password is correct
          const password = prompt('Please enter lobby password');
          if (password !== lobby.details.lobbyPassword) {
            alert('Wrong password');
            return;
          }

          if (lobbyClickedFromProfile) {
            lobbyClickedFromProfile();
          }
          //@ts-ignore
          navigation?.navigate('Lobby', {
            lobbyId: id,
          });
        } else {
          // console.log('navigation to', id);
          if (lobbyClickedFromProfile) {
            lobbyClickedFromProfile();
          }
          //@ts-ignore
          navigation?.navigate('Lobby', {
            lobbyId: id,
          });
        }
      }}>
      <View style={[styles.container]}>
        <View style={{ ...styles.apart, paddingBottom: 6 }}>
          <View style={styles.sameLine}>
            <View style={{ marginRight: 8 }}>
              <Image
                contentFit="cover"
                style={s.hostIcon}
                source={lobby.creator.photoUrl ? lobby.creator.photoUrl : assetList.PLAYARD_HOST}
                className="border-[1px] border-[#E2E2E2]"
              />
            </View>
            <View>
              <View className="flex-row items-center">
                <Text style={styles.capacityText} numberOfLines={1} ellipsizeMode="tail">
                  {lobby.details.title.length > 30
                    ? lobby.details.title.substring(0, 30) + '...'
                    : lobby.details.title}

                  {lobby.details.lobbyPassword && (
                    <Image
                      style={[s.lockIcon, { marginLeft: 5, marginRight: 5 }]}
                      source={assetList.ICON_LOCK}
                    />
                  )}
                </Text>
                {lobby.details.genderGroup === 'Laki-Laki' && <BoysLabel />}
                {lobby.details.genderGroup === 'Perempuan' && <LadiesLabel />}
              </View>
              <Text style={{ ...styles.lightText, marginTop: 2, color: '#433F51' }}>
                {lobby.creator.name ?? 'Playard Official'}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Venue, Date, & Skill Labels Section */}
          <View>
            <View style={{ ...styles.alignLeft, marginTop: 8 }}>
              <Text style={{ ...styles.lightText, color: '#121434' }}>
                {DateTime.fromISO(
                  new Date(lobby.details.eventDate.toDate()).toISOString(),
                ).toFormat('dd LLL yyyy • t')}
              </Text>
              <Text style={{ ...styles.lightText, marginTop: 2, color: '#121434' }}>
                {lobby.venue.title}
              </Text>
            </View>

            <View className="flex-row mt-2 gap-x-1">
              <SkillLabel skillLevel={determineSkillLabel()} />
              <View className="py-1 px-2 rounded flex-row items-center bg-[#F4F4F4]">
                <Image source={require('../assets/rp-circle.png')} className="w-4 h-4 mr-1" />
                <Text
                  style={{
                    fontFamily: 'InterBold',
                    fontSize: 12,
                    color: '#121434',
                  }}>
                  {nFormatter(lobby.details.price)}
                </Text>
              </View>
            </View>
          </View>

          {/* FacePile & Registrant */}
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View className="items-center">
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View className="flex-row-reverse flex-nowrap items-center flex-1">
                  {remainingPlayers > 0 && (
                    <View
                      className="h-9 w-9 rounded-full object-cover border border-white bg-slate-300 items-center justify-center"
                      style={{ marginLeft: -14 }}>
                      <CustomText
                        fontFamily="InterSemiBold"
                        className="text-white text-center font-semibold text-xs ml-2">
                        +{remainingPlayers}
                      </CustomText>
                    </View>
                  )}
                  {faces.map((f, i) => {
                    return (
                      <Image
                        className="h-9 w-9 rounded-full object-cover border border-white"
                        style={{ marginLeft: -18 }}
                        source={{ uri: f.imageUrl }}
                        key={f.id}
                      />
                    );
                  })}
                </View>
              </View>
              <View className="flex-row items-center mt-[13px]">
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: '700',
                    fontFamily: 'InterBold',
                    color: '#121434',
                  }}>
                  {lobby.players.length}/{lobby.details.capacity}
                </Text>
                <Text
                  style={{
                    fontWeight: '400',
                    fontSize: 12,
                    fontFamily: 'Inter',
                    color: '#433F51',
                  }}>
                  {' '}
                  Pendaftar
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    // height: 60,
    padding: 12,
  },
  alignRight: {
    alignItems: 'flex-end',
  },
  alignLeft: {
    alignItems: 'flex-start',
  },
  apart: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  capacityText: {
    fontWeight: '600',
    fontSize: 12,
    color: '#121212',
  },
  lightText: {
    fontWeight: '400',
    fontSize: 12,
    fontFamily: 'Inter',
    lineHeight: 12.37,
  },

  sameLine: {
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
});
export default LobbyCard;
