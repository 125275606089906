import * as Clipboard from 'expo-clipboard';
import { Image } from 'expo-image';
import { Text, View, StyleSheet } from 'react-native';
import Toast from 'react-native-root-toast';

import PlButton from '../components/PlButton';
import { s } from '../styles';

import { assetList } from '@/modules/shared/types';

export const InAppBrowserScreen = () => {
  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(window.location.href);
    Toast.show('Berhasil menyalin link', {
      duration: Toast.durations.SHORT,
    });
  };
  return (
    <View style={styles.container}>
      <Image style={styles.image} source={assetList.WARN} contentFit="contain" transition={1000} />
      <View style={styles.subContainer}>
        <View style={{ flex: 1 }}>
          <Text style={{ ...s.lobbyTitleText, padding: 8 }}>Yah, kamu harus pindah browser..</Text>
          <Text style={{ ...s.capacityText, lineHeight: 20, padding: 8 }}>
            Kami mendeteksi kamu sedang menggunakan browser dalam aplikasi, silahkan salin link dan
            buka playard di browser handphone kamu.
          </Text>
        </View>
        <View style={{ flex: 1, alignSelf: 'stretch' }}>
          <PlButton title="Salin Link" onPress={() => copyToClipboard()} type="rounded" />
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: '129px',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subContainer: {
    textAlign: 'center',
    flex: 2,
    flexDirection: 'column',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    alignSelf: 'stretch',
  },

  image: {
    flex: 2,
    width: '80vw',
  },
});
