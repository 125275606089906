import { useRoute } from '@react-navigation/native';
import { Image, View} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { RouteProp } from '@/modules/navigation/types';
import { FlatList } from 'react-native-gesture-handler';
import { FirestoreLobby } from '@/modules/shared/types';
import { LobbyCard } from '../components/LobbyCard';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

const LobbyListScreen = () => {
  const { params } = useRoute<RouteProp<'LobbyListScreen'>>();
  const { lobbies } = params;
  const { currentUser } = useAuth();
  const filteredLobbies = lobbies.filter(
    // TODO use lobby duration value
    (a: FirestoreLobby) =>
      new Date(a.data?.details.eventDate.toDate()).valueOf() >
        new Date().setHours(new Date().getHours() - 2).valueOf() && !a.data.isDeleted,
  )
  .sort(
    (a: FirestoreLobby, b: FirestoreLobby) =>
      a.data.details.eventDate.toDate().valueOf() -
      b.data.details.eventDate.toDate().valueOf(),
  )

  // TODO sort out types
  return (
    <View className="flex-1 bg-white">
      <SafeAreaView className="flex-1 bg-white" edges={['bottom']} style = {{ marginTop: 16 }}>
        <FlatList
            data= { filteredLobbies }
            keyExtractor={ (lobby) => lobby.id }
            renderItem={ (item) => {
                return <View
                    key={item.item.id}
                    className="bg-white relative rounded-lg mb-2 border-[1px] border-[#D9D9D9] overflow-hidden"
                    style = {{ marginHorizontal: 16}}
                    >
                    {item.item.data.details.isPrivate && (
                        <View className="absolute right-0 rounded-l-full mt-2 h-8 w-12 bg-[#25165E] items-center justify-center">
                        <Image source={require('../assets/icon-lock.png')} className="h-5 w-5" />
                        </View>
                    )}
                    <LobbyCard firestoreLobby={item.item} currentUserId={currentUser?.uid ?? ''} />
            </View>
            }}
        ></FlatList>
      </SafeAreaView>
    </View>
  );
};
export default LobbyListScreen;
