import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import useMutateCreateGrup from './useMutateCreateGrup';

import { createGroupSchema, GroupSchema } from '@/modules/shared/api/group';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

const useCreateGrupForm = () => {
  const { mutateAsync, isPending } = useMutateCreateGrup();
  const { currentUser } = useAuth();
  const form = useForm<GroupSchema>({
    resolver: zodResolver(createGroupSchema),
    defaultValues: {
      name: '',
      city: '',
      year: '',
      profileImg: '',
      coverImg: '',
      description: '',
      instagram: '',
      whatsapp: '',
      userId: currentUser?.uid ?? '',
    },
  });

  const submit = form.handleSubmit(async (data) => {
    console.log('submit create group', data);
    await mutateAsync(data);
  });

  return { form, submit, isPending };
};

export default useCreateGrupForm;
