import { StackActions, useNavigation, useRoute } from '@react-navigation/native';
import { Image } from 'expo-image';
import { serverTimestamp } from 'firebase/firestore';
import { parsePhoneNumber } from 'libphonenumber-js';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ActivityIndicator,
  AppState,
  Linking,
  Pressable,
  RefreshControl,
  ScrollView,
  Share,
  TouchableOpacity,
  useWindowDimensions,
  View,
  Text as RNText,
  Dimensions
} from 'react-native';
import Animated, { FadeInDown, FadeOutDown } from 'react-native-reanimated';
import Toast from 'react-native-root-toast';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { NumericFormat } from 'react-number-format';

import levelExpertIcon from '../../../assets/expert.png';
import genderIcon from '../../../assets/gender_trait.png';
import levelMenengahIcon from '../../../assets/menengah.png';
import messageIcon from '../../../assets/message_icon.png';
import levelIcon from '../../../assets/pemula.png';
import photoIcon from '../../../assets/photographer.png';
import priceIcon from '../../../assets/price.png';
import levelSemuaIcon from '../../../assets/semua.png';
import { PlayerPill } from '../../../components/PlayerPill';
import { useLobby } from '../../../providers/LobbyProvider';
import { AddPartisipanButton } from '../components/AddPartisipanButton';
import CancelLobbyBottomSheet from '../components/CancelLobbyBottomSheet';
import ImageViewerModal from '../components/ImageViewerModal';
import LobbyTrait from '../components/LobbyTrait';
import SuccessJoinLobbyBottomSheet from '../components/SuccessJoinLobbyBottomSheet';
import useDetailLobby from '../hooks/useDetailLobby';
import { JoinerType } from '../schema/createLobbySchema';
import { handlePay } from '../utils/handlePay';
import { RouteNavigation } from '../utils/route';
import { MixpanelManager } from '../../../src/MixpanelManager';

import useQueryGroupDetail from '@/modules/group/hooks/useQueryGroupDetail';
import { RouteProp } from '@/modules/navigation/types';
import {
  getPushTokens,
  saveNotificationToUsers,
  sendPushNotification,
} from '@/modules/shared/api/pushNotifications';
import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import Gradient from '@/modules/shared/components/Gradient';
import LoadingModal from '@/modules/shared/components/LoadingModal';
import Text from '@/modules/shared/components/basic/Text';
import { MapPinIcon } from '@/modules/shared/components/icons/MapPinIcon';
import { TimeIcon } from '@/modules/shared/components/icons/TimeIcon';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { FeatherIcons, FontAwesomeIcons, Ionicons } from '@/modules/shared/libs/icons';
import { assetList, Lobby, Notification } from '@/modules/shared/types';
import { Swipeable } from 'react-native-gesture-handler';
import LobbyLoginSuccessBottomSheet from '../components/LobbyLoginSuccessBottomSheet';
import { isWithin72HoursFromToday } from '@/modules/shared/utils/checkLobbyStatus';

const LobbyScreen = () => {
  const { bottom } = useSafeAreaInsets();
  const { params } = useRoute<RouteProp<'Lobby'>>();
  const navigation = useNavigation();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const { joinLobby, leaveLobby, logInOnLobby } = useLobby();
  const [refreshing, setRefreshing] = useState(false);
  const [visibleImage, setIsVisibleImage] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [indexImage, setIndexImage] = useState(0);
  const [canSwipeToLogin, setCanSwipeToLogin] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const [registerWraperHeight, setRegisterWraperHeight] = useState<number | null>(null);
  const { width } = useWindowDimensions();
  const screenWidth = Dimensions.get('window').width;

  const lobbyId = params.lobbyId;

  const { lobby, loading: loadingLobby, refetch: refetchLobbyDetails } = useDetailLobby(lobbyId);

  const { refreshData } = useLobby();

  const { data: group } = useQueryGroupDetail(lobby?.groupId || '');

  const participantCount = useMemo(() => {
    return lobby?.players.length ?? 0;
  }, [lobby]);

  const isJoinerTypeInternal = useMemo(
    () =>
      lobby?.details?.joinerType === JoinerType.Internal ||
      lobby?.details?.joinerType === undefined,
    [lobby],
  );

  const isJoinerTypeExternal = useMemo(
    () => lobby?.details?.joinerType === JoinerType.External,
    [lobby],
  );

  const isCreator = useMemo(() => {
    return lobby?.creator.userId === currentUser?.uid;
  }, [currentUser, lobby]);

  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);

  const _ = useMemo(() => {

    if (lobby == null) {
      return;
    }

    const isElligible = (eventDate: any) => {
      const now = new Date().getTime();
      const lobbyTimeFinished = eventDate.toDate().getTime() + 2 * 60 * 60 * 1000;

      const isElligible = lobbyTimeFinished >= now;
      return isElligible;
    };

    const lobbyPlayer = lobby?.players.find((p) => p.userId === currentUser?.uid);
    const canSwipe = lobby?.players.some((p) => p.userId === currentUser?.uid) && (new Date() > new Date(lobby?.details.eventDate.toDate()));
    const isElligibleToSwipe = isElligible(lobby?.details.eventDate ?? '') && (canSwipe ?? false);
    setCanSwipeToLogin(isElligibleToSwipe);
    setHasLoggedIn(lobbyPlayer?.hasLoggedIn ?? false);
  }, [lobby]);
  
  const canUserGiveCredits = useMemo(() => {
    const currentPlayerInLobby = lobby?.players.find(
      (player) => player.uid == (currentUser?.uid ?? ''),
    );
    if (currentPlayerInLobby == null) {
      return false;
    } else {
      const hasGivenCredits = currentPlayerInLobby.hasGivenOutCredits ?? false;
      return !hasGivenCredits && isWithin72HoursFromToday(lobby?.details.eventDate?.toDate());
    }
  }, [lobby, currentUser]);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        console.log('App has come to the foreground!');
      }

      appState.current = nextAppState;
      setAppStateVisible(appState.current);
      console.log('AppState', appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  const onShare = async () => {
    try {
      const url = 'https://app.playard.id/lobby/' + lobbyId;
      const date = DateTime.fromISO(
        new Date(lobby!.details.eventDate.toDate()).toISOString(),
      ).toFormat('dd LLL yyyy • t');
      const result = await Share.share({
        message:
          `${lobby!.creator.name} buka lobi basket di tanggal ${date}, lho.. Coba cek di Playard dan ikut main yuk!` +
          '\n' +
          url,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      console.log(error, '<<');
    }
  };

  const handleShowToast = () => {
    if (toastVisible) {
      return;
    }
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 5000);
  };

  const handleOpenMap = async () => {
    const link = lobby?.venue.gMapsLink;

    if (!link) {
      return;
    }

    const canOpenLink = await Linking.canOpenURL(link);

    if (!canOpenLink) {
      return;
    }

    Linking.openURL(link);
  };

  const shareButton = useMemo(() => {
    return (
      <TouchableOpacity onPress={onShare}>
        <Image source={require('../../../assets/share.png')} style={{ height: 24, width: 24 }} />
      </TouchableOpacity>
    );
  }, [lobby]);

  const SubstringText = (props) => {
    const arr = props.text.split(' ');
     const reducer = (acc, cur, index) => {
       let previousVal = acc[acc.length - 1];
       if (
         previousVal &&
         previousVal.startsWith('**') &&
         !previousVal.endsWith('**')
       ) {
         acc[acc.length - 1] = previousVal + ' ' + cur;
       } else {
         acc.push(cur);
       }
       return acc;
     };
   
     const text = arr.reduce(reducer, []);
   
     return (
       <Text style = { props.style }>
         {text.map((text) => {
           if (text.startsWith('**')) {
             return (
               <Text style={ props.substringStyle }>
                 {text.replaceAll('**', '')}{' '}
               </Text>
             );
           }
           return `${text} `;
         })}
       </Text>
     );
   };

  useEffect(() => {
    if (lobby) {
      navigation.setOptions({ headerRight: () => shareButton });
    }
  }, [lobby]);

  if (loadingLobby && !lobby) {
    return (
      <View className="flex-1 bg-white items-center justify-center">
        <ActivityIndicator color="rgb(147 120 255)" size="large" />
      </View>
    );
  }

  const handleCancel = () => {
    DynamicBottomSheetService.show({
      content: ({ close }) => {
        return (
          <CancelLobbyBottomSheet
            onOk={async () => {
              close();
              setLoading(true);
              await leaveLobby(lobbyId, currentUser?.uid);
              refetchLobbyDetails({});
              refreshData();
              setLoading(false);
            }}
            onCancel={close}
          />
        );
      },
    });
  };

  const hanldeNavigate = () => {
    navigation?.navigate('GroupDetailScreen', {
      groupId: String(lobby?.groupId),
      isOwner: currentUser?.uid === group?.userId,
    });
  };

  const handleSuccess = async () => {
    const createdAt = serverTimestamp();
    const notification: Notification = {
      title: `${currentUser?.displayName || currentUser?.email} mendaftar dalam lobi`,
      body: `Cek lobi ${lobby?.details.title} di Playard untuk ikut main bareng ${currentUser?.displayName || currentUser?.email}`,
      createdAt,
      read: false,
      iconUrl: '',
      appLink: `playard://lobby/${lobby?.id}`,
    };

    saveNotificationToUsers(currentUser?.followers, notification);
    const pushTokenList = await getPushTokens(currentUser?.followers);
    console.log('follower pushtokenlist', pushTokenList);
    // TODO type
    if (pushTokenList.length > 0) {
      sendPushNotification(pushTokenList as any, notification);
    }

    if (lobby?.details.price == 0) return;
    DynamicBottomSheetService.show({
      content: ({ close }) => {
        return (
          <SuccessJoinLobbyBottomSheet
            onOk={async () => {
              close();
              navigation.reset({
                index: 0,
                routes: [{ name: 'HomeTabs' }],
              });
            }}
            onCancel={close}
          />
        );
      },
    });
  };

  const handleRegister = async () => {
    setLoading(true);
    if (lobby?.details.price === 0) {
      await joinLobby(lobbyId, currentUser?.uid);
      setLoading(false);
      handleSuccess();
      setLoading(false);
      refetchLobbyDetails({});
      refreshData();
      return;
    }
    console.log('paid lobby');
    console.log('current user in payment', currentUser);
    await handlePay(currentUser, lobby as Lobby, {
      onClose: () => {
        console.log('close pay');
        console.log(
          'cannot get onSuccess callBack via payment link, use this to refetch lobbydetails',
        );
        setLoading(false);
        refetchLobbyDetails({});
        if (lobby?.players?.find((p) => p.userId === currentUser?.uid)) {
          handleSuccess();
        }
      },
      onFailure: () => setLoading(false),
      onSuccess: async () => {
        console.log('success pay');
        await joinLobby(lobbyId, currentUser?.uid);
        setLoading(false);
        refetchLobbyDetails({});
        handleSuccess();
        refreshData();
      },
    });
  };

  const handleShowSocial = () => {
    const cleanIGname = group?.instagram?.replace('@', '');
    const cleanWhatsapp = parsePhoneNumber(String(group?.whatsapp), 'ID')?.formatInternational();

    DynamicBottomSheetService.show({
      content: ({ close }) => {
        return (
          <View className="px-4 space-y-6 pb-6">
            {cleanIGname !== '' && (
              <Pressable
                className="flex-row items-center"
                onPress={() => {
                  close();
                  Linking.openURL(`https://instagram.com/${cleanIGname}`);
                }}>
                <FeatherIcons name="instagram" size={20} />
                <Text className="text-base ml-4" fontFamily="InterSemiBold">
                  {cleanIGname}
                </Text>
              </Pressable>
            )}

            {cleanWhatsapp !== '' && (
              <Pressable
                className="flex-row items-center"
                onPress={() => {
                  close();
                  if (cleanWhatsapp) {
                    const removeSpace = cleanWhatsapp.replace(/\s/g, '');
                    Linking.openURL(`https://wa.me/${removeSpace}`);
                  }
                }}>
                <FontAwesomeIcons name="whatsapp" size={20} />
                <Text className="text-base ml-4" fontFamily="InterSemiBold">
                  {cleanWhatsapp}
                </Text>
              </Pressable>
            )}
          </View>
        );
      },
    });
  };

  const renderLevel = () => {
    if (!lobby?.details.category) {
      return;
    }

    switch (lobby!.details.category) {
      case 'Pemula':
        return <LobbyTrait iconSource={levelIcon} text={lobby!.details.category} />;
      case 'Expert':
        return <LobbyTrait iconSource={levelExpertIcon} text={lobby!.details.category} />;
      case 'Menengah':
        return <LobbyTrait iconSource={levelMenengahIcon} text={lobby!.details.category} />;
      case 'Semua':
        return <LobbyTrait iconSource={levelSemuaIcon} text={lobby!.details.category} />;

      default:
        return <LobbyTrait iconSource={levelSemuaIcon} text={lobby!.details.category} />;
    }
  };

  const routeToProfile = async (userId: string) => {
    if (userId === currentUser?.uid) return;
    navigation.navigate('ProfileScreen', { uid: userId });
  };

  const joined = lobby?.players.find((p) => p.userId === currentUser?.uid);
  const isLobyFull = Number(lobby?.players.length) === Number(lobby?.details.capacity);

  const images = (
    lobby?.gallery?.filter((g) => !g.includes('.mp4') && !g.includes('.mov')) ?? []
  ).map((g) => ({
    uri: g,
  }));

  const eventDate = new Date(lobby!.details.eventDate.toDate());
  const today = new Date();
  const isEarlyBirdAvailable = today < eventDate && lobby?.details.earlyBirdPrice;
  const isClosedHours = new Date().setHours(new Date().getHours() - 72).valueOf();

  const handleLoginSwipeLeft = () => {
    const leftAndRightPaddings = 16 + 16;
    const swiperBoxWidth = 52;
    const swiperBoxMargins = 4 + 4;
    const totalMaxSwipeDistance = screenWidth - (leftAndRightPaddings + swiperBoxWidth + swiperBoxMargins);
    return <View style = {{width: totalMaxSwipeDistance, backgroundColor: 'white', opacity: 0.2}}/>
  };

  const handleDidSwipeLoginFinish = () => {
    MixpanelManager.track('didSwipeToLogin', currentUser?.uid, { 
      lobbyId: lobby?.id ?? '',
      page: 'LobbyDetailScreen'
    });
    setCanSwipeToLogin(false);
    setHasLoggedIn(true);
    logInOnLobby(lobby?.id, currentUser?.uid);

    DynamicBottomSheetService.show({
      content: ({ close }) => {
        return (
          <LobbyLoginSuccessBottomSheet
            onSkip={ () => {
              MixpanelManager.track('clickSkipTakePhotoAfterLogin', currentUser?.uid, { 
                lobbyId: lobby?.id ?? '',
                page: 'LobbyDetailScreen'
              });
              close();
            }}
            onTakePhoto={ () => {
              close();
              MixpanelManager.track('clickTakePhotoAfterLogin', currentUser?.uid, {
                lobbyId: lobby?.id ?? '',
                page: 'LobbyDetailScreen'
              });
              navigation?.navigate('CreateGroupPostScreen', {});
            }}
            userId = { currentUser?.uid }
          />
        );
      },
    });
  };

  const renderButton = () => {
    if (isLobyFull && !joined) return null;

    if (joined) {
      return (
        <Pressable
          className="bg-red-500 rounded-md w-full items-center py-3"
          onPress={handleCancel}>
          <Text className="text-white font-semibold text-base">Batalkan Pemesanan</Text>
        </Pressable>
      );
    }

    if (isEarlyBirdAvailable) {
      return (
        <>
          <View className="flex-row mb-4 justify-between">
            <Pressable
              className="flex-row items-center gap-x-[2px]"
              onPress={() => {
                const eventDate = DateTime.fromISO(
                  new Date(lobby!.details.eventDate.toDate()).toISOString(),
                )
                  .setLocale('id')
                  .toFormat('EEEE, dd MMMM');

                Toast.show(
                  `Nikmati potongan harga untuk pendaftaran sebelum hari bermain lobi (${eventDate})`,
                  {
                    duration: Toast.durations.SHORT,
                    position:
                      registerWraperHeight !== null ? -registerWraperHeight - bottom - 8 : -160,
                    textStyle: {
                      fontFamily: 'Inter',
                      fontSize: 12,
                      fontWeight: '400',
                      color: '#433F51',
                      textAlign: 'left',
                    },
                    containerStyle: {
                      backgroundColor: '#ffffff',
                      borderWidth: 1,
                      borderColor: '#D9D9D9',
                      borderRadius: 16,
                      width: width - 32,
                      padding: 20,
                    },
                    shadow: false,
                  },
                );
              }}>
              <Text className="text-[#838383] text-[14px] font-normal">Harga Early Bird</Text>
              <Image source={require('../../../assets/icon-info.png')} className="w-4 h-4" />
            </Pressable>
            <View className="flex-row items-center">
              <NumericFormat
                value={lobby!.details.price.toString()}
                displayType="text"
                thousandSeparator
                renderText={(formattedValue) => (
                  <Text className="text-[#838383] text-[14px] font-normal line-through mr-1">
                    Rp{formattedValue}
                  </Text>
                )} // <--- Don't forget this!
              />
              <NumericFormat
                value={lobby!.details.earlyBirdPrice}
                displayType="text"
                thousandSeparator
                renderText={(formattedValue) => (
                  <Text className="text-[#5F48B9] text-[14px] font-bold">Rp{formattedValue}</Text>
                )} // <--- Don't forget this!
              />
            </View>
          </View>
          <Pressable
            className="bg-playard rounded-md w-full items-center py-3"
            onPress={handleRegister}>
            <Text className="text-white font-semibold text-base">Daftar</Text>
          </Pressable>
        </>
      );
    }

    return (
      <Pressable
        className="bg-playard rounded-md w-full items-center py-3"
        onPress={handleRegister}>
        <Text className="text-white font-semibold text-base">Daftar</Text>
      </Pressable>
    );
  };
  return (
    <View className="flex-1 bg-white" style={{ paddingBottom: bottom }}>
      <View className="flex-1 relative">
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                setRefreshing(true);
                refetchLobbyDetails({});
                refreshData();
                refreshData().then(() => setRefreshing(false));
              }}
            />
          }
          className="bg-white"
          contentContainerStyle={{ paddingBottom: 16 }}>
          <View className="bg-playard p-4 flex-row space-x-4 items-center">
            <Pressable onPress={hanldeNavigate}>
              <Image
                source={{ uri: lobby?.creator.photoUrl ?? assetList.PLAYARD_HOST }}
                className="h-10 w-10 rounded-full object-cover"
              />
            </Pressable>

            <View className="flex-1">
              <View className="flex-row items-center">
                <Text fontFamily="InterBold" className="font-semibold text-white">
                  {lobby?.details.title}
                </Text>
              </View>

              <Text className="text-xs text-white">{lobby?.creator.name ?? 'Playard'}</Text>
            </View>
            {group && (
              <Pressable onPress={handleShowSocial}>
                <Image source={messageIcon} className="h-6 w-6 rounded-full" />
              </Pressable>
            )}
          </View>

          {isCreator && participantCount === 0 && (
            <View className="px-4 ">
              <Pressable
                className="border border-gray-300 rounded-lg px-4 py-3 mt-4 flex-row items-center space-x-4"
                onPress={() => {
                  if (group) {
                    navigation.navigate('UpdateLobbyScreen', {
                      groupId: String(group.id),
                      lobbyId,
                    });
                  }
                }}>
                <View className="h-8 w-8 border border-gray-300 rounded-md items-center justify-center">
                  <FeatherIcons name="edit-3" size={16} className="text-gray-600" />
                </View>
                <Text className="color-[#433F51] flex-1">Atur Lobi sebelum ada pendaftar</Text>
                <FeatherIcons name="chevron-right" size={16} className="text-gray-600" />
              </Pressable>
            </View>
          )}

          { canSwipeToLogin && !hasLoggedIn && (
            <View
              className = "flex-row items-center"
              style = {{ 
                borderRadius: 30, 
                borderWidth: 1,
                borderColor: '#5F48B9',
                overflow: 'hidden',
                backgroundColor: '#EFEBFF',
                marginTop: 16,
                marginHorizontal: 16,
                height: 60
              }}>
            <SubstringText 
              style = {{ 
                padding: 14, 
                marginLeft: 52, 
                position: 'absolute', 
                width: screenWidth - 32 - 52,
                fontFamily: 'Inter',
                fontSize: 12,
                color: '#433F51'
              }} 
              substringStyle = {{ 
                color: '#5F48B9', 
                fontFamily: 'InterBold',
                fontWeight: 'bold' 
              }}
              text = { 'Geser untuk mencatat kehadiran dan **dapatkan exp**'}/>
            <View style = { { position: 'absolute', width: screenWidth - 32 , height: 60}}>
              <Swipeable renderLeftActions = { handleLoginSwipeLeft }
                          onSwipeableOpen= { handleDidSwipeLoginFinish }
                          overshootLeft = { false }>
                  <View style = { { 
                    height: 52, 
                    width: 52, 
                    backgroundColor: 'white', 
                    borderColor: '#5F48B9',
                    borderWidth: 2, 
                    borderRadius: 26, 
                    flexShrink: 1,
                    marginLeft: 4,
                    alignItems: 'center',
                    marginVertical: 4
                  } }>
                    
                    <View className= 'flex-row items-center' style = { {flexGrow: 1}}>
                      <Image source={require('../../../assets/slide-right.png')} style={{ height: 32, width: 32}} />
                    </View>
                  </View>
              </Swipeable>
            </View>
            </View>
          )}

          { hasLoggedIn && (
            <View className='px-4' style = {{ paddingTop: 16}}>
                <Text
                style = {{ textAlign: 'center', fontSize: 12, color: '#5F48B9', fontWeight: '700', fontFamily: 'InterBold'}}>
                  Kamu sudah mencatat kehadiran.
                </Text>
            </View>
          )}

          <View>
            <View className="space-y-3 mt-4 px-4 py-3">
              <View className="flex-row items-center space-x-3">
                <TimeIcon />
                <Text className="text-sm text-neutral-800">{`${DateTime.fromISO(
                  new Date(lobby!.details.eventDate.toDate()).toISOString(),
                ).toFormat('dd LLL yyyy • t')},  ${lobby!.details.durationInMinutes} menit`}</Text>
              </View>
              <View className="flex-row space-x-3">
                <MapPinIcon />
                <View className="ml-2 flex-shrink">
                  <Text className="text-sm  text-neutral-800">
                    {lobby?.venue.title}
                    {'\n\n'}
                    {lobby?.venue.address}
                  </Text>
                  {lobby?.venue.gMapsLink && (
                    <Pressable className="mt-2" onPress={handleOpenMap}>
                      <Text className="text-primary underline">Buka di Maps</Text>
                    </Pressable>
                  )}
                </View>
              </View>
            </View>
          </View>
          <View className="flex-row items-start py-5 rounded-xl border border-gray-300 mx-4 mt-6 mb-8">
            {renderLevel()}
            <LobbyTrait iconSource={genderIcon} text={lobby!.details.genderGroup} />
            <LobbyTrait
              iconSource={priceIcon}
              text={lobby!.details.price.toString()}
              parseTextAsCurrency
            />
            <LobbyTrait
              iconSource={photoIcon}
              text={lobby?.details?.hasPhotographer ? 'Ada' : 'Tidak Ada'}
            />
          </View>

          <View className="px-4">
            <View className="flex-row justify-between">
              <Text className="text-xl" fontFamily="InterSemiBold">
                Partisipan
                {isJoinerTypeInternal ? ` (${participantCount} / ${lobby?.details.capacity})` : ''}
              </Text>
              {isJoinerTypeInternal && isCreator && (
                <AddPartisipanButton
                  lobbyId={lobbyId}
                  initialParticipants={lobby?.players
                    ?.filter((p) => !p.paid)
                    .map((p) => ({
                      paid: p.paid ?? false,
                      userId: p.uid,
                      uid: p.uid,
                      email: p.email,
                      photoURL: p?.photoURL ?? '',
                      displayName: p?.displayName ?? p?.email,
                    }))}
                  paidParticipantIds={lobby?.players.filter((p) => p.paid).map((p) => p.uid) ?? []}
                  onSuccess={() => {
                    setRefreshing(true);
                    refetchLobbyDetails({});
                    refreshData();
                    refreshData().then(() => setRefreshing(false));
                  }}
                />
              )}
              {canUserGiveCredits && participantCount > 0 && (
                <Gradient
                  style={{
                    borderRadius: 15,
                    justifyContent: 'center',
                    paddingHorizontal: 10,
                  }}>
                  <TouchableOpacity
                    onPress={() => {
                      MixpanelManager.track('clickButtonGiveCredits', { 
                        lobbyId: lobby?.id ?? '',
                        page: 'LobbyDetailScreen'
                      })
                      navigation.navigate(RouteNavigation.CreditGiven, {
                        participants:
                          lobby?.players.filter((p) => p.userId !== currentUser.uid) ?? [],
                        sourceLobbyId: lobby?.id ?? '',
                      });
                    }}
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <View>
                      <RNText className="text-center font-semibold text-xs px-4 py-1">
                        Beri Kredit
                      </RNText>
                    </View>
                  </TouchableOpacity>
                </Gradient>
              )}
            </View>
            {isJoinerTypeExternal && (
              <View className="px-1 mt-2 flex-row items-center">
                <View className="bg-[#5F48B9]/10 space-x-2 rounded-full p-1 pr-3 flex-row items-center">
                  <Image
                    source={{ uri: lobby?.creator.photoUrl ?? assetList.PLAYARD_HOST }}
                    className="h-4 w-4 rounded-full"
                  />
                  <Text className=" text-black text-xs">
                    {lobby?.creator.name ?? 'Playard'} + ({participantCount}/
                    {lobby?.details.capacity})
                  </Text>
                </View>
                <Pressable hitSlop={10} onPress={handleShowToast}>
                  <Ionicons
                    name="information-circle"
                    size={18}
                    color="#8b8b8b"
                    style={{ marginLeft: 4 }}
                  />
                </Pressable>
              </View>
            )}

            {isCreator && lobby?.players.length === 0 && (
              <Text className="text-center text-gray-500 mt-4 text-xs">
                Ada pemain yang akan ikut bermain tapi tidak akan daftar lewat Playard? Tambahkan
                dengan tombol di kanan.
              </Text>
            )}

            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                paddingTop: 20,
              }}>
              {lobby?.players.map((p, index: number) => (
                <PlayerPill player={p} key={index} routeFunc={routeToProfile} />
              ))}
            </View>
          </View>

          <View className="px-4 mt-6">
            <Text className="text-xl" fontFamily="InterSemiBold">
              Catatan
            </Text>

            <Text className="text-xs text-gray-600 mt-2">{lobby?.details.description}</Text>
          </View>
          {!!lobby?.gallery && (
            <View className="px-4 mt-6">
              <Text fontFamily="InterSemiBold" className="text-xl mb-3">
                Foto & Video
              </Text>
              <ScrollView horizontal showsVerticalScrollIndicator={false}>
                {lobby?.gallery
                  .filter((g) => !g.includes('.mp4') && !g.includes('.mov'))
                  .map((image, id) => (
                    <Pressable
                      className="h-40 mr-2 w-40 bg-gray-100"
                      key={`image-${id}`}
                      onPress={() => {
                        setIsVisibleImage(true);
                        setIndexImage(id);
                      }}>
                      <Image source={{ uri: image }} className="h-40 mr-2 w-40 object-cover" />
                    </Pressable>
                  ))}
              </ScrollView>
            </View>
          )}
        </ScrollView>
        {toastVisible && (
          <Animated.View
            entering={FadeInDown}
            exiting={FadeOutDown}
            className="absolute bottom-0 w-full z-10 p-4">
            <View className="bg-white p-4 border border-gray-300 rounded-xl">
              <Text>
                Daftar pemain di lobi ini hanya tambahan di luar member grup yang sudah rutin
                bermain.
              </Text>
            </View>
          </Animated.View>
        )}
      </View>

      <View
        className="bg-white p-4 border-t border-t-gray-200"
        onLayout={(event) => {
          if (registerWraperHeight === null) {
            setRegisterWraperHeight(event.nativeEvent.layout.height);
          }
        }}>
        {renderButton()}
      </View>
      <LoadingModal visible={loading} />
      <ImageViewerModal
        imageIndex={indexImage}
        images={images}
        visible={visibleImage}
        onRequestClose={() => setIsVisibleImage(false)}
      />
    </View>
  );
};

export default LobbyScreen;
