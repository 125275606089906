import Ionicons from '@expo/vector-icons/Ionicons';
import { Image } from 'expo-image';
import React, { useState } from 'react';
import { Platform, Pressable, Text, View } from 'react-native';

import { LobbyCard } from '../../../components/LobbyCard';
import useFetchUserData from '../hooks/useFetchUserData';
import useProfileDetail from '../hooks/useProfileDetail';

import ImageViewerModal from '@/modules/lobby/components/ImageViewerModal';
import TagList from '@/modules/lobby/components/TagList';
import { useNavigation } from '@/modules/navigation/RootNavigation';
import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { assetList, Lobby } from '@/modules/shared/types';

type Props = {
  userId?: string;
  showSetting?: boolean;
  upCommingLobbies: Lobby[];
  credits: string[];
};

const ProfileHeader = ({ userId, showSetting = true, upCommingLobbies, credits }: Props) => {
  const { data: profileDetailSelf } = useFetchUserData();
  const { profileDetail } = useProfileDetail(userId);

  const navigaiton = useNavigation();

  const [visibleImage, setIsVisibleImage] = useState(false);
  const { deleteAccount, currentUser } = useAuth();

  const hasUpcomingLobby = upCommingLobbies.length > 0;

  const isOwn = userId === currentUser?.uid;

  const onEdit = () => {
    navigaiton?.navigate('EditProfileScreen', { canBack: true });
  };

  const handleSettingPress = () => {
    DynamicBottomSheetService.show({
      content: ({ close }) => (
        <View className="p-4">
          <Pressable
            className="p-4 rounded-lg mt-4 bg-playard"
            onPress={() => {
              close();
              onEdit();
            }}>
            <Text className=" font-semibold text-center text-white">Edit Profil</Text>
          </Pressable>
          {deleteAccount && (
            <Pressable
              className="p-4 rounded-lg mt-4"
              onPress={async () => {
                console.log('delete account');
                await deleteAccount();
                close();
              }}>
              <Text className=" font-semibold text-center text-red-500">
                Ajukan Penghapusan Akun
              </Text>
            </Pressable>
          )}
        </View>
      ),
      bottomSheetProps: {
        keyboardBehavior: 'extend',
      },
    });
  };
  const amountOfCredits = calculateDuplicatesAsArray(credits);

  return (
    <View>
      <View className="flex-row mt-4 space-x-4 ">
        <View className="bg-gray-100 rounded-full w-20 h-20 overflow-hidden">
          <Pressable onPress={() => setIsVisibleImage(true)}>
            <Image
              className="w-20 h-20"
              source={{
                uri: isOwn
                  ? profileDetailSelf?.photoURL || assetList.PLACEHOLDER_USER
                  : profileDetail?.photoURL || assetList.PLACEHOLDER_USER,
              }}
              cachePolicy="memory-disk"
            />
          </Pressable>
        </View>
        <View className="flex-1 space-y-1">
          <View className="flex-row items-center space-x-2">
            <Text className="font-semibold text-xs">
              {profileDetail?.displayName ?? profileDetail?.email}
            </Text>
            {showSetting && (
              <Pressable onPress={handleSettingPress}>
                <Ionicons name="md-settings-outline" size={12} color="#0f0f0f" />
              </Pressable>
            )}
          </View>
          <View className="flex-row space-x-4">
            <Text className="text-sm font-semibold">
              {profileDetail?.followers?.length}
              <Text className="text-xs font-normal text-gray-500"> Pengikut</Text>
            </Text>
            <Text className="text-sm font-semibold">
              {profileDetail?.joinedLobbies?.length}
              <Text className="text-xs font-normal text-gray-500"> Kali Bermain</Text>
            </Text>
          </View>
          <View className="flex-row flex-wrap">
            <TagList
              amountOfCredits={amountOfCredits}
              user={isOwn ? 'Kamu' : profileDetail?.displayName ?? ''}
            />
          </View>
        </View>
      </View>

      {hasUpcomingLobby && (
        <Text className="text-xl font-semibold mt-4 mb-2">Sedang Dalam Lobi</Text>
      )}

      {hasUpcomingLobby &&
        upCommingLobbies.map((lobby) => {
          return (
            <View
              key={lobby.id}
              className="w-full bg-white px-3 rounded-lg mr-2 border-[1px] border-[#D9D9D9]">
              <LobbyCard
                key={lobby.id}
                currentUserId={userId || ''}
                firestoreLobby={{
                  id: lobby.id,
                  data: lobby,
                }}
              />
            </View>
          );
        })}

      <ImageViewerModal
        showDownload={showSetting}
        imageIndex={0}
        images={[{ uri: profileDetail?.photoURL ?? assetList.PLACEHOLDER_USER }]}
        visible={visibleImage}
        onRequestClose={() => setIsVisibleImage(false)}
      />
    </View>
  );
};

const calculateDuplicatesAsArray = (arr: string[]) => {
  const countMap: { [key: string]: number } = {};
  arr.forEach((item) => {
    countMap[item] = (countMap[item] || 0) + 1;
  });

  const resultArray = Object.keys(countMap).map((key) => ({
    tag: key,
    count: countMap[key],
  }));
  return resultArray;
};

export default ProfileHeader;
