import { collection, getDocs, query, Timestamp, where } from 'firebase/firestore';

import { Lobby } from '../types';

import { db } from '@/modules/shared/libs/firebase/app';

export const getAllActiveLobbies = async () => {
  const ref = collection(db, 'lobbies');
  const q = query(ref, where('details.eventDate', '>=', Timestamp.fromDate(new Date())));
  const lobbiesSnapshot = await getDocs(q);

  return lobbiesSnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    } as Lobby;
  });
};

export const getAllGroupLobbies = async (groupId: string) => {
  const ref = collection(db, 'lobbies');

  const lobbiesSnapshot = await getDocs(query(ref, where('groupId', '==', groupId)));

  return lobbiesSnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    } as Lobby;
  });
};

export const getGroupLobbies = async (groupId: string) => {
  const ref = collection(db, 'lobbies');

  const lobbiesSnapshot = await getDocs(
    query(
      ref,
      where('groupId', '==', groupId),
      where('details.eventDate', '>=', Timestamp.fromDate(new Date())),
    ),
  );

  return lobbiesSnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    } as Lobby;
  });
};
