import { Image } from 'expo-image';
import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import { Platform, Text, View } from 'react-native';

import PlButton from '../../../components/PlButton';
import { s } from '../../../styles';
import useFetchUserData from '../../account/hooks/useFetchUserData';
import { useNavigation } from '../../navigation/RootNavigation';

import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import { assetList } from '@/modules/shared/types';

type Props = {
  hide: () => void;
  groupId: string;
};

const CreateLobbyBottomSheetPrompt = ({ hide, groupId }: Props) => {
  const { data } = useFetchUserData();

  const navigation = useNavigation();
  const handleContinue = () => {
    // hide();
    if (Platform.OS != 'web') {
      navigation?.navigate('CreateLobbyScreen', { groupId: data?.groupAdmin ?? groupId });
      hide();
    } else {
      renderBottomSheet();
    }
  };
  const renderBottomSheet = () => {
    console.log('renderBottomSheet');
    return DynamicBottomSheetService.show({
      content: ({ close }) => (
        <View style={[s.bottomSheetContainer, { marginBottom: 14 }]}>
          <View>
            <Image
              style={{ width: 200, height: 200, alignSelf: 'center' }}
              source={assetList.NEW_APP}
            />
            <View style={s.newBottomSheetTextContainer}>
              <Text style={{ ...s.lobbyTitleText, padding: 8 }}>Harus Lewat Aplikasi</Text>
              <Text style={{ ...s.capacityText, textAlign: 'center' }}>
                Silahkan gunakan aplikasi Playard untuk membuka lobi
              </Text>
            </View>
          </View>
          <View style={s.newBottomSheetButtonContainer}>
            <PlButton
              title="Unduh Sekarang"
              type="rounded"
              onPress={async () => {
                // TODO add link to app store, only show if ios for now
                await WebBrowser.openBrowserAsync('https://playard.id');
                close();
              }}
            />
          </View>
        </View>
      ),
    });
  };
  return (
    <View style={{ padding: 16 }}>
      <View
        style={{
          paddingBottom: 20,
          marginBottom: 20,
          borderBottomWidth: 1,
          borderBottomColor: '#eee',
        }}>
        <Text style={{ fontSize: 16, fontWeight: '700' }}>Cara Membuat Lobi</Text>
      </View>

      {[
        '1. Pastikan kamu sudah melakukan booking lapangan terlebih dahulu',
        '2. Isi detail dari lobi dan pastikan data yang diisi tidak salah karena tidak akan bisa diubah setelah ada yang mendaftar',
      ].map((item, index) => (
        <View key={index} style={{ marginBottom: 16 }}>
          <Text style={{ fontFamily: 'Inter', color: '#3a3745' }}>{item}</Text>
        </View>
      ))}

      <View style={{ height: 16 }} />
      <PlButton onPress={handleContinue} title="Lanjut" customStyle={{ borderRadius: 8 }} />
      <View style={{ height: 16 }} />
      <PlButton
        onPress={hide}
        title="Batal"
        type="secondary"
        customStyle={{ backgroundColor: 'white', borderRadius: 8 }}
      />
    </View>
  );
};

export default CreateLobbyBottomSheetPrompt;
