import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { Image } from 'expo-image';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, Dimensions, ListRenderItem, Pressable, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { PostData } from 'src/domain/PostResponse';

// @ts-ignore
// @ts-ignore
import GroupDetailHeader from '../components/GroupDetailHeader';
import useNeedRefetch from '../hooks/useNeedRefetch';
import { useQueryGetPosts } from '../hooks/useQueryGroupPost';

import { RouteProp } from '@/modules/navigation/types';
import Text from '@/modules/shared/components/basic/Text';
import { Ionicons } from '@/modules/shared/libs/icons';

type PostItemProps = { item: PostData };
type ImageDownloadState = 'downloading' | 'error' | 'finish';
const PostItemCell = ({ item }: PostItemProps) => {
  const navigation = useNavigation();
  const windowWidth = Dimensions.get('window').width;
  const windowWidthDivBy2 = windowWidth / 2;

  const [downloadState, setDownloadState] = useState<ImageDownloadState>('downloading');

  return (
    <Pressable
      className="flex-[0.5] mb-[1px]"
      style={{ height: windowWidthDivBy2 }}
      onPress={() => {
        navigation.navigate('GroupPostDetailScreen', { postId: item.id });
      }}>
      <View className="relative">
        <Image
          cachePolicy="memory-disk"
          source={{
            uri: item.photoUrl,
          }}
          contentFit="cover"
          className="flex"
          style={{ height: windowWidthDivBy2 }}
          onProgress={(event) => {
            if (event.loaded === event.total) {
              setDownloadState('finish');
            }
          }}
          onLoad={() => {
            setDownloadState('finish');
          }}
        />
        {downloadState === 'downloading' && (
          <ActivityIndicator
            size="small"
            color="black"
            className="absolute transform -translate-x-1/2 -translate-y-1/2 bg-[#DDDDDD]/75 w-full h-full"
          />
        )}
      </View>
    </Pressable>
  );
};

const GroupDetailScreen = () => {
  const { params } = useRoute<RouteProp<'GroupDetailScreen'>>();
  const { groupId, isOwner, postId } = params;

  const { data: response, fetchNextPage, refetch } = useQueryGetPosts(groupId);

  const list = response?.pages.flatMap((r) => r.data);

  const isNeedRefetch = useNeedRefetch((state) => state.needRefetch);
  const navigation = useNavigation();
  const setNeedRefetch = useNeedRefetch((state) => state.setNeedRefetch);
  const linkTo = useLinkTo();
  const { bottom } = useSafeAreaInsets();

  // MARK: Effects
  useEffect(() => {
    if (postId) {
      linkTo(`/post/${postId}`);
    }
  }, []);

  useEffect(() => {
    if (isNeedRefetch) {
      refetch();
      // Reset State
      setNeedRefetch(false);
    }
  }, [isNeedRefetch]);

  const onEndReached = useCallback(() => {
    fetchNextPage();
  }, []);

  const renderPostItem = useCallback<ListRenderItem<PostData>>(({ item }) => {
    return <PostItemCell item={item} />;
  }, []);

  const onPressAddPosting = useCallback(() => {
    navigation?.navigate('CreateGroupPostScreen', { groupId });
  }, []);

  const emptyStatePosts = () => {
    if (isOwner) {
      return (
        <View className="items-center space-y-[12px]">
          <Pressable onPress={onPressAddPosting}>
            <Ionicons name="add-circle" size={28} color="#BDBDBD" />
          </Pressable>
          <Text fontFamily="Inter">Buat postingan pertama kamu</Text>
        </View>
      );
    }

    return (
      <View className="items-center">
        <Text className="text-neutral-800 my-4 text-center" fontFamily="InterBold">
          Tidak Ada Postingan
        </Text>
      </View>
    );
  };

  return (
    <FlatList
      className="flex bg-white"
      showsVerticalScrollIndicator={false}
      data={list}
      numColumns={2}
      columnWrapperStyle={{ columnGap: 1 }}
      keyExtractor={(item, index) => `${index}-${item.id}`}
      ListHeaderComponent={
        <GroupDetailHeader
          groupId={groupId}
          isEmptyPost={list ? list.length === 0 : true}
          isOwner={isOwner}
        />
      }
      onEndReachedThreshold={0.1}
      onEndReached={onEndReached}
      renderItem={renderPostItem}
      ListEmptyComponent={emptyStatePosts}
      contentContainerStyle={{ paddingBottom: bottom }}
    />
  );
};

export default GroupDetailScreen;
