import { useMutation, useQueryClient } from '@tanstack/react-query';
import Toast from 'react-native-root-toast';

import { updateUser } from '@/modules/shared/api/user';
import { K_USER_DETAIL } from '@/modules/shared/constants/react-query';

type Params = {
  displayName: string;
  username: string;
  photoURL: string;
  id: string;
  skipCheck?: boolean;
};

const useMutateUpdateAccount = (skipCheck = false, hasPhoto = false, skipNotif = false) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, skipCheck: skipCheckParams, ...input }: Params) =>
      updateUser(id, input, skipCheckParams ?? skipCheck, hasPhoto),
    onError: (error) => {
      Toast.show(error?.message ?? 'Gagal update', {
        backgroundColor: '#ef4444',
        textColor: '#fef2f2',
        opacity: 1,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [K_USER_DETAIL],
      });
    },
  });
};

export default useMutateUpdateAccount;
