import axios from 'axios';
import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { NumericFormat } from 'react-number-format';

import useProfileDetail from '../hooks/useProfileDetail';

import useCheckPendingWithdrawals from '@/modules/lobby/hooks/useCheckPendingWithdrawals';
import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import { Button } from '@/modules/shared/components/basic/Button';
import Text from '@/modules/shared/components/basic/Text';

type Props = {
  userId?: string;
};

export const WalletCard = ({ userId }: Props) => {
  const { profileDetail, refetch, groupDetail } = useProfileDetail(userId);
  const { pending } = useCheckPendingWithdrawals(userId);
  const [loading, setLoading] = React.useState(false);
  if (!profileDetail?.wallet) return null;

  const withdraw = async () => {
    setLoading(true);
    try {
      const res = await axios.request({
        method: 'POST',
        baseURL:
          process.env.NODE_ENV === 'production'
            ? 'https://moneys-bucket-prod.fly.dev'
            : 'https://moneys-bucket-staging.fly.dev',
        url: '/withdraw-moneys',
        data: {
          userId,
        },
      });
      console.log('res', res.data, res.status);
      DynamicBottomSheetService.show({
        content: ({ close }) => {
          return (
            <View style={{ paddingHorizontal: 16, marginBottom: 32 }}>
              <Text style={{ fontSize: 18, fontWeight: '700' }}>{res.data.message}</Text>
            </View>
          );
        },
      });
      refetch();
      setLoading(false);
    } catch (error) {
      console.error('error withdrawing', error);
    }
  };

  return (
    <View style={styles.container}>
      <View style={{ ...styles.apart, padding: 6 }}>
        <View
          style={{
            ...styles.sameLine,
            paddingTop: 2,
          }}>
          <View>
            <Text fontFamily="InterBold" className="text-base text-white">
              {groupDetail?.name}{' '}
            </Text>
            <Text fontFamily="InterBold" className="pt-2 text-white text-sm">
              Balance
              <NumericFormat
                value={profileDetail?.wallet}
                displayType="text"
                thousandSeparator
                prefix=" Rp"
                renderText={(formattedValue) => (
                  <Text
                    className="text-sm text-white"
                    fontFamily="Inter"
                    // style={{ ...styles.lightText, fontWeight: 'normal', fontSize: 10 }}
                  >
                    {formattedValue}
                  </Text>
                )} // <--- Don't forget this!
              />
            </Text>
          </View>
        </View>

        <Button
          onPress={() => withdraw()}
          disabled={pending || loading}
          className="items-center justify-center  bg-black rounded-2xl h-10 ">
          <View className="flex-1" />
          <Text className="text-white text-center" fontFamily="InterBold">
            Tarik
          </Text>
        </Button>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    // height: 60,
    padding: 12,
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1,
    backgroundColor: '#5F48B9',
    borderRadius: 10,
  },
  alignRight: {
    alignItems: 'flex-end',
  },
  alignLeft: {
    alignItems: 'flex-start',
  },
  apart: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  capacityText: {
    fontWeight: '600',
    fontSize: 12,
    color: '#121212',
  },
  lightText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 14,
  },

  sameLine: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
  },
});
