import React from 'react';
import { TextInput as RNTextInput, TextInputProps } from 'react-native';

import { cn } from '@/modules/shared/utils/cn';

type Props = {
  className?: string;
  fontFamily?: 'Inter' | 'InterMedium' | 'InterSemiBold' | 'InterBold';
} & TextInputProps;

const TextInput = ({ fontFamily = 'Inter', ...props }: Props) => {
  return (
    <RNTextInput
      style={{ includeFontPadding: false, margin: 0, padding: 0, fontFamily }}
      {...props}
      className={cn(props.className)}
    />
  );
};

export default TextInput;
