import { zodResolver } from '@hookform/resolvers/zod';
import { StackActions, useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Text, View, TouchableOpacity, Alert } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { z } from 'zod';

import CustomModal from './components/CustomModal';
import ScreenContainer from '../../components/ScreenContainer';
import { addWarehouseForUser } from '../../firebase';
import { areAllValuesNotEmpty } from '../lobby/utils/isThereAnyEmptyValues';
import { RouteNavigation } from '../lobby/utils/route';

import TextInput from '@/modules/shared/components/basic/TextInput';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { Ionicons } from '@/modules/shared/libs/icons';
import { cn } from '@/modules/shared/utils/cn';

const schema = z.object({
  name: z.string(),
  city: z.string(),
  phoneNumber: z.string(),
});
type Schema = z.infer<typeof schema>;

const OpenWareHouse = () => {
  const { currentUser } = useAuth();
  const userId = currentUser.uid;
  const navigation = useNavigation();
  const { control, handleSubmit, setValue } = useForm<Schema>({
    defaultValues: {
      name: '',
      city: '',
      phoneNumber: '',
    },
    resolver: zodResolver(schema),
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [roomId, setRoomId] = useState('');

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setValue('city', '');
    setValue('name', '');
    setValue('phoneNumber', '');
  };

  const handleAddWareHouse = async (data: any) => {
    try {
      setModalVisible(false);

      const isAllValueWereFilled = areAllValuesNotEmpty(data);
      const fixedData = { ...data, items: [], userId };
      if (isAllValueWereFilled) {
        const room = await addWarehouseForUser(fixedData);
        if (room) {
          handleOpenModal();
          setRoomId(room);
        }
      } else {
        Alert.alert('Tolong lengkapi data anda');
      }
    } catch (error: any) {
      Alert.alert(error.toString());
    }
  };
  const addItem = () => {
    if (roomId.length > 0) {
      handleCloseModal();
      setTimeout(() => {
        navigation.dispatch(StackActions.replace(RouteNavigation.AddItem, { warehouseId: roomId }));
      }, 300); // forced close if there is no delay
    }
  };

  const onReject = () => {
    handleCloseModal();
    navigation.goBack();
  };

  return (
    <ScrollView>
      <ScreenContainer className="bg-white" safeAreaTop={false}>
        <View className="flex-1 p-4">
          <View className="bg-white rounded-lg p-4 mb-5 border border-gray-300">
            <Text className="text-sm font-semibold py-2">Nama Gudang</Text>
            <View>
              <Controller
                control={control}
                name="name"
                render={({ field, fieldState }) => (
                  <TextInput
                    placeholder=""
                    className={cn('border border-neutral-300 rounded-md text-base leading-5 p-3', {
                      'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                    })}
                    onChangeText={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </View>
            <Text className="text-sm font-semibold py-2">Kota</Text>
            <View>
              <Controller
                control={control}
                name="city"
                render={({ field, fieldState }) => (
                  <TextInput
                    placeholder=""
                    className={cn('border border-neutral-300 rounded-md text-base leading-5 p-3', {
                      'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                    })}
                    onChangeText={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </View>

            <Text className="text-sm font-semibold py-2">Kontak</Text>
            <View>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field, fieldState }) => (
                  <View
                    className={cn(
                      'flex-row items-center border border-neutral-300 rounded-md p-3',
                      {
                        'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                      },
                    )}>
                    <Ionicons name="logo-whatsapp" size={28} className="mr-2" />
                    <TextInput
                      placeholder=""
                      keyboardType="phone-pad"
                      className="flex-1 text-base leading-5"
                      onChangeText={field.onChange}
                      value={field.value}
                    />
                  </View>
                )}
              />
            </View>
          </View>

          <CustomModal visible={modalVisible} onClose={onReject} onAddItem={addItem} />

          <TouchableOpacity
            className="bg-[#5F48B9] p-3 rounded-lg items-center"
            onPress={handleSubmit(handleAddWareHouse)}>
            <Text className="text-white text-base font-bold">Buka Gudang</Text>
          </TouchableOpacity>
        </View>
      </ScreenContainer>
    </ScrollView>
  );
};

export default OpenWareHouse;
