import { QueryClient, QueryClientProvider as RNQueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const QueryClientProvider = ({ children }: PropsWithChildren) => {
  return <RNQueryClientProvider client={client}>{children}</RNQueryClientProvider>;
};
