import { useNavigation } from '@react-navigation/native';
import { DocumentData } from 'firebase/firestore';
import React, { useCallback, useMemo, useState } from 'react';
import {
  View,
  Text,
  Pressable,
  FlatList,
  ListRenderItem,
  TouchableOpacity,
  Image,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Shop from '../../../assets/shop.png';
import { getWarehouseByUserId } from '../../../firebase';
import ProfileHeader from '../components/ProfileHeader';
import { WalletCard } from '../components/WalletCard';
import useWarehouseList from '../hooks/useFetchWarehouse';
import useUserFollowers from '../hooks/useUserFollowers';
import useUserActivities from '../hooks/useUserActivities';

import PreferencesButton from '@/modules/account/components/PreferencesButton';
import { useUserCredits } from '@/modules/lobby/hooks/useUserCredits';
import { RouteNavigation } from '@/modules/lobby/utils/route';
import ActivityCell from '@/modules/shared/components/ActivityCell';
import ProfileCell from '@/modules/shared/components/ProfileCell';
import SegmentedControl from '@/modules/shared/components/SegmentedControl';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { Lobby } from '@/modules/shared/types';
import { RefreshControl, ScrollView } from 'react-native-gesture-handler';
import { type UserActivity } from '../hooks/useUserActivities';

const segments = ['Aktivitas', 'Pengikut'];

const AccountScreen = () => {
  const { currentUser, signOut, refetchUserData } = useAuth();
  const { top } = useSafeAreaInsets();
  const navigation = useNavigation();
  const { warehouses } = useWarehouseList(currentUser.uid);

  const activities = useUserActivities();
  const { followers } = useUserFollowers();

  const { credits } = useUserCredits(currentUser?.uid ?? '');
  const [refreshing, setRefreshing] = useState(false);
  const pastActivities = useMemo(
    () =>
      activities
        ?.filter((activity) => activity?.lobby.details?.eventDate.toDate() < new Date())
        .sort(
          (a, b) => b.lobby.details.eventDate.toDate().getTime() - a.lobby.details.eventDate.toDate().getTime(),
        ),
    [activities],
  );

  const upCommingLobbies = useMemo(
    () => activities?.filter((activity) => activity?.lobby.details?.eventDate.toDate() >= new Date())
                      .map((activity) =>  activity.lobby ),
    [activities],
  );

  const [activeIndex, setActiveIndex] = React.useState(0);
  const userExperience = useMemo(
    () => {
      if (currentUser && currentUser.experience) {
        return currentUser.experience;
      } else {
        let title: string;
        let progress = 0;
        const level = Math.ceil((currentUser?.joinedLobbies?.length ?? 1) / 2);
        if (currentUser?.joinedLobbies?.length == 0) {
          title = 'Curious Cat'
        } else if (level < 10) {
          progress = 10;
          title = 'Adventurer';
        } else if (level < 20) {
          title = 'Enthusiast';
        } else if (level < 30) {
          title = 'Cultural'
        } else {
          title = 'Maniac'
        }
        return {
          level: level,
          progress: progress,
          title: title
        }
      }
    }
    , [currentUser]
  );
  const userProgressWidth = useMemo(
    () => {
      const progress = currentUser?.experience?.progress ?? 0;
      return progress + '%';
    }, [currentUser]
  ) ;

  const renderActivityItem = useCallback<ListRenderItem<UserActivity>>(
    ({ item }) => {
      return (
        <ActivityCell
          activity={item}
          userName={currentUser?.displayName ?? currentUser?.email ?? ''}
        />
      );
    },
    [currentUser],
  );

  // useEffect(() => {
  //   if (!currentUser?.username) {
  //     BottomSheetService.show({
  //       content: ({ close }) => (
  //         <UpdateAccountBottomSheet close={close} currentUser={currentUser} />
  //       ),
  //       bottomSheetProps: {
  //         snapPoints: ['100%'],
  //         keyboardBehavior: 'extend',
  //       },
  //     });
  //   }
  // }, [currentUser]);

  const renderFollowerItem = useCallback<ListRenderItem<DocumentData>>(({ item }) => {
    return <ProfileCell user={item} />;
  }, []);

  return (
    <View className = "flex-1 bg-white" style = {{ paddingTop: top, paddingHorizontal: 16 }}>
      <View className="flex-row items-center justify-between" style = {{ paddingVertical: 8 }}>
        <Text className="text-xl font-bold">{currentUser?.displayName}</Text>
        <View className="flex-row items-center space-x-4">
          <PreferencesButton />
          <Pressable className="px-2 py-1 border border-gray-600 rounded-lg" onPress={signOut}>
            <Text className="text-xs">Logout</Text>
          </Pressable>
        </View>
      </View>

      <View className = "flex-1">
        <ScrollView 
          showsVerticalScrollIndicator = { false }
          className="flex-1 bg-white"
          refreshControl={
            <RefreshControl 
              refreshing = { refreshing }
              onRefresh={async () => {
                setRefreshing(true);
                refetchUserData(currentUser?.uid ?? '').then(() => setRefreshing(false));
              }
            }/>
          }
        >
          <View className="mb-4">
            <ProfileHeader
              userId={currentUser?.uid}
              upCommingLobbies={upCommingLobbies}
              credits={credits}
            />
          </View>

          <View style = {{ gap: 12, marginBottom: 20 }} >
            <View className='flex-row' style = {{justifyContent: 'space-between', alignItems: 'flex-end'}}>
              <View style = {{ flexDirection: 'row', gap: 8 }}>
                <Text style = {{ fontFamily: 'InterBold', fontSize: 16, color: '#121212' }}>{userExperience.title}</Text>
                <View style = {{ justifyContent: 'flex-end' }}>
                  <Text style = {{ fontFamily: 'InterSemiBold', fontSize: 14, color: '#565656' }}>(Level {userExperience.level})</Text>
                </View>
              </View>
              <Text style = {{ fontFamily: 'Inter', fontSize: 12, color: '#565656' }}>{userExperience.progress}/100 exp</Text>
            </View>
            <View>
              <View style = {{position: 'absolute', width: '100%', height: 8 , borderRadius: 2, backgroundColor: '#D9D9D9'}}/>
              <View style = {{position: 'absolute', width: userProgressWidth, height: 8 , borderRadius: 2, backgroundColor: '#128C7E'}}/>
            </View>
          </View>

        {currentUser?.wallet && (
          <View className="mb-4">
          <WalletCard userId={currentUser?.uid} />
        </View>
        )}

        <SegmentedControl segments={segments} activeIndex={activeIndex} onChange={setActiveIndex}/>
        {activeIndex === 0 && (
          <FlatList
            showsVerticalScrollIndicator={false}
            data={pastActivities}
            keyExtractor={(item) => item.lobby.id}
            renderItem={renderActivityItem}
            style = {{ marginTop: 20 }}
          />
        )}
        {activeIndex === 1 && (
          <FlatList
            showsVerticalScrollIndicator={false}
            data={followers}
            keyExtractor={(item) => item.id}
            renderItem={renderFollowerItem}
            style = {{ marginTop: 20 }}
          />
        )}
        {/* {activeIndex === 1 && warehouses.length === 0 && (
        <View>
          <View className="flex-row justify-center items-center pt-4 pb-2 px-4">
            <Image
              style={{ tintColor: '#6D7588' }}
              source={Shop}
              className="h-10 w-10 m-2 "
              resizeMode="cover"
            />
            <Text className="text-xs font-bold text-[#6D7588]">
              Belum ada gudang. Buka sekarang dan mulai jual barang bekas pertama kamu.
            </Text>
          </View>
          <TouchableOpacity
            className="border border-gray-900 p-3 rounded-md items-center"
            onPress={() => navigation?.navigate(RouteNavigation.OpenWareHouse)}>
            <Text className="text-gray-800 text-base font-bold">Buka Gudang</Text>
          </TouchableOpacity>
        </View>
      )} */}
        </ScrollView>
      </View>
    </View>
  );
};

export default AccountScreen;
