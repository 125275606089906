import { useQuery } from '@tanstack/react-query';

import useFetchUserData from '@/modules/account/hooks/useFetchUserData';
import { getDetailGroup } from '@/modules/shared/api/group';
import { K_GROUP_DETAIL_QUERY_KEY } from '@/modules/shared/constants/react-query';

const useQueryGroupDetail = (id: string) => {
  const { data: user } = useFetchUserData();

  return useQuery({
    queryKey: [K_GROUP_DETAIL_QUERY_KEY, id, user?.groupAdmin, user?.uid],
    queryFn: () => getDetailGroup(String(id)),
    enabled: Boolean(id),
    select: (data) => {
      // console.log('select', data, user);
      const isAdmin = String(user?.groupAdmin) === id;

      if (!isAdmin) return data;

      return {
        ...data,
        followers: data?.followers?.filter((f) => f !== user?.uid),
      };
    },
  });
};

export default useQueryGroupDetail;
