import { z } from 'zod';

export enum JoinerType {
  Internal = 'ALL',
  External = 'EXTERNAL_ONLY',
}

export const createLobbySchema = z.object({
  capacity: z.number().int().positive(),
  category: z.enum(['Pemula', 'Menengah', 'Expert', 'Semua']),
  description: z.string().min(10),
  durationInMinutes: z.number().int().positive(),
  eventDate: z.date(),
  genderGroup: z.enum(['Laki-Laki', 'Perempuan', 'Campur']),
  price: z.number().int().nonnegative(),
  title: z.string().min(1),
  venue: z.string().min(1),
  address: z.string(),
  googlePlaceId: z.string(),
  joinerType: z.nativeEnum(JoinerType),
  hasPhotographer: z.boolean(),
  isPrivate: z.boolean(),
});

export type CreateLobbySchema = z.infer<typeof createLobbySchema>;
