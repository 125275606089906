import translations from '../constants/locales/translations.json';
type Translations = typeof translations;
const getTranslation = (key: string, language: keyof Translations = 'id') => {
  const keys = key.split('.');
  let translation: any = translations[language];

  for (const k of keys) {
    if (translation[k] !== undefined) {
      translation = translation[k];
    } else {
      return key;
    }
  }

  return translation;
};

export default getTranslation;
