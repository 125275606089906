import { Timestamp } from 'firebase/firestore';

import { JoinerType } from '../lobby/schema/createLobbySchema';
import { UserResponseData } from '../../src/domain/UserResponse';

export enum TeamColours {
  BLUE_TEAM = '#7383DA',
  BLACK_TEAM = '#121212',
  YELLOW_TEAM = '#FFD644',
  GREEN_TEAM = '#68C36C',
  BROWN_TEAM = '#C39B89',
}
export type Notification = {
  title: string;
  body: string;
  createdAt: Timestamp | any;
  read: boolean;
  appLink: string;
  iconUrl: string;
};

export type Category = 'Pemula' | 'Expert' | 'Menengah' | 'Semua';
export enum LobbyStatus {
  'Open',
  'Closed',
}

export type Comment = {
  uid: string;
  userId: string;
  postId: string;
  content: string;
  createdAt: Timestamp;
  userDetail: UserResponseData;
};

export type Lobby = {
  id: string;
  players: Player[];
  venue: Venue;
  creator: Creator;
  details: Details;
  gallery?: string[];
  groupId?: string;
  isDeleted?: boolean;
  status?: LobbyStatus;
};
export type Creator = {
  userId?: string;
  name: string;
  photoUrl: string;
};
export type Player = {
  username?: string;
  uid: string;
  userId?: string;
  displayName: string;
  team?: TeamColours;
  email: string;
  photoURL: string;
  paid?: boolean;
  hasGivenOutCredits?: boolean;
  hasLoggedIn?: boolean;
  experience?: UserExperience;
};

export type Venue = {
  address: string;
  title: string;
  gMapsLink: string;
  id: string;
};
export type Details = {
  createdAt: Timestamp;
  lobbyPassword?: string;
  description: string;
  genderGroup: string;
  title: string;
  category: Category;
  durationInMinutes: number;
  price: number;
  earlyBirdPrice?: number;
  capacity: number;
  eventDate: Timestamp;
  joinerType: JoinerType;
  hasPhotographer?: boolean;
  isPrivate?: boolean;
};

export type UserExperience = {
  level: number;
  progress: number;
  title: string;
}

export type UserDetail = {
  followers?: string[];
  pushToken?: string;
  notifications?: Notification[];
  following?: string[];
  joinedLobbies?: string[];
  groups?: string[];
  photoURL: string;
  displayName: string;
  username?: string;
  email: string;
  wallet?: number;
  groupAdmin?: string;
  uid: string;
  experience?: UserExperience;
  posts?: string[]
};

export enum TeamTexts {
  BLUE_TEAM = 'Tim Biru',
  BLACK_TEAM = 'Tim Hhitam',
  YELLOW_TEAM = 'Tim Kuning',
  GREEN_TEAM = 'Tim Hijau',
  BROWN_TEAM = 'Tim Coklat',
}
export enum assetList {
  SAD_FACE = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691989210/sadface_apow0s.png',
  NEW_APP = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1699533112/Frame_7418_dtqcfp.png',
  PLACEHOLDER_USER = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1697512796/playard%20assets/Frame_6356577_lejrvx.png',
  PAYMENT_SUCCESS = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988880/playard%20assets/paymentSuccess_wgub3l.png',
  WARN = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988879/playard%20assets/warn_zyiesx.png',
  LANDING_ASSET = 'https://res.cloudinary.com/dau9nvleb/image/upload/v1722274640/mainLanding_oc1zla.png',
  ICON_HAM = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988881/playard%20assets/hamburger-icon_t4igyy.png',
  ICON_GENDER = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988880/playard%20assets/man_ospa1z.png',
  ICON_CATEGORY = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988880/playard%20assets/newbie_qnzpvk.png',
  ICON_MONEY = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988880/playard%20assets/money_cpc0kb.png',
  ICON_CANCELLATION = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988881/playard%20assets/cancellation_vbwguu.png',
  ICON_BACK = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988882/playard%20assets/back-icon_xncfgf.png',
  ICON_LOCK = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988880/playard%20assets/lock_hjwydg.png',
  ICON_SEARCH = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988879/playard%20assets/search-inactive_wdyfsx.png',
  ICON_USER = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988879/playard%20assets/user_fo8kdp.png',
  ICON_CLOCK = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988881/playard%20assets/clock_v1hcpc.png',
  ICON_LOCATION = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988880/playard%20assets/location_nq7da0.png',
  PLAYARD_HOST = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988883/playard%20assets/android-chrome-512x512_kz6hht.png',
  HOME_ACTIVE = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988881/playard%20assets/home-active_lfrhek.png',
  HOME_INACTIVE = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988881/playard%20assets/home-inactive_jkxzq9.png',
  SEARCH_ACTIVE = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988881/playard%20assets/ic_search_sua3e3.png',
  SEARCH_INACTIVE = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988879/playard%20assets/search-inactive_wdyfsx.png',
  PROFILE_INACTIVE = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988879/playard%20assets/profile-inactive_d3rpfh.png',
  PROFILE_ACTIVE = 'https://res.cloudinary.com/drxewzlaa/image/upload/v1691988879/playard%20assets/profile-active_fcf5hl.png',
}
export type FirestoreLobby = {
  id: string;
  data: Lobby;
};
export type CarouselItem = {
  id: string;
  data: {
    redirectUrl: string;
    assetUrl: string;
  };
};

export type PlayerWithCredit = Player & {
  credit: string;
};

export type Warehouse = {
  id?: string;
  name: string;
  city: string;
  phoneNumber: string;
  items: string[];
  userId: string;
};
