import React from 'react';
import { TouchableOpacity, Text } from 'react-native';

import Gradient from '../Gradient';

type TagProps = {
  onPress?: () => void;
  gradientColor?: string[];
  item: string;
};

const Tag = ({
  onPress,

  item,
}: TagProps) => (
  <Gradient className="m-1 px-3 py-1 rounded-xl">
    <TouchableOpacity onPress={onPress ?? (() => {})}>
      <Text className="text-xs font-semibold px-2">{item}</Text>
    </TouchableOpacity>
  </Gradient>
);

export default Tag;
