import React from 'react';
import { View } from 'react-native';

const Gradient: React.FC = ({ children, style }) => {
  return (
    <View style={{ borderRadius: 5, overflow: 'hidden' }}>
      <View style={[style, { backgroundColor: '#FFB039' }]}>{children}</View>
    </View>
  );
};

export default Gradient;
