import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { RefreshControl, SafeAreaView, ScrollView, View } from 'react-native';

import GroupItems from '../components/GroupItems';
import useQueryGroups from '../hooks/useQueryGroups';
import useQueryUserGroup from '../hooks/useQueryUserGroup';

import { useNavigation } from '@/modules/navigation/RootNavigation';
import Header from '@/modules/shared/components/Header';
import { Button } from '@/modules/shared/components/basic/Button';
import Text from '@/modules/shared/components/basic/Text';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { Ionicons } from '@/modules/shared/libs/icons';

const GroupScreen = () => {
  const navigation = useNavigation();
  const { data, isLoading, isFetching, refetch } = useQueryUserGroup();
  const { data: groups, isFetching: isFetchingGroup, refetch: refetchGroup } = useQueryGroups();

  const { currentUser } = useAuth();
  const userId = currentUser?.uid ?? '0';

  const refreshing = isFetching || isFetchingGroup;

  const renderButton = () => {
    if (isLoading) {
      return null;
    }

    if (!data) {
      return (
        <Button
          onPress={() => navigation?.navigate('CreateGroupScreen')}
          className="items-center justify-center w-full">
          <View className="flex-1" />
          <Text className="text-white text-center" fontFamily="InterBold">
            Buat Grup
          </Text>
          <View className="flex-1 flex-row justify-end">
            <Ionicons name="chevron-forward-outline" size={20} className="text-white" />
          </View>
        </Button>
      );
    }

    return (
      <Button
        onPress={() =>
          navigation?.navigate('GroupDetailScreen', {
            groupId: data.id,
            isOwner: data.userId === userId,
          })
        }
        className="items-center justify-center w-full">
        <View className="flex-1" />
        <Text className="text-white text-center" fontFamily="InterBold">
          {data.name}
        </Text>
        <View className="flex-1 flex-row justify-end">
          <Ionicons name="chevron-forward-outline" size={20} className="text-white" />
        </View>
      </Button>
    );
  };

  return (
    <SafeAreaView className="flex-1 bg-white">
      <StatusBar style="dark" translucent={false} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={async () => {
              await Promise.all([refetch(), refetchGroup()]);
            }}
          />
        }>
        <Header />
        <View className="px-4">
          <View className="flex-row items-center justify-center my-4">
            <Ionicons name="people-outline" size={24} />
            <Text fontFamily="InterBold" className="ml-2 text-2xl">
              Grup
            </Text>
          </View>
          {renderButton()}
          {/* commented for future use */}
          {/* <View className="border border-neutral-200 my-4 rounded-md bg-neutral-100 flex-row items-center">
            <Ionicons name="search-outline" size={20} className="p-2 text-neutral-400" />
            <TextInput
              className="text-base leading-5 p-2 flex-1"
              placeholderTextColor="#717171"
              placeholder="Cari"
            />
          </View> */}
          <GroupItems data={groups} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default GroupScreen;
