import { useBottomSheetModal } from '@gorhom/bottom-sheet';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { doc, getDoc } from 'firebase/firestore';
import React, { useCallback, useEffect } from 'react';

import { HomeScreen } from '../../screens/HomeScreen';
import { SearchScreen } from '../../screens/SearchScreen';

import PreferencesBottomSheet from '@/modules/account/components/PreferencesBottomSheet';
import AccountScreen from '@/modules/account/screens/AccountScreen';
import GroupScreen from '@/modules/group/screens/GroupScreen';
import BottomSheetService from '@/modules/shared/components/BottomSheet/BottomSheetService';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { db } from '@/modules/shared/libs/firebase/app';
import { FeatherIcons, Ionicons } from '@/modules/shared/libs/icons';
import { getTrackingPermission } from '@/modules/shared/utils/getTrackingPermission';

const Tab = createBottomTabNavigator();

const tabNavigationOptions = {
  tabBarActiveTintColor: '#5F48B9',
  tabBarInactiveTintColor: '#414141',
  headerShown: false,
  tabBarStyle: {
    height: 72,
    paddingTop: 6,
    shadowColor: '#ABABAB',
    shadowOffset: {
      width: 0,
      height: -4,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  tabBarIconStyle: {
    height: 24,
    width: 24,
    marginBottom: 0,
  },
  tabBarItemStyle: {
    height: 48,
  },
  tabBarLabelStyle: {
    fontSize: 12,
  },
};

const AuthedTabRoutes = () => {
  const auth = useAuth();
  const { dismissAll } = useBottomSheetModal();

  const showPref = useCallback(async () => {
    if (auth.currentUser === null) {
      return;
    }

    const hasSeenPref = await AsyncStorage.getItem('hasSeenPref');
    if (hasSeenPref === 'true') {
      return;
    }

    const userRef = doc(db, 'users', auth?.currentUser.uid);
    const snapshot = await getDoc(userRef);

    const data = snapshot.data() || {};

    if (!('preferences' in data)) {
      dismissAll();
      await AsyncStorage.setItem('hasSeenPref', 'true');
      BottomSheetService.show({
        content: ({ close }) => (
          <PreferencesBottomSheet close={close} userId={auth.currentUser?.uid} />
        ),
        bottomSheetProps: {
          snapPoints: ['90%'],
          keyboardBehavior: 'extend',
        },
      });
    }
  }, [auth]);

  useEffect(() => {
    showPref();
    getTrackingPermission();
  }, [showPref]);

  return (
    <Tab.Navigator screenOptions={tabNavigationOptions} initialRouteName="Home">
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          tabBarLabel: 'Home',
          tabBarIcon: ({ focused }) => (
            <FeatherIcons
              name="home"
              size={20}
              className={focused ? 'text-playard' : 'text-gray-700'}
            />
          ),
        }}
      />
      <Tab.Screen
        name="Group"
        component={GroupScreen}
        options={{
          tabBarLabel: 'Grup',
          tabBarIcon: ({ focused }) => (
            <Ionicons
              name="people-outline"
              size={20}
              className={focused ? 'text-playard' : 'text-gray-700'}
            />
          ),
        }}
      />
      <Tab.Screen
        name="Search"
        component={SearchScreen}
        options={{
          tabBarLabel: 'Search',
          tabBarIcon: ({ focused }) => (
            <Ionicons
              name="search-outline"
              size={20}
              className={focused ? 'text-playard' : 'text-gray-700'}
            />
          ),
        }}
      />
      <Tab.Screen
        name="Profile"
        component={AccountScreen}
        options={{
          tabBarLabel: 'Profile',
          tabBarIcon: ({ focused }) => (
            <Ionicons
              name="person-outline"
              size={20}
              className={focused ? 'text-playard' : 'text-gray-700'}
            />
          ),
        }}
      />
    </Tab.Navigator>
  );
};

export default AuthedTabRoutes;
