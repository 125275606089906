import { StackActions, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useState } from 'react';
import { View, Pressable, ScrollView } from 'react-native';
import Toast from 'react-native-root-toast';

import { addPlayerCredits, updateUserHasGivenCredits } from '../../../../firebase';
import { TaggedPlayerCellWithCredit } from '../../components/TaggedPlayerCell';
import { useCreditTags } from '../../hooks/useQueryCredits';

import { RootStackParamList } from '@/modules/navigation/types';
import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import Tag from '@/modules/shared/components/Tag/Tag';
import Text from '@/modules/shared/components/basic/Text';
import { Player, PlayerWithCredit } from '@/modules/shared/types';
import getTranslation from '@/modules/shared/utils/getTranslation';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

type Props = NativeStackScreenProps<RootStackParamList, 'GivenCreditScreen'>;
const GivenCreditScreen = ({ route }: Props) => {
  const { credits } = useCreditTags();
  const navigation = useNavigation();
  const { currentUser } = useAuth();

  const { participants, sourceLobbyId } = route.params;

  const [participantsWithCredit, setParticipantsWithCredit] = useState<PlayerWithCredit[]>(() =>
    participants.map((player: Player) => ({ ...player, credit: '' })),
  );

  const composeOnPress = (
    index: number,
    item: string,
    onPress: (index: number, item: string, onClose: () => void) => void,
    onClose: () => void,
  ) => {
    return () => onPress(index, item, onClose);
  };
  const onAddCreditToUser = (index: number, elem: string, onClose: () => void) => {
    if (
      participantsWithCredit.filter((items) => items.credit !== '').length < 3 ||
      participantsWithCredit[index].credit !== ''
    ) {
      const updatedParticipants = [...participantsWithCredit];
      updatedParticipants[index].credit = elem;
      setParticipantsWithCredit(updatedParticipants);
    }
    onClose();
  };

  const onOpenBottomSheet = useCallback(
    (index: number, playerCredits: string[]) => () => {
      DynamicBottomSheetService.show({
        content: ({ close }) => (
          <View className="pb-5 pl-2 pr-2">
            <View className="justify-center mb-6 border-b border-b-gray-200">
              <Text className="text-xl text-center pb-6" fontFamily="InterSemiBold">
                {btm_sheet_title}
              </Text>
            </View>

            <View className="flex-row flex-wrap ">
              {playerCredits.map((item: string, idx: number) => (
                <Tag
                  key={idx}
                  onPress={composeOnPress(index, item, onAddCreditToUser, close)}
                  item={item}
                />
              ))}
            </View>
          </View>
        ),
      });
    },
    [],
  );
  const btm_sheet_title = getTranslation('credit_bottom_sheet_title');
  const btnTitle = getTranslation('btn_send');
  const isParticipantIsEmpty =
    participantsWithCredit.filter((item, _) => item.credit !== '').length === 0;

  return (
    <View className="flex-1 px-4 bg-white">
      <View className="justify-center mb-5 border-b border-b-gray-200 pb-5 pt-10">
        <Text className="text-center text-sm">{getTranslation('credit_content')}</Text>
      </View>

      <ScrollView>
        {participantsWithCredit.map((value: PlayerWithCredit, index: number) => (
          <TaggedPlayerCellWithCredit
            user={value}
            key={index}
            onPress={onOpenBottomSheet(index, credits)}
          />
        ))}
      </ScrollView>
      <Pressable
        className={`rounded-md w-full items-center py-3 absolute bottom-10 self-center ${isParticipantIsEmpty ? 'bg-gray' : 'bg-playard'}`}
        onPress={async () => {
          for (let i = 0; i < participantsWithCredit.length; i++) {
            if (participantsWithCredit[i].credit !== '') {
              await addPlayerCredits(
                participantsWithCredit[i].userId ?? '',
                participantsWithCredit[i].credit,
              );
            }
          }
          // set user hasGivenCredits to true
          await updateUserHasGivenCredits(currentUser.uid, sourceLobbyId);
          navigation.dispatch(StackActions.pop(2));
        }}>
        <Text className="text-white font-semibold text-base">{btnTitle}</Text>
      </Pressable>
    </View>
  );
};

export default GivenCreditScreen;
