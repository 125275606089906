import React from 'react';
import { Image, View } from 'react-native';

import Text from './basic/Text';
import { useAuth } from '../context/auth/AuthContext';

const Header = () => {
  const { currentUser } = useAuth();

  return (
    <View className="bg-neutral-100 w-full flex-row items-center px-4">
      <View className="flex-1" />
      <Image
        source={require('@/modules/shared/assets/playard_icon.png')}
        className="h-16 w-16"
        resizeMode="contain"
      />
      <View className="flex-1 flex-row justify-end">
        {currentUser ? (
          <Image
            source={{ uri: String(currentUser.photoURL) }}
            className="h-12 w-12 rounded-full"
            resizeMode="cover"
          />
        ) : (
          <View className="h-12 w-12 rounded-full bg-neutral-300" />
        )}
      </View>
    </View>
  );
};

export default Header;
