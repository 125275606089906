import React from 'react';
import { Image, Pressable, Text, View } from 'react-native';
import { MixpanelManager } from '../../../src/MixpanelManager';

import { assetList } from '@/modules/shared/types';

type Props = {
  onSkip: () => void;
  onTakePhoto: () => void;
  userId?: string;
};

const LobbyLoginSuccessBottomSheet = ({ onSkip, onTakePhoto, userId }: Props) => {
  MixpanelManager.track('viewLobbySuccessLogin', userId,{ page: 'LobbyLoginSuccessBottomSheet' });
  return (
    <View style = {{paddingBottom: 48, paddingHorizontal: 16}}>
        <View className = 'items-center' style = {{ gap: 24}}>
            <View className='items-center' style = {{ gap: 20 }}>
                <View className = 'flex-row items-center' style = {{gap: 8}}>
                    <Image source = { require('../../../assets/check-circle-outline.png')} style = {{height: 40, width: 40}}/>
                    <Text style = {{flexShrink: 1, fontFamily: 'Inter', fontWeight: 'bold', fontSize: 18, color: '#121212'}}>Berhasil Mencatat</Text>
                </View>
                <Text style = {{ textAlign: 'center', color: '#433F51', fontFamily: 'Inter', fontSize: 16}}>
                Mantap! Kamu baru saja mendapatkan 10 exp. Tambah foto untuk dapat 20 exp tambahan?
                </Text>
            </View>
            <Pressable onPress={ onTakePhoto }>
                <Text style = {{ color: '#5F48B9', fontFamily: 'InterBold', fontSize: 16 }}>Foto sekarang</Text>
            </Pressable>
            <Pressable onPress={ onSkip }>
                <Text style = {{ color: '#A9A9A9', fontFamily: 'Inter', fontSize: 16 }}>Catat tanpa buat postingan</Text>
            </Pressable>
        </View>
    </View>
  );
};

export default LobbyLoginSuccessBottomSheet;
