import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';
import { View } from 'react-native';

import Text from './Text';

type Variant = VariantProps<typeof labelTextVariants>;

interface LabelProps extends Variant {
  text: string;
  fontFamily?: 'Inter' | 'InterMedium' | 'InterSemiBold' | 'InterBold' | undefined;
}

const labelVariants = cva('rounded-lg px-2 py-[2px]', {
  variants: {
    variant: {
      default: 'bg-playard',
      dark: 'bg-[#2A2A2A]',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const labelTextVariants = cva('font-normal', {
  variants: {
    variant: {
      default: 'text-white',
      dark: 'text-white',
    },
    size: {
      default: 'text-sm',
      sm: 'text-xs',
      lg: 'text-base',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

export default function Label({
  variant = 'default',
  size = 'default',
  fontFamily = 'Inter',
  ...props
}: LabelProps) {
  return (
    <View className={labelVariants({ variant })}>
      <Text fontFamily={fontFamily} className={labelTextVariants({ variant, size })}>
        {props.text}
      </Text>
    </View>
  );
}
