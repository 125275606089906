import { ActivityIndicator, Modal, View } from 'react-native';

const LoadingModal = ({ visible }: { visible: boolean }) => {
  return (
    <Modal visible={visible} transparent>
      <View className="flex-1 bg-slate-400/80 items-center justify-center">
        <ActivityIndicator size="large" color="#5F48B9" />
      </View>
    </Modal>
  );
};

export default LoadingModal;
