import { useNavigation } from '@react-navigation/native';
import { Image } from 'expo-image';
import parsePhoneNumber from 'libphonenumber-js';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ActivityIndicator, Linking, Pressable, Share, TouchableOpacity, View } from 'react-native';

// @ts-ignore
import PlusIcon from '../../../assets/ic-plus.png';
import GroupLobbies from '../components/GroupLobbies';
import useMutateFollowGroup from '../hooks/useMutateFollowGroup';
import useMutateUnfollowGroup from '../hooks/useMutateUnfollowGroup';
import useQueryGroupDetail from '../hooks/useQueryGroupDetail';
import useQueryGroupLobbies from '../hooks/useQueryGroupLobbies';

import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import { Button } from '@/modules/shared/components/basic/Button';
import Text from '@/modules/shared/components/basic/Text';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { FeatherIcons, FontAwesomeIcons, Ionicons } from '@/modules/shared/libs/icons';

interface GroupDetailHeaderProps {
  groupId: string;
  isEmptyPost: boolean;
  isOwner: boolean;
}

const GroupDetailHeader = (props: GroupDetailHeaderProps) => {
  const { data: group, isLoading } = useQueryGroupDetail(props.groupId);
  const { data: lobbies } = useQueryGroupLobbies(props.groupId, true);
  const { currentUser } = useAuth();

  const { mutate: followGroup, isPending: isLoadingFollow } = useMutateFollowGroup();
  const { mutate: unfollowGroup, isPending: isLoadingUnFollow } = useMutateUnfollowGroup();

  const submitting = isLoadingFollow || isLoadingUnFollow;

  const navigation = useNavigation();

  const onPressAddPosting = useCallback(() => {
    navigation?.navigate('CreateGroupPostScreen', {
      groupId: props.groupId,
    });
  }, []);

  const onShare = async () => {
    try {
      const url = 'https://app.playard.id/group/' + props.groupId;
      const result = await Share.share({
        message:
          `Coba deh, liat grup ${group?.name} di Playard supaya bisa ikut main basket bareng!` +
          '\n' +
          url,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      console.log(error, '<<');
    }
  };

  const shareButton = useMemo(() => {
    return (
      <TouchableOpacity onPress={onShare}>
        <Image source={require('../../../assets/share.png')} style={{ height: 24, width: 24 }} />
      </TouchableOpacity>
    );
  }, [group]);

  useEffect(() => {
    navigation.setOptions({ headerRight: () => shareButton });
  }, [group]);

  if (isLoading) {
    return (
      <View className="flex-1 items-center justify-center bg-white">
        <ActivityIndicator color="#5F48B9" />
      </View>
    );
  }

  if (!group) {
    return (
      <View className="flex-1 items-center justify-center bg-white">
        <Text className="text-2xl text-neutral-900">Group tidak ditemukan</Text>
      </View>
    );
  }

  const isFollowing = group?.followers?.includes(currentUser?.uid ?? '') ?? false;

  const handleFollowOrUnfollow = () => {
    if (isFollowing) {
      unfollowGroup({ groupId: group.id });
    } else {
      followGroup({ groupId: group.id });
    }
  };

  const handleShowSocial = () => {
    const cleanIGname = group.instagram.replace('@', '');
    const cleanWhatsapp = parsePhoneNumber(group.whatsapp, 'ID')?.formatInternational();

    DynamicBottomSheetService.show({
      content: ({ close }) => {
        return (
          <View className="px-4 space-y-6 pb-6">
            {cleanIGname !== '' && (
              <Pressable
                className="flex-row items-center"
                onPress={() => {
                  close();
                  Linking.openURL(`https://instagram.com/${cleanIGname}`);
                }}>
                <FeatherIcons name="instagram" size={20} />
                <Text className="text-base ml-4" fontFamily="InterSemiBold">
                  {cleanIGname}
                </Text>
              </Pressable>
            )}

            <Pressable
              className="flex-row items-center"
              onPress={() => {
                close();
                if (cleanWhatsapp) {
                  const removeSpace = cleanWhatsapp.replace(/\s/g, '');
                  Linking.openURL(`https://wa.me/${removeSpace}`);
                }
              }}>
              <FontAwesomeIcons name="whatsapp" size={20} />
              <Text className="text-base ml-4" fontFamily="InterSemiBold">
                {cleanWhatsapp}
              </Text>
            </Pressable>
          </View>
        );
      },
    });
  };

  return (
    <View className="bg-white">
      <Image source={{ uri: group.coverImg }} className="aspect-[3/1] w-full bg-neutral-200" />
      <View className="flex-row -mt-12 justify-center">
        <Image
          source={{ uri: group.profileImg }}
          className="w-24 h-24 rounded-full bg-neutral-200"
        />
      </View>

      <Text className="text-2xl text-center mt-3 text-neutral-800" fontFamily="InterBold">
        {group.name}
      </Text>
      <Text className="text-center text-xs text-neutral-500">
        {group.city}, est. {group.year}
      </Text>

      <View className="flex-row items-center justify-center mt-4 space-x-4">
        <View className="items-center">
          <Text fontFamily="InterBold">{group?.followers?.length ?? 0}</Text>
          <Text>Pengikut</Text>
        </View>
        <View className="items-center">
          <Text fontFamily="InterBold">{lobbies?.length ?? 0}</Text>
          <Text>Lobi</Text>
        </View>
      </View>

      <View className="flex-row items-center justify-center mt-4 space-x-4">
        {!props.isOwner && (
          <Button
            className="rounded-full w-32"
            disabled={submitting}
            onPress={handleFollowOrUnfollow}>
            <Text fontFamily="InterBold" className="text-white">
              {isFollowing ? 'Batal Ikuti' : 'Ikuti'}
            </Text>
          </Button>
        )}
        <Button
          className="rounded-full border-playard w-32"
          variant="outline"
          onPress={handleShowSocial}>
          <Text fontFamily="InterBold" className="text-playard">
            Kontak Kami
          </Text>
        </Button>
      </View>

      <Text className="text-center text-sm text-neutral-600 mt-4 px-5">{group.description}</Text>

      {props.isOwner && (
        <View className="px-5 mt-5">
          <Pressable
            className="border border-neutral-300 px-4 py-2 rounded-md flex-row justify-center items-center"
            onPress={() =>
              navigation?.navigate('EditGroupScreen', {
                groupId: group.id,
              })
            }>
            <View />
            <View className="flex-1 items-center">
              <Text className="text-base text-neutral-500" fontFamily="InterSemiBold">
                Atur Grup
              </Text>
            </View>
            <View>
              <Ionicons name="chevron-forward-outline" size={20} className="text-neutral-500" />
            </View>
          </Pressable>
        </View>
      )}

      <GroupLobbies id={group.id} />

      <View className="flex-row items-center justify-between px-4 pt-3 pb-5">
        <Text className="text-neutral-800 text-[20px]" fontFamily="InterBold">
          Postingan
        </Text>
        {!props.isEmptyPost && props.isOwner && (
          <Pressable onPress={onPressAddPosting}>
            <Image source={PlusIcon} className="w-[24px] h-[24px] rounded-full bg-neutral-200" />
          </Pressable>
        )}
      </View>
    </View>
  );
};

export default GroupDetailHeader;
