import { createContext, useContext } from 'react';

import { UserDetail } from '../../types';

type AuthContextType = {
  // TODO type, this is causing issues, sometime uses firebase type
  currentUser?: UserDetail | null;
  loading: boolean;
  initializing: boolean;
  isInAppBrowser?: boolean;
  setNotificationPermission?: (p: boolean) => void;
  setLoading: (loading: boolean) => void;
  signInGoogle: () => Promise<void>;
  signInWithApple: () => Promise<void>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  registerWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  // NOTE delete only for native
  deleteAccount?: () => Promise<void>;
  setCurrentUser: React.Dispatch<any>;
  loggedIn: boolean;
  refetchUserData: (userId: string) => Promise<void>;
};

export const AuthContext = createContext<AuthContextType>(undefined as never);

export const useAuth = () => useContext(AuthContext);