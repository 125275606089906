import { Image } from 'expo-image';
import React, { Component } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';

import { assetList } from '@/modules/shared/types';

const searchBarStyles = StyleSheet.create({
  textInput: {
    paddingVertical: 16,
    paddingLeft: 20,
    paddingRight: 52,
  },
  searchIcon: {
    width: 24,
    height: 24,
    position: 'absolute',
    zIndex: 1,
    right: 20,
    top: 14,
  },
});

type SearchBarProps = {
  style: any;
  onChangeText: (text: string) => void;
};

class SearchBar extends Component<SearchBarProps> {
  render() {
    return (
      <View
        style={{
          ...this.props.style,
          height: 52,
        }}>
        <TextInput
          style={searchBarStyles.textInput}
          placeholder="Cari teman disini"
          onChangeText={this.props.onChangeText}
          placeholderTextColor="#888"
        />

        <Image style={searchBarStyles.searchIcon} source={assetList.ICON_SEARCH} />
      </View>
    );
  }
}

export default SearchBar;
