import { createNavigationContainerRef } from '@react-navigation/native';

import { RootStackParamList, UnauthorizedStackParamList } from './types';

export const navigationRef = createNavigationContainerRef<
  RootStackParamList & UnauthorizedStackParamList
>();

export const useNavigation = () => {
  if (navigationRef.isReady()) {
    return navigationRef;
  }
  return undefined;
};
