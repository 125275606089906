import {
  type BottomSheetRef,
  type BottomSheetContentElement,
  type BottomSheetProps,
} from './BottomSheet';

let ref: BottomSheetRef | null = null;

const setBottomSheet = (bottomSheetRef: BottomSheetRef | null) => {
  ref = bottomSheetRef;
};

const getBottomSheet = (): Promise<BottomSheetRef> => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (ref) {
        resolve(ref);
        clearInterval(interval);
      }
    }, 50);
  });
};

const show = ({
  content,
  bottomSheetProps,
}: {
  content?: BottomSheetContentElement;
  bottomSheetProps?: BottomSheetProps;
}) => {
  getBottomSheet().then((sheet) => sheet.show({ content, bottomSheetProps }));
};

const BottomSheetService = {
  setBottomSheet,
  show,
};

export default BottomSheetService;
