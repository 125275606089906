import { Image } from 'expo-image';
import React from 'react';
import { Pressable, ScrollView, View } from 'react-native';

import useQueryFollowedGroups from '../hooks/useQueryFollowedGroups';

import { useNavigation } from '@/modules/navigation/RootNavigation';
import { Group } from '@/modules/shared/api/group';
import Text from '@/modules/shared/components/basic/Text';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { Ionicons } from '@/modules/shared/libs/icons';

const GroupItems = ({ data }: { data?: Group[] }) => {
  const { data: followedGroups } = useQueryFollowedGroups();

  const navigation = useNavigation();
  const { currentUser } = useAuth();
  const userId = currentUser?.uid ?? '0';

  const handlePress = (groupId: string, isOwner: boolean) => {
    navigation?.navigate('GroupDetailScreen', { groupId, isOwner });
  };

  return (
    <View>
      {(followedGroups?.length ?? 0) !== 0 && (
        <>
          <View className="flex-row items-center my-4">
            <Text fontFamily="InterBold" className="ml-2 text-2xl">
              Grup Diikuti
            </Text>
          </View>

          <ScrollView showsHorizontalScrollIndicator={false} horizontal>
            {followedGroups?.map((group) => (
              <Pressable
                key={group.id}
                className="mr-4 w-[70px]  items-center justify-center"
                onPress={() => handlePress(group.id, group.userId === userId)}>
                <View className="flex-1">
                  <Image
                    cachePolicy="memory-disk"
                    source={{ uri: group.profileImg }}
                    className="w-[70px] h-[70px] rounded-full bg-neutral-200 border border-playard"
                  />
                </View>
                <Text className="text-sm mt-2 text-center" numberOfLines={2}>
                  {group.name}
                </Text>
              </Pressable>
            ))}
          </ScrollView>
        </>
      )}

      <View className="flex-row items-center my-4">
        <Ionicons name="people-outline" size={24} />
        <Text fontFamily="InterBold" className="ml-2 text-2xl">
          Grup Lainnya
        </Text>
      </View>
      {data?.map((group) => (
        <Pressable
          key={group.id}
          className="border border-neutral-200 rounded-lg p-3 mb-4"
          onPress={() => handlePress(group.id, group.userId === userId)}>
          <View className="flex-row items-center space-x-3">
            <Image
              cachePolicy="memory-disk"
              source={{ uri: group.profileImg }}
              className="w-10 h-10 rounded-full bg-neutral-200"
            />
            <Text className="text-base flex-1" fontFamily="InterBold">
              {group.name}
            </Text>
            <Ionicons name="chevron-forward-outline" size={20} className="text-neutral-900" />
          </View>
          <Text className="text-neutral-900 my-1" fontFamily="InterSemiBold">
            {group.description}
          </Text>
          <Text className="text-neutral-600 text-sm">{group.city}</Text>
          <Image
            cachePolicy="memory-disk"
            source={{ uri: group.coverImg }}
            className="object-cover h-32 w-full rounded-lg bg-neutral-100 mt-4"
          />
        </Pressable>
      ))}
    </View>
  );
};

export default GroupItems;
