import axios from 'axios';
import { Platform } from 'react-native';

interface CloudinaryIdentity {
  cloud_name: string;
  api_key: string;
}

interface CloudinaryID {
  post: CloudinaryIdentity;
  default: CloudinaryIdentity;
}

export const CLOUDINARY_ID: CloudinaryID = {
  post: {
    cloud_name: 'dbowfitpz',
    api_key: '678918578891996',
  },
  default: {
    cloud_name: 'drxewzlaa',
    api_key: '965129456138243',
  },
};

export const uploadImageFromBase64 = async (uri: string, base64: string) => {
  const identity = CLOUDINARY_ID.default;

  const uriArr = uri.split('.');

  const fileType = uriArr[uriArr.length - 1];

  const file = `data:${fileType};base64,${base64}`;

  console.log(file);

  const url = `https://api.cloudinary.com/v1_1/${identity.cloud_name}/image/upload`;
  console.log('url', url);

  const formData = new FormData();
  formData.append('upload_preset', 'playard');
  formData.append('api_key', identity.api_key);

  // web can just use raw uri
  formData.append('file', Platform.OS === 'web' ? uri : file);
  try {
    const response = await axios.request({
      method: 'POST',
      url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('uploaded image url', response);
    return response.data.secure_url as string;
  } catch (e) {
    console.log('upload errror', e);
    console.log(e);
  }
};
