// NOTE to remove/rename eventually. make it dumb

import {
  DocumentData,
  QueryConstraint,
  QuerySnapshot,
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { LobbyResponse } from 'src/domain/LobbyResponse';
import { UserResponse, UserResponseData } from 'src/domain/UserResponse';

import {
  saveNotificationToUsers,
  sendPushNotification,
} from './modules/shared/api/pushNotifications';
import { db } from './modules/shared/libs/firebase/app';
import { PostData } from './src/domain/PostResponse';

import {
  CarouselItem,
  Lobby,
  Notification,
  Player,
  UserDetail,
  Warehouse,
} from '@/modules/shared/types';

export const createOrUpdateFirebaseUser = async (authUser: UserDetail) => {
  if (!authUser) return;
  console.log('creating or updating user', authUser.uid);
  const userRef = doc(db, 'users', authUser.uid);
  const snapshot = await getDoc(userRef);

  // this is from firebase
  const { email, displayName, photoURL, uid, experience } = authUser;
  if (!snapshot.exists()) {
    console.log('creating user', authUser.uid);
    const createdAt = serverTimestamp();
    try {
      await setDoc(userRef, {
        email,
        uid,
        displayName,
        photoURL,
        createdAt,
        followers: [],
        following: [],
        joinedLobbies: [],
        notifications: [],
        experience: experience ?? { progress: 0, level: 1, title: 'Curious Cat' }
      });
    } catch (e: any) {
      console.log('error creating user', e.message);
    }
    const newUserSnapshot = await getDoc(userRef);
    console.log('new user snapshot data', newUserSnapshot.data());
    return newUserSnapshot.data();
  } else {
    console.log('updating user', authUser.uid);
    const updatedAt = serverTimestamp();
    // NOTE dont update photoURL, displayName, email if exists
    try {
      let userExperience = snapshot.data().experience;
      if (userExperience == null) {
        let title: string;
        let progress = 0;
        const level = Math.ceil((snapshot.data().joinedLobbies?.length ?? 1) / 2);
        if (snapshot.data().joinedLobbies?.length == 0) {
          title = 'Curious Cat'
        } else if (level < 10) {
          progress = 10;
          title = 'Adventurer';
        } else if (level < 20) {
          title = 'Enthusiast';
        } else if (level < 30) {
          title = 'Cultural'
        } else {
          title = 'Maniac'
        }
        userExperience = { 
          progress: progress, 
          level: level,
          title: title
        }
      }
      await updateDoc(userRef, {
        updatedAt,
        followers: (snapshot.data() && Array.from(new Set(snapshot.data().followers))) || [],
        following: (snapshot.data() && Array.from(new Set(snapshot.data().following))) || [],
        joinedLobbies:
          (snapshot.data() && Array.from(new Set(snapshot.data().joinedLobbies))) || [],
          experience: userExperience!
      });
    } catch (e: any) {
      console.log('error updating user', e.message);
    }
    console.log('update snapshot user data', snapshot.data());
    return snapshot.data();
  }
};
export const getLobbyById = async (id: string) => {
  const lobbyRef = doc(db, 'lobbies', id);
  const lobbyDoc = await getDoc(lobbyRef);

  if (lobbyDoc.data()) {
    return {
      ...lobbyDoc.data(),
      lobbyId: id,
    } as LobbyResponse;
  }

  return null;
};

export const getAllLobbies = async () => {
  const lobbies: any = [];
  const lobbiesRef = await getDocs(collection(db, 'lobbies'));
  lobbiesRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    lobbies.push({ data: doc.data(), id: doc.id });
  });
  return lobbies;
};

export const leaveLobbyFirebase = async (
  lobbyId: string,
  userId: string,
): Promise<Player[] | undefined> => {
  const lobbyRef = doc(db, 'lobbies', lobbyId);
  const lobbyDoc = await getDoc(lobbyRef);

  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  console.log('removing player', userDoc.data());
  console.log('manipulating lobby', lobbyDoc.data());

  if (!lobbyDoc.exists()) {
    console.log('no such lobby');
    return undefined;
  }

  const { players } = lobbyDoc.data() as Lobby;
  // TODO types
  const { joinedLobbies } = userDoc.data() as any;
  if (!joinedLobbies) {
    console.log(joinedLobbies);
    console.log('user does not have joined lobbies');
    return undefined;
  }

  const alreadyJoined = players.find((p: Player) => p.userId === userId);
  const joinedLobbyIndex = joinedLobbies.find((userLobbyId: string) => userLobbyId === lobbyId);

  if (!alreadyJoined || !joinedLobbyIndex) {
    console.log('not yet joined');
    return undefined;
  }
  players.splice(players.indexOf(alreadyJoined), 1);
  joinedLobbies.splice(joinedLobbies.indexOf(joinedLobbyIndex), 1);
  try {
    await updateDoc(lobbyRef, {
      players,
    });
    await updateDoc(userRef, {
      joinedLobbies,
    });
  } catch (e: any) {
    console.log('error leaving lobby', e.message);
  }

  console.log('player removed succesfully', players);
  return players;
};
export const joinLobbyFirebase = async (
  lobbyId: string,
  userId: string,
): Promise<Player[] | undefined> => {
  const lobbyRef = doc(db, 'lobbies', lobbyId);
  const lobbyDoc = await getDoc(lobbyRef);

  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);

  const { email, photoURL, displayName }: any = userDoc.data();
  console.log('adding player', userDoc.data());
  console.log('manipulating lobby', lobbyDoc.data());

  if (!lobbyDoc.exists()) {
    console.log('no such lobby');
    return undefined;
  }
  const { players } = lobbyDoc.data() as Lobby;
  const alreadyJoined = players.find((p: Player) => p.userId === userId);

  if (alreadyJoined) {
    console.log('already joined');
    return undefined;
  }

  // TODO reenable paid to false before pushing
  const playerInsertion: Player = {
    paid: true,
    userId,
    uid: userId,
    email,
    photoURL,
    displayName,
    hasGivenOutCredits: false,
    hasLoggedIn: false
  };
  players.push(playerInsertion);
  const joinedLobbies = [...userDoc?.data()?.joinedLobbies, lobbyId];

  console.log('new user joinedLobbies', joinedLobbies);
  console.log('new lobby players', players);
  try {
    await updateDoc(lobbyRef, {
      players,
    });
    await updateDoc(userRef, {
      joinedLobbies,
    });

    //update user with joined lobby id
  } catch (e: any) {
    console.log('error joining lobby', e.message);
  }
  console.log('player added succesfully', players);
  // update document

  return players;
};
export const joinedStatusLobbyFirebase = async (lobbyId: string, userId: string) => {
  const lobbyRef = doc(db, 'lobbies', lobbyId);
  const lobbyDoc = await getDoc(lobbyRef);

  if (!lobbyDoc.exists()) {
    console.log('no such lobby');
    return undefined;
  }
  const { players } = lobbyDoc.data() as Lobby;
  const alreadyJoined = players.find((p: Player) => p.userId === userId);
  console.log(alreadyJoined);
  return alreadyJoined;
};

export interface GetAllUserParameter {
  limitBy?: number;
  searchByName?: string;
}

export const getAllUsers = async (parameter?: GetAllUserParameter) => {
  const users: any = [];
  const userRef = collection(db, 'users');

  let userData: QuerySnapshot<DocumentData, DocumentData>;
  if (parameter) {
    const constraints: QueryConstraint[] = [];

    if (parameter.limitBy) {
      constraints.push(limit(parameter.limitBy));
    }

    if (parameter.searchByName) {
      constraints.push(where('displayName', '>=', parameter.searchByName));
      constraints.push(where('displayName', '<=', parameter.searchByName.toLowerCase() + '\uf8ff'));
    }

    const q = query(userRef, ...constraints);
    userData = await getDocs(q);
  } else {
    userData = await getDocs(collection(db, 'users'));
  }

  userData.forEach((doc) => {
    users.push({ data: doc.data(), id: doc.id });
  });

  return users as UserResponse[];
};

export const getUserById = async (userId: string) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  return userDoc.data() as UserResponseData;
};

export const logInToLobby = async (lobbyId: string, userId: string) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  const lobbyRef = doc(db, 'lobbies', lobbyId);
  const lobbyDoc = await getDoc(lobbyRef);

  if (!lobbyDoc.exists()) {
    console.log('no such lobby');
    return undefined;
  }

  let { players } = lobbyDoc.data() as Lobby;
  players = players.map((p: Player) => {
    if (p.userId === userId) {
      let updatedPlayer = p;
      updatedPlayer.hasLoggedIn = true;
      return updatedPlayer;
    } else {
      return p;
    }
  });

  let { experience } = userDoc.data() as UserDetail;
  let newProgress = (experience?.progress ?? 0) + 10;
  let level = (experience?.level ?? 1);
  
  let title: string;
  if (level < 10) {
    title = 'Adventurer';
  } else if (level < 20) {
    title = 'Enthusiast';
  } else if (level < 30) {
    title = 'Cultural'
  } else {
    title = 'Maniac'
  }
  if (newProgress >= 100) {
    newProgress = newProgress % 100;
    level += 1;
  }
  experience = {
    progress: newProgress,
    level: level,
    title: title
  }

  let userDetail = userDoc.data() as UserDetail;
  userDetail.experience = experience;

  await updateDoc(lobbyRef, { players });
  await updateDoc(userRef, { experience });
};

export const followUser = async (userFollowed: UserDetail, follower: UserDetail) => {
  console.log('🤗 following', userFollowed, follower);
  const userRef = doc(db, 'users', userFollowed.uid);
  const followerRef = doc(db, 'users', follower.uid);

  const followers = [...(userFollowed.followers as []), follower.uid];
  const following = [...(follower.following as []), userFollowed.uid];
  await updateDoc(userRef, {
    followers,
  });
  await updateDoc(followerRef, {
    following,
  });

  // TODO type
  const createdAt = serverTimestamp();
  const notification: Notification = {
    title: `${follower.displayName || follower.email}`,
    body: `Mulai mengikuti kamu`,
    createdAt,
    read: false,
    iconUrl: '',
    appLink: `playard://profile/${follower.uid}`,
  };
  saveNotificationToUsers([userFollowed.uid], notification);
  if (userFollowed.pushToken) {
    sendPushNotification([userFollowed.pushToken], notification);
  }
};

export const unfollowUser = async (currentUser: any, follower: any) => {
  console.log('😒 unfollowing', currentUser, follower);
  const userRef = doc(db, 'users', currentUser.uid);
  const followerRef = doc(db, 'users', follower.uid);
  const followers = currentUser.followers.filter((id: string) => id !== follower.uid);
  const following = follower.following.filter((id: string) => id !== currentUser.uid);
  await updateDoc(userRef, {
    followers,
  });
  await updateDoc(followerRef, {
    following,
  });
};

export const getCarouselData = async () => {
  const carouselItems: CarouselItem[] = [];
  const carouselItemsRef = await getDocs(collection(db, 'carouselData'));
  carouselItemsRef.forEach((doc) => {
    carouselItems.push({
      data: doc.data() as CarouselItem['data'],
      id: doc.id,
    });
  });
  console.log('pull carousel data', carouselItems);

  return carouselItems;
};

export const addNotifToken = async (token: string, userId?: string) => {
  const tokenRef = doc(db, 'pushTokens', 'tokens');

  const tokenDoc = await getDoc(tokenRef);

  if (userId) {
    const userRef = doc(db, 'users', userId);
    const docResult = await getDoc(userRef);

    if (docResult.exists()) {
      await updateDoc(userRef, {
        pushToken: token,
      });
    }
  }

  if (!tokenDoc.exists()) {
    await setDoc(tokenRef, {
      notifTokens: Array.from(new Set([token])),
    });
    return;
  }

  const { notifTokens } = tokenDoc.data() as any;
  const newNotifTokens = Array.from(new Set([...notifTokens, token]));
  await updateDoc(tokenRef, {
    notifTokens: newNotifTokens,
  });
};

export const addLobbyRating = async (
  lobbyId: string,
  userId: string,
  rate: number,
  comment: string,
) => {
  const ratingData = {
    userId,
    createdAt: new Date().getTime(),
    rate,
    comment,
  };

  try {
    const ratingRef = doc(db, `reviews/${lobbyId}/rates/${userId}`);

    await setDoc(ratingRef, ratingData);
    return true;
  } catch (e) {
    console.log('Error adding rating: ', e);
    return false;
  }
};

export const calculateAverageRating = async (lobbyId: string) => {
  const ratingsRef = collection(db, `rating/${lobbyId}/rates`);
  const q = query(ratingsRef);

  try {
    const querySnapshot = await getDocs(q);
    let totalRate = 0;
    let count = 0;

    querySnapshot.forEach((doc) => {
      totalRate += doc.data().rate;
      count += 1;
    });

    const averageRate = totalRate / count;
    return averageRate;
  } catch (e) {
    console.log('Error when calculate the rating: ', e);
  }
};

export const getAllCreditTagDocuments = async (): Promise<{ credits: string[] }> => {
  const colRef = collection(db, 'credit-tags');
  const creditsSet: Set<string> = new Set();

  try {
    const querySnapshot = await getDocs(colRef);

    querySnapshot.forEach((doc) => {
      const docData = doc.data() as DocumentData;

      if (docData.credits && Array.isArray(docData.credits)) {
        docData.credits.forEach((credit: string) => {
          creditsSet.add(credit);
        });
      }
    });

    const creditsArray = Array.from(creditsSet);

    return { credits: creditsArray };
  } catch (e) {
    console.log('Error Fetch documents:', e);
    return { credits: [] };
  }
};

export const addPlayerCredits = async (userId: string, tag: string) => {
  const creditsRef = collection(db, `users-credits/${userId}/credits`);

  try {
    const docRef = await addDoc(creditsRef, {
      tag,
      createdAt: Timestamp.now(),
    });
    return docRef.id;
  } catch (e) {
    console.log('Error adding item: ', e);
    return null;
  }
};
export const getCreditByUserId = async (userId: string): Promise<{ credits: string[] }> => {
  const creditsRef = collection(db, `users-credits/${userId}/credits`);
  const credits: string[] = [];

  try {
    const querySnapshot = await getDocs(creditsRef);

    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      if (docData.tag) {
        credits.push(docData.tag);
      }
    });

    return { credits };
  } catch (e) {
    console.log('Error getting user credits:', e);
    return { credits: [] };
  }
};

export const queryLobbyByUserId = async (userId: string) => {
  try {
    const lobbyQuerySnapshot = await collection(db, 'lobbies');

    const queryDb = query(lobbyQuerySnapshot, where('userId', '==', userId));
    const querySnapshot = await getDocs(queryDb);
    const lobbies: any = [];
    querySnapshot.forEach((doc) => {
      lobbies.push({ id: doc.id, ...doc.data() });
    });

    return lobbies;
  } catch (error) {
    console.log('Error querying lobbies: ', error);
  }
};

export async function addLobbyDocument(lobbyId: string, userId: string, data: any) {
  if (!lobbyId) {
    return null;
  }
  const lobbyDocRef = doc(collection(db, 'lobby-closed'), lobbyId);

  try {
    await updateLobbyStatus(lobbyId);
    await setDoc(lobbyDocRef, {
      lobbyId,
      userId,
      createdAt: serverTimestamp(),
    });
  } catch (error) {
    console.log('Error adding document: ', error);
  }
}

const updateLobbyStatus = async (id: string) => {
  try {
    const docRef = doc(db, 'lobbies', id);

    await updateDoc(docRef, {
      status: 'Done',
    });
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

export const getPostsFromFollowing = async(userId: string) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userData = (await getDoc(userRef)).data();

    const following = await Promise.all((userData?.following as string[]).map (async (id) => {
      const userRef = doc(db, 'users', id);
      const userData = (await getDoc(userRef)).data();
      return { 
        name: userData?.displayName ?? 'User',
        uid: id,
        posts: userData?.posts as string[] ?? []
      };
    }));

    let posts: any[] = [];

    await Promise.all(following.map(async (user) => {
      await Promise.all(user.posts.map(async (userPost) => {
        const postRef = doc(db, 'posts', userPost);
        const postData = await getDoc(postRef);
        posts.push({
          ...postData.data(),
          id: postData.id,
          userDisplayName: user.name
        })
      }))
    }));

    posts.sort((a, b) => 
      b.createdAt.toDate().valueOf() - a.createdAt.toDate().valueOf()
    );

    const finalPosts = Promise.all(posts.map(async (post) => {
      if ((post.lobbyId == null) || post.lobbyId == '') {
        return post;
      }
      const lobbyDetail = await getLobbyById(post.lobbyId);

      return {
        ...post,
        lobbyDetails: {
          creatorPhotoUrl: lobbyDetail?.creator.photoUrl,
          creatorName: lobbyDetail?.creator.name,
          groupId: lobbyDetail?.groupId,
          isCreator: lobbyDetail?.creator.userId == userId,
          location: lobbyDetail?.venue.title
        }
      }
    }));

    return finalPosts
  } catch (error) {
    console.log('Error getting following posts: ', error);
  }
};

export const likePost = async (postId: string, userId: string, unlike: boolean) => {
  try {
    const postRef = doc(db, 'posts', postId);
    const postData = await getDoc(postRef);

    let likes = postData.data()?.likes as string[] ?? [];

    if (unlike) {
      likes =  likes.filter((likeUserId) => likeUserId != userId);
    } else {
      const likesSet = new Set([...likes, userId]);
      likes = Array.from(likesSet);
    }
    await updateDoc(postRef, { likes });
  } catch (error) {
    console.log('error liking post', error);
  }
}
export const updateUserHasGivenCredits = async (userId: string, lobbyId: string) => {
  try {
    const lobbyRef = doc(db, 'lobbies', lobbyId);
    const lobbyDoc = await getDoc(lobbyRef);

    if (lobbyDoc.data()) {
      const lobbyPlayers = {
        ...lobbyDoc.data(),
        lobbyId,
      } as LobbyResponse;
      const players = lobbyPlayers.players.map((player) => {
        if (player.userId == userId) {
          player.hasGivenOutCredits = true;
        }
        return player;
      });
      await updateDoc(lobbyRef, {
        players,
      });
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error updating user has given credits: ', error);
  }
};

export const addWarehouseForUser = async (houseData: Warehouse): Promise<string | null> => {
  try {
    const housesRef = collection(db, 'warehouses');
    const houseDocRef = await addDoc(housesRef, houseData);
    return houseDocRef.id;
  } catch (error) {
    console.error('Error adding house:', error);
    return null;
  }
};

export const addItemForWarehouse = async (
  itemData: any,
  warehouseId: string,
): Promise<string | null> => {
  try {
    const itemsRef = collection(db, 'items');
    const itemDocRef = await addDoc(itemsRef, itemData);

    const warehouseRef = doc(db, 'warehouses', warehouseId);
    await updateDoc(warehouseRef, {
      items: [itemDocRef.id],
    });

    return itemDocRef.id;
  } catch (error) {
    console.log('Error while adding item:', error);
    return null;
  }
};

export const getWarehouseByUserId = async (userId: string) => {
  try {
    const housesRef = collection(db, 'warehouses');
    const q = query(housesRef, where('userId', '==', userId));

    const querySnapshot = await getDocs(q);
    const houses: any = [];
    querySnapshot.forEach((doc) => {
      houses.push({ id: doc.id, ...doc.data() });
    });
    return houses;
  } catch (error) {
    console.log('Error when fetch data:', error);
    return [];
  }
};
