import { Image } from 'expo-image';
import React, { useEffect, useState, useCallback } from 'react';
import { Dimensions, Linking, ScrollView, StyleSheet, Text, View, TouchableOpacity} from 'react-native';
import { FlatList, 
  RefreshControl, 
  TapGestureHandler,} from 'react-native-gesture-handler';
import Carousel from 'react-native-reanimated-carousel';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { LobbyCard } from '../components/LobbyCard';
import { CreateLobbyButton } from '../modules/lobby/components/CreateLobbyButton';
import { useLobby } from '../providers/LobbyProvider';

import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { assetList, FirestoreLobby } from '@/modules/shared/types';
import { getPostsFromFollowing, likePost } from '../firebase';
import { PostData } from 'src/domain/PostResponse';
import { PostItem } from '../components/HomePostItem';
import { useNavigation } from '@/modules/navigation/RootNavigation';
import { MixpanelManager } from '../src/MixpanelManager';

export const HomeScreen = () => {
  const { lobbyList, carouselData, refreshData } = useLobby();
  const [refreshing, setRefreshing] = useState(false);
  const navigation = useNavigation();

  const [postsList, setPostsList] = useState<PostData[]>([]);
  const [displayedLobbies, setDisplayedLobbies] = useState<FirestoreLobby[]>([]);

  const { currentUser } = useAuth();

  useEffect(() => {
    const getPosts = async () => {
      if (currentUser == null) {
        return;
      }
      const posts = await getPostsFromFollowing(currentUser.uid);
      setPostsList(posts as PostData[]);
      console.log('posts are ', posts as PostData[]);
    };

    const getDisplayedLobbies = () => {
      if ((lobbyList as FirestoreLobby[]) == null) {
        return;
      }
      const filtered = (lobbyList as FirestoreLobby[]).filter(
        // TODO use lobby duration value
        (a: FirestoreLobby) =>
          new Date(a.data?.details.eventDate.toDate()).valueOf() >
            new Date().setHours(new Date().getHours() - 2).valueOf() && !a.data.isDeleted,
      )
      .sort(
        (a: FirestoreLobby, b: FirestoreLobby) =>
          a.data.details.eventDate.toDate().valueOf() -
          b.data.details.eventDate.toDate().valueOf(),
      )
      setDisplayedLobbies(filtered);
    }
    getPosts();
    getDisplayedLobbies();
  }, [currentUser, lobbyList]);

  const handleLikePost = ((postId: string, distinct: boolean) => {
      const updatedPosts = postsList.map( (post) => {
        if (post.id == postId) {
          if (post.likes.some((userId) => userId == currentUser?.uid ?? '' )) {
            // unlike
            if (distinct) {
              return post;
            }
            post.likes =  post.likes.filter((likeUserId) => likeUserId != currentUser?.uid ?? '');
            likePost(postId, currentUser?.uid ?? '', true);
            return post
          } else {
            // like
            post.likes = [...post.likes, currentUser?.uid ?? ''];
            likePost(postId, currentUser?.uid ?? '', false);
            return post
          }
        }
        return post;
      });
      setPostsList(updatedPosts);
  });

  const insets = useSafeAreaInsets();
  const PAGE_WIDTH = Dimensions.get('window').width;
  const baseOptions = {
    vertical: false,
    width: PAGE_WIDTH,
    height: 130,
  } as const;
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        alignContent: 'flex-start',
        backgroundColor: 'white',
        paddingTop: insets.top,
      }}>
      <View style={{ flexDirection: 'row', paddingVertical: 20, paddingHorizontal: 16, alignItems: 'center' }}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View style={{ paddingRight: 12, paddingTop: 1, paddingLeft: 0 }}>
            <Image
              style={styles.hostIcon}
              source={currentUser?.photoURL || assetList.PLACEHOLDER_USER}
            />
          </View>
          <View style={{ flex: 1 }}>
            <Text
              style={{
                ...styles.lightText,
                color: '#2A2A2A',
              }}>
              Hello,
            </Text>
            <Text style={{ ...styles.capacityText, fontSize: 18 }}>
              {currentUser?.displayName || currentUser?.email}
            </Text>
          </View>
        </View>

        <CreateLobbyButton />
      </View>
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={async () => {
              setRefreshing(true);
              refreshData().then(() => setRefreshing(false));
            }}
          />
        } showsVerticalScrollIndicator = { false }>
        <View style={{ alignSelf: 'center', paddingHorizontal: 16 }}>
          <Carousel
            {...baseOptions}
            loop={false}
            autoPlay={false}
            data={carouselData}
            mode="parallax"
            modeConfig={{
              parallaxScrollingScale: 0.9,
            }}
            renderItem={({ index }) => (
              <TapGestureHandler
                key={index}
                onActivated={() => {
                  if (!carouselData[index].data.redirectUrl) return;
                  Linking.openURL(carouselData[index].data.redirectUrl);
                }}>
                <Image source={carouselData[index].data.assetUrl} style={styles.banner} />
              </TapGestureHandler>
            )}
          />
        </View>
        <View style = {{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 16, paddingHorizontal: 16 }}> 
          <Text
            style={{
              fontWeight: 'bold',
              fontFamily: 'InterBold',
              fontSize: 20
            }}>
            Lobi aktif
          </Text>
          { displayedLobbies.length > 3 && (
            <TouchableOpacity style = {{ justifyContent: 'flex-end', flexShrink: 1}}
            onPress = { ()=> {
              navigation?.navigate('LobbyListScreen', { lobbies: lobbyList });
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                fontFamily: 'InterBold',
                fontSize: 12
              }}>
              Lihat Semua
            </Text>
          </TouchableOpacity>
          )}
        </View>
        {displayedLobbies
          .slice(0, 3)
          .map((firestoreLobby: FirestoreLobby, index: number) => (
            <View
              key={firestoreLobby.id}
              className="bg-white relative rounded-lg border-[1px] mb-2 border-[#D9D9D9]"
              style = {{ marginHorizontal: 16}}
              >
              {firestoreLobby.data.details.isPrivate && (
                <View className="absolute right-0 rounded-l-full mt-2 h-8 w-12 bg-[#25165E] items-center justify-center">
                  <Image source={require('../assets/icon-lock.png')} className="h-5 w-5" />
                </View>
              )}
              <LobbyCard firestoreLobby={firestoreLobby} currentUserId={currentUser?.uid ?? ''} />
            </View>
          ))}

          <View style = {{ height: 2, width: '100%', backgroundColor: '#F0F3f7', marginTop: 24}}/>
          <Text style = {{ textAlign: 'center', fontFamily: 'InterBold', color: '#565656', marginVertical: 32, 
          paddingHorizontal: 16 }}>
            Dari teman yang kamu ikuti
          </Text>

          { (postsList?.length != 0) &&  (
            <FlatList
              data={postsList}
              keyExtractor={ (post) => post.id }
              renderItem={ (item) => {
                return PostItem({ 
                  postData: item.item, 
                  userId: currentUser?.uid ?? '',
                  onLike: (fromDoubleTap) => {
                    MixpanelManager.track(fromDoubleTap ? 'doubleTapLikeOnHomePost' : 'clickHeartLikeOnHomePost', 
                    currentUser?.uid, 
                    { page: 'HomeScreen' });
                    handleLikePost(item.item.id, fromDoubleTap);
                  },
                  onComment: () => {
                    MixpanelManager.track('clickCommentIconOnHomePost', currentUser?.uid, { page: 'HomeScreen' });
                    navigation?.navigate('GroupPostDetailScreen', { postId: item.item.id });
                  },
                  onClickGroup: () => {
                    MixpanelManager.track('clickGroupDetailOnHomePost', currentUser?.uid, { page: 'HomeScreen' });
                    navigation?.navigate('GroupDetailScreen', { groupId: item.item.lobbyDetails.groupId, isOwner: item.item.lobbyDetails.isLobbyCreator})
                  },
                  onClickUser: () => {
                    MixpanelManager.track('clickUserNameOnHomePost', currentUser?.uid, { page: 'HomeScreen' });
                    navigation?.navigate('ProfileScreen', { uid: item.item.userId });
                  }
                })
              }}
            />
          )}

          { (postsList?.length == 0) && (currentUser?.following ?? []).length > 0 &&  (
            <View style = {{ alignContent: 'center', marginBottom: 24 }}>
              <View style = {{ flexDirection: 'row', justifyContent: 'center'}}>
                <Image source = { require('../assets/empty-jar.png') } style = {{ flexShrink: 1, width: 48, height: 48}}/>
              </View>
              <Text style = {{ 
                paddingHorizontal: 12, 
                textAlign: 'center', 
                color: '#8B8B8B',
                marginTop: 16,
                fontFamily: 'Inter'
              }}>
                Belum ada postingan dari teman kamu
              </Text>
          </View>
          )}

          { (((currentUser?.following ?? []).length == 0) && (postsList?.length == 0) && (
            <View style = {{ alignContent: 'center'}}>
              <View style = {{ flexDirection: 'row', justifyContent: 'center'}}>
                <Image source = { require('../assets/empty-jar.png') } style = {{ flexShrink: 1, width: 48, height: 48}}/>
              </View>
                <Text style = {{ 
                  paddingHorizontal: 12, 
                  textAlign: 'center', 
                  color: '#8B8B8B',
                  marginTop: 16,
                  fontFamily: 'Inter'
                }}>
                  Kamu belum mengikuti teman. Ikuti teman untuk melihat aktivitas mereka
                </Text>
              <View style = {{ flexDirection: 'row', justifyContent: 'center'}}>
                <TouchableOpacity 
                  style = {{ borderWidth: 1, borderColor: '#d9d9d9', borderRadius: 20, height: 40, paddingHorizontal: 24, marginVertical: 16, flexShrink: 1, justifyContent: 'center'}}
                  onPress = { () => {
                    MixpanelManager.track('didTapFindFriends', currentUser?.uid, { page: 'HomeScreen' });
                    navigation?.reset({
                      index: 0, 
                      routes: [
                        {
                          name: 'HomeTabs', 
                          params: { 
                            screen: 'Search'
                          }
                        }
                      ]
                    });
                  }}>
                    <Text style = {{ textAlign: 'center', color: '#8B8B8B', fontFamily: 'InterBold', fontSize: 14}}>
                      Cari Teman
                    </Text>
                </TouchableOpacity>
              </View>
            </View>
          ))}
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    // height: 60,
    paddingBottom: 16,
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1,
  },
  alignRight: {
    alignItems: 'flex-end',
  },
  alignLeft: {
    alignItems: 'flex-start',
  },
  apart: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  capacityText: {
    fontWeight: '600',
    fontSize: 12,
  },
  lightText: {
    fontWeight: 'normal',
    color: '#565656',
    fontSize: 10,
  },
  userIcon: {
    padding: 2,
    alignSelf: 'center',
    height: 16,
    width: 16,
  },
  hostIcon: {
    alignSelf: 'center',
    height: 36,
    width: 36,
    borderRadius: 18,
    backgroundColor: '#EBEBEB',
  },
  banner: {
    resizeMode: 'cover',
    height: 128,
    borderRadius: 8,
  },
  sameLine: {
    flexDirection: 'row',
    padding: 12,
    paddingLeft: 0,
  },
});
