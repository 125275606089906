import axios from 'axios';
import {
  DocumentData,
  QueryConstraint,
  QueryDocumentSnapshot,
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { LobbyResponse } from 'src/domain/LobbyResponse';
import { PostData } from 'src/domain/PostResponse';
import { UserResponseData } from 'src/domain/UserResponse';
import { z } from 'zod';

// import { CLOUDINARY_ID, uploadFile } from './cloudinary';
import { Group } from './group';
import { GetAllUserParameter, getAllUsers, getLobbyById, getUserById } from '../../../firebase';

import { db } from '@/modules/shared/libs/firebase/app';
import { UserDetail } from '../types';

export const createPostSchema = z.object({
  caption: z.string().min(1, 'Caption tidak boleh kosong'),
  photoUrl: z.string().min(1, 'Foto tidak boleh kosong'),
  lobbyId: z.string().optional(),
  userId: z.string().min(1, 'User id tidak boleh kosong'),
  groupId: z.string().optional(),
  tagPlayerIDs: z.string().array().optional(),
  tagPlayerPushTokens: z.string().array().optional(),
  likes: z.string().array().optional().default([]),
});

export type PostSchema = z.infer<typeof createPostSchema>;

export const getAllUser = async (parameter?: GetAllUserParameter) => {
  try {
    const getAllUser = await getAllUsers(parameter);

    return getAllUser;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getLastLobby = async (groupID: string) => {
  console.log('getLastLobby', groupID);
  try {
    const ref = doc(db, 'groups', groupID);
    const snapshot = await getDoc(ref);
    const groupData = snapshot.data();

    if (!groupData) {
      return;
    }
    if (!groupData?.createdLobbies) {
      console.log('group has no created lobbies ');
      return;
    }
    const lobbies: LobbyResponse[] = await Promise.all(
      groupData?.createdLobbies.map((lobbyid: string) => getLobbyById(lobbyid)),
    );
    const lobbiesBeforeToday = lobbies.filter(
      (lobby) => lobby && lobby.details.eventDate.toDate() < new Date(),
    );

    return Promise.resolve(
      lobbiesBeforeToday.reduce((a, b) => (a.details.eventDate > b.details.eventDate ? a : b)),
    );
  } catch (e) {
    console.log(e);
    return Promise.resolve(null);
  }
};
export const getUserLastLobby = async(userId: string) => {
  try {
    const ref = doc(db, 'users', userId);
    const snapshot = await getDoc(ref);
    const userData = snapshot.data();

    if ((userData?.joinedLobbies ?? []).length == 0) {
      console.log('user has never played in a lobby');
      return;
    }
    const lobbies: LobbyResponse[] = await Promise.all(
      userData?.joinedLobbies.map((lobbyid: string) => getLobbyById(lobbyid)),
    );
    const lastLobbyBeforeToday = lobbies.filter((lobby) => lobby && lobby.details.eventDate.toDate() < new Date());
    if (lastLobbyBeforeToday.length == 0) {
      console.log('user has not played in any lobby');
      return;
    } else {
      return Promise.resolve(lastLobbyBeforeToday[lastLobbyBeforeToday.length - 1]);
    }
  } catch (error) {
    console.log(error);
    return Promise.resolve(null);
  }
}
type CommentSchema = {
  content: string;
  postId: string;
  userId: string;
};
export const createComment = async (input: CommentSchema) => {
  console.log('createComment', input);
  const createdAt = serverTimestamp();
  const ref = await addDoc(collection(db, 'comments'), {
    ...input,
    createdAt,
  });
  // add comment to post

  const postRef = doc(db, 'posts', input.postId);
  await updateDoc(postRef, {
    commentsList: arrayUnion(ref.id),
  });

  return {
    commentId: ref.id,
  };
};
export const createPost = async (input: PostSchema) => {
  const createdAt = serverTimestamp();

  const { tagPlayerPushTokens, ...requestInput } = input;
  const ref = await addDoc(collection(db, 'posts'), {
    ...requestInput,
    createdAt,
  });

  if ((input.groupId ?? '') == '') { // uploaded by user and not as group
    const userRef = doc(db, 'users', input.userId);
    const userDoc = await getDoc(userRef);

    // For now, assume all posts from users
    // are to log in on lobby activity, so need to update level progress
    let { experience } = userDoc.data() as UserDetail;
    let newProgress = (experience?.progress ?? 0) + 20;
    let level = (experience?.level ?? 1);
    if (newProgress >= 100) {
      newProgress = newProgress % 100;
      level += 1;
    }
    experience = {
      progress: newProgress,
      level: level
    }

    const userPosts = userDoc?.data()?.posts ?? [];
    const posts = [...userPosts, ref.id];
    try { 
      await updateDoc(userRef, {posts, experience});
    } catch (error) {
      console.log('error saving post to user');
    }
  }

  if (tagPlayerPushTokens) {
    return {
      groupId: input.groupId,
      postId: ref.id,
      tagPlayerPushTokens,
      tagPlayerIDs: requestInput.tagPlayerIDs,
    };
  }

  return {
    groupId: input.groupId,
    postId: ref.id,
    tagPlayerPushTokens: [],
    tagPlayerIDs: requestInput.tagPlayerIDs,
  };
};

export const sendPostPushNotification = async (
  postId: string,
  groupId: string,
  pushTokenList: string[],
) => {
  if (pushTokenList.length > 0) {
    const groupRef = doc(db, 'groups', groupId);
    const groupSnapshot = await getDoc(groupRef);
    const groupData = groupSnapshot.data() as Group;
    if (groupData) {
      await axios.request({
        method: 'POST',
        url: 'https://moneys-bucket-staging.fly.dev/api/notify',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          pushTokenList,
          title: groupData.name,
          body: 'Baru saja mengetag kamu dalam postingan',
          appLink: `playard://group/${groupId}?postId=${postId}`,
        },
      });
    }
  }

  return Promise.resolve();
};

export const getPostByID = async (postId: string) => {
  const ref = doc(db, 'posts', postId);
  const postDetail = await getDoc(ref);

  return postDetail.data() as PostData;
};

export const getPosts = async (
  groupId: string,
  cursor?: QueryDocumentSnapshot<DocumentData, DocumentData>,
) => {
  const posts: any = [];
  const ref = collection(db, 'posts');

  const constraints: QueryConstraint[] = [where('groupId', '==', groupId)];

  if (cursor) {
    constraints.push(startAfter(cursor));
  }

  constraints.push(limit(5));

  const q = query(ref, ...constraints);
  const postData = await getDocs(q);

  postData.forEach((doc) => {
    posts.push({ id: doc.id, ...doc.data() });
  });

  const lastVisible = postData.docs[postData.docs.length - 1];

  const castPosts = posts as PostData[];

  // Sory by CreatedAT DESC
  const orderedPosts = castPosts.sort((a, b) => {
    return b.createdAt.toMillis() - a.createdAt.toMillis();
  });

  if (lastVisible) {
    return {
      data: orderedPosts,
      cursor: lastVisible,
    };
  }

  return {
    data: [],
    cursor: null,
  };
};

export const getUserByIds = async (userIds?: string[]) => {
  const users: any = [];
  const userRef = collection(db, 'users');
  const q = query(userRef, where('uid', 'in', userIds));
  const userDoc = await getDocs(q);

  userDoc.forEach((doc) => {
    users.push(doc.data());
  });

  return users as UserResponseData[];
};

export const getCommentsByIds = async (commentIdList: string[]) => {
  console.log('getCommentsByIds', commentIdList);
  const commentRef = collection(db, 'comments');
  let commentData: any = [];

  while (commentIdList.length > 0) {
    const batch = commentIdList.splice(0, 10);
    const q = query(commentRef, where('__name__', 'in', [...batch]));
    const result = await getDocs(q);

    const cleanResult = await Promise.all(result?.docs.map(async (doc) => {
      const user = await getUserById(doc.data().userId);
      return ({ userDetail: user, uid: doc.id, ...doc.data() })
    }
    ));
    commentData = [...commentData, ...cleanResult];
  }
  console.log('unmerged commentData', commentData);

  return commentData.sort((a: any, b: any) => b.createdAt.toMillis() - a.createdAt.toMillis());
};
export type LikeState = 'initial-like' | 'like' | 'dislike';

export const updateLike = async (currentLikes: string[], postId: string) => {
  // TODO push notif
  await updateDoc(doc(db, 'posts', postId), {
    likes: currentLikes,
  });
};
