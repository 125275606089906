import { BottomSheetBackdrop, BottomSheetModal, BottomSheetModalProps } from '@gorhom/bottom-sheet';
import React, { useCallback, useImperativeHandle, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export type BottomSheetProps = Omit<BottomSheetModalProps, 'children'>;
export type BottomSheetContentElement = ({ close }: { close: () => void }) => React.ReactElement;

export type BottomSheetRef = {
  show: ({
    content,
    bottomSheetProps,
  }: {
    content?: BottomSheetContentElement;
    bottomSheetProps?: BottomSheetProps;
  }) => void;
};

const BottomSheet = React.forwardRef<BottomSheetRef, object>((_, ref) => {
  const bottomSheetRef = React.useRef<BottomSheetModal>(null);
  const { bottom } = useSafeAreaInsets();
  const [content, setContent] = React.useState<React.ReactElement | undefined>();
  const [props, setProps] = React.useState<BottomSheetProps>();

  const snapPoints = useMemo(
    () => (props?.snapPoints ? props.snapPoints : ['80%']),
    [props?.snapPoints],
  );

  const handleClose = useCallback(() => {
    bottomSheetRef.current?.dismiss();
    setTimeout(() => {
      setContent(undefined);
      setProps(undefined);
    }, 200);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        show: ({ content, bottomSheetProps }) => {
          setContent(content?.({ close: handleClose }));
          setProps(bottomSheetProps);
          bottomSheetRef.current?.present();
        },
      };
    },
    [handleClose],
  );

  return (
    <BottomSheetModal
      ref={bottomSheetRef}
      {...(props ? props : {})}
      snapPoints={snapPoints}
      backdropComponent={(backdropProps) => (
        <BottomSheetBackdrop
          {...backdropProps}
          opacity={0.5}
          enableTouchThrough={false}
          appearsOnIndex={0}
          disappearsOnIndex={-1}
          style={[{ backgroundColor: 'rgba(0, 0, 0, 1)' }, StyleSheet.absoluteFillObject]}
        />
      )}>
      <>
        {Boolean(content) && content}
        <View style={{ height: bottom }} />
      </>
    </BottomSheetModal>
  );
});

export default BottomSheet;
