import { Image } from 'expo-image';
import React from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';

import LoadingModal from '@/modules/shared/components/LoadingModal';
import Text from '@/modules/shared/components/basic/Text';
import useImagePicker from '@/modules/shared/hooks/useImagePicker';
import { FeatherIcons, Ionicons } from '@/modules/shared/libs/icons';

type Props = {
  onChange?: (value: string) => void;
  value?: string;
};

const UploadPhoto = ({ onChange, value }: Props) => {
  const { pickImage, loading } = useImagePicker();

  const handlePress = async () => {
    const image = await pickImage();
    if (image) {
      console.log('edit profile update photo');
      onChange?.(image);
    }
  };

  return (
    <View className="items-center min-h-[120px]">
      {loading ? (
        <ActivityIndicator
          className="w-[120px] h-[120px] object-cover rounded-full border border-gray-200"
          size="large"
          color="#5F48B9"
        />
      ) : (
        <Pressable
          onPress={handlePress}
          className=" bg-neutral-200 rounded-full w-[120px] h-[120px] flex items-center justify-center relative">
          {value ? (
            <Image
              cachePolicy="disk"
              source={{ uri: value }}
              className="w-[120px] h-[120px] object-cover rounded-full border border-gray-200"
            />
          ) : (
            <Ionicons name="add-outline" size={24} color="#fff" />
          )}
          <View className="absolute z-10 w-7 h-7 items-center justify-center bg-white border rounded-sm border-gray-200 bottom-0 right-0">
            <FeatherIcons name="edit-2" size={12} />
          </View>
        </Pressable>
      )}
      <Text fontFamily="InterBold" className="ml-3 text-playard mt-4 mb-8">
        Atur Foto Profil
      </Text>
    </View>
  );
};

export default UploadPhoto;
