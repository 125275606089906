import { useQuery } from '@tanstack/react-query';

import { getAllGroups } from '@/modules/shared/api/group';
import { K_GROUPS_QUERY_KEY } from '@/modules/shared/constants/react-query';

const useQueryGroups = () => {
  return useQuery({
    queryKey: [K_GROUPS_QUERY_KEY],
    queryFn: getAllGroups,
  });
};

export default useQueryGroups;
