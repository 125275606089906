import { Image } from 'expo-image';
import React from 'react';
import { Pressable } from 'react-native';

import { useNavigation } from '../RootNavigation';

import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { Ionicons } from '@/modules/shared/libs/icons';

const HeaderLeft = () => {
  const navigation = useNavigation();
  const { currentUser } = useAuth();

  const handlePress = () => {
    if (navigation?.canGoBack()) {
      navigation?.goBack();
      return;
    }

    if (currentUser) {
      navigation?.reset({
        index: 0,
        routes: [{ name: 'HomeTabs' }],
      });
      return;
    }

    navigation?.reset({
      index: 0,
      routes: [{ name: 'WelcomeScreen' }],
    });
  };

  return (
    <Pressable onPress={handlePress}>
      <Ionicons name="arrow-back-outline" size={22} />
    </Pressable>
  );
};

export default HeaderLeft;
