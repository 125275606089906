import { cva } from 'class-variance-authority';
import { Image } from 'expo-image';
import React from 'react';
import { View } from 'react-native';

import Text from '@/modules/shared/components/basic/Text';

export type SkillLevelLabel = 'newbie' | 'intermediate' | 'expert' | 'all';

interface LabelProps {
  skillLevel: SkillLevelLabel;
}

const bgVariants = cva('py-1 px-2 rounded flex-row items-center', {
  variants: {
    variant: {
      newbie: 'bg-[#2C9D60]',
      intermediate: 'bg-[#FC9C0C]',
      expert: 'bg-[#FC5858]',
      all: 'bg-[#FFD707]',
    },
  },
  defaultVariants: {
    variant: 'all',
  },
});

export default function SkillLabel(props: LabelProps) {
  const renderLabel = () => {
    switch (props.skillLevel) {
      case 'all':
        return 'Semua';
      case 'expert':
        return 'Expert';
      case 'intermediate':
        return 'Menengah';
      case 'newbie':
        return 'Pemula';
    }
  };
  return (
    <View className="flex-row">
      <View className={bgVariants({ variant: props.skillLevel })}>
        <Image source={require('../assets/gauge-circle.png')} className="w-4 h-4 mr-1" />
        <Text fontFamily="InterSemiBold" className="text-xs/[12.37px] text-white font-semibold">
          {renderLabel()}
        </Text>
      </View>
    </View>
  );
}
