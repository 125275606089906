import { Image } from 'expo-image';
import React, { useState, useMemo } from 'react';
import { Pressable, ScrollView, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDebounce } from 'use-debounce';

import SearchBar from '../components/SearchBar';

import { useNavigation } from '@/modules/navigation/RootNavigation';
import Text from '@/modules/shared/components/basic/Text';
import { useAllUsers } from '@/modules/shared/context/AllUserContext';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

const styles = StyleSheet.create({
  searchBar: {
    marginTop: 52,
    marginHorizontal: 20,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#777',
  },
  searchRowContainer: {
    marginTop: 16,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    paddingBottom: 16,
  },
  profilePicture: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: '#ebebeb',
  },
  username: {
    fontWeight: '600',
    color: '#121212',
    fontSize: 12,
  },
  name: {
    marginTop: 2,
    fontSize: 12,
    color: '#565656',
  },
  resultContainer: {
    marginTop: 16,
    paddingHorizontal: 20,
  },
});

const SearchRow = (props: any) => {
  const navigation = useNavigation();

  return (
    <Pressable
      style={styles.searchRowContainer}
      onPress={() => {
        navigation?.navigate('ProfileScreen', { uid: props.userId });
      }}>
      <Image
        style={styles.profilePicture}
        source={props.user.photoURL}
        contentFit="contain"
        transition={300}
      />
      <View style={{ marginLeft: 16 }}>
        <Text style={styles.username}>{props.user.displayName ?? props.user.email}</Text>
        <Text style={styles.name}>{'Bermain ' + props.user.joinedLobbies.length + ' kali'}</Text>
      </View>
    </Pressable>
  );
};

const SearchPage = ({ insets }: any) => {
  const [search, setSearch] = useState('');
  const [debounceSearch] = useDebounce(search, 300);

  const allUsersContext = useAllUsers();

  const userResults = useMemo(() => {
    const results = allUsersContext.searchInstance.search(debounceSearch, {
      prefix: true,
    });
    return results;
  }, [debounceSearch, allUsersContext]);

  return (
    <View
      style={{
        backgroundColor: '#fff',
        flex: 1,
      }}>
      <SearchBar style={styles.searchBar} onChangeText={setSearch} />
      <ScrollView style={styles.resultContainer}>
        {!!debounceSearch && userResults.length === 0 && (
          <Text fontFamily="InterSemiBold" className="text-center mt-12">
            Pemain tidak ditemukan
          </Text>
        )}
        {userResults.map((user) => (
          <SearchRow user={user} key={user.uid} userId={user.uid} />
        ))}
      </ScrollView>
      <View style={{ flex: 1 }} />
    </View>
  );
};

export const SearchScreen = () => {
  const { currentUser } = useAuth();
  const insets = useSafeAreaInsets();

  return <SearchPage currentUser={currentUser} insets={insets} />;
};
