import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { DocumentData } from 'firebase/firestore';
import React, { useCallback, useEffect, useMemo } from 'react';
import { View, Text, Pressable, FlatList, ListRenderItem, ScrollView } from 'react-native';

import { followUser, unfollowUser } from '../../../firebase';

import ProfileHeader from '@/modules/account/components/ProfileHeader';
import useProfileDetail from '@/modules/account/hooks/useProfileDetail';
import useUserFollowers from '@/modules/account/hooks/useUserFollowers';
import useUserActivities from '@/modules/account/hooks/useUserActivities';
import { useUserCredits } from '@/modules/lobby/hooks/useUserCredits';
import { RootStackParamList } from '@/modules/navigation/types';
import ActivityCell from '@/modules/shared/components/ActivityCell';
import ProfileCell from '@/modules/shared/components/ProfileCell';
import SegmentedControl from '@/modules/shared/components/SegmentedControl';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { Lobby } from '@/modules/shared/types';
import { cn } from '@/modules/shared/utils/cn';
import { type UserActivity } from '@/modules/account/hooks/useUserActivities';
import { UserExperience } from '../../shared/types';

const segments = ['Aktivitas', 'Pengikut'];

const ProfileScreen = () => {
  const { params } = useRoute<RouteProp<RootStackParamList, 'ProfileScreen'>>();
  const userId = params.uid;

  const { currentUser } = useAuth();

  const activities = useUserActivities(userId);
  const { profileDetail, refetch } = useProfileDetail(userId);
  const { followers, refetch: refetchFollowers } = useUserFollowers(userId);

  const { profileDetail: currentUserDetail, refetch: refetchCurrent } = useProfileDetail(
    currentUser?.uid,
  );
  const { credits } = useUserCredits(userId);

  const navigation = useNavigation();

  const pastActivities = useMemo(
    () =>
      activities
        ?.filter((activity) => activity?.lobby.details?.eventDate.toDate() < new Date())
        .sort(
          (a, b) => b.lobby.details.eventDate.toDate().getTime() - a.lobby.details.eventDate.toDate().getTime(),
        ),
    [activities],
  );

  const upCommingLobbies = useMemo(
    () => activities?.filter((activity) => activity?.lobby.details?.eventDate.toDate() >= new Date())
                      .map((activity) =>  activity.lobby ),
    [activities],
  );

  const [activeIndex, setActiveIndex] = React.useState(0);

  const renderActivityItem = useCallback<ListRenderItem<UserActivity>>(
    ({ item }) => {
      return (
        <ActivityCell
          activity={item}
          userName={profileDetail?.displayName ?? profileDetail?.email ?? ''}
        />
      );
    },
    [profileDetail],
  );

  const renderFollowerItem = useCallback<ListRenderItem<DocumentData>>(({ item }) => {
    return <ProfileCell user={item} />;
  }, []);

  const renderFollowBtn = useCallback(() => {
    const isFollowing = profileDetail?.followers?.some((follower) => follower === currentUser?.uid);

    const handlePress = async () => {
      try {
        if (!isFollowing) {
          await followUser(profileDetail, currentUserDetail);
          return;
        }
        await unfollowUser(profileDetail, currentUserDetail);
      } catch (error) {
        console.log({ error });
      } finally {
        refetch();
        refetchCurrent();
        refetchFollowers();
      }
    };

    return (
      <Pressable
        className={cn('bg-playard px-4 py-1 rounded-full', {
          'bg-gray-300 ': isFollowing,
        })}
        onPress={handlePress}>
        <Text
          className={cn('text-xs font-medium text-white', {
            'text-gray-600': isFollowing,
          })}>
          {' '}
          {!isFollowing ? 'Ikuti' : 'Batal Ikuti'}
        </Text>
      </Pressable>
    );
  }, [currentUser, userId, refetch, refetchFollowers, profileDetail, currentUserDetail]);

  const userExperience = useMemo(
    () => {
      if (profileDetail && profileDetail.experience) {
        return profileDetail.experience;
      } else {
        let title: string;
        let progress = 0;
        const level = Math.ceil((profileDetail?.joinedLobbies?.length ?? 1) / 2);
        if (profileDetail?.joinedLobbies?.length == 0) {
          title = 'Curious Cat'
        } else if (level < 10) {
          progress = 10;
          title = 'Adventurer';
        } else if (level < 20) {
          title = 'Enthusiast';
        } else if (level < 30) {
          title = 'Cultural'
        } else {
          title = 'Maniac'
        }
        return {
          level: level,
          progress: progress,
          title: title
        }
      }
    }, [profileDetail]
  );
  const userProgressWidth = useMemo(
    () => {
      const progress = profileDetail?.experience?.progress ?? 0;
      return progress + '%';
    }, [profileDetail]
  ) ;

  useEffect(() => {
    if (profileDetail) {
      navigation.setOptions({
        title: profileDetail?.displayName,
        headerRight: renderFollowBtn,
      });
    }
  }, [profileDetail, renderFollowBtn]);

  return (
    <ScrollView className="flex-1 bg-white px-4">
      <View className="mb-4 -mt-4">
        <ProfileHeader
          userId={userId}
          showSetting={false}
          upCommingLobbies={upCommingLobbies}
          credits={credits}
        />
      </View>

      <View style = {{ gap: 12, marginBottom: 20 }} >
        <View className='flex-row' style = {{justifyContent: 'space-between', alignItems: 'flex-end'}}>
          <View style = {{ flexDirection: 'row', gap: 8 }}>
            <Text style = {{ fontFamily: 'InterBold', fontSize: 16, color: '#121212' }}>{userExperience.title}</Text>
            <View style = {{ justifyContent: 'flex-end' }}>
              <Text style = {{ fontFamily: 'InterSemiBold', fontSize: 14, color: '#565656' }}>(Level {userExperience.level})</Text>
            </View>
          </View>
          <Text style = {{ fontFamily: 'Inter', fontSize: 12, color: '#565656' }}>{userExperience.progress}/100 exp</Text>
        </View>
        <View>
          <View style = {{position: 'absolute', width: '100%', height: 8 , borderRadius: 2, backgroundColor: '#D9D9D9'}}/>
          <View style = {{position: 'absolute', width: userProgressWidth, height: 8 , borderRadius: 2, backgroundColor: '#128C7E'}}/>
        </View>
      </View>


      <SegmentedControl segments={segments} activeIndex={activeIndex} onChange={setActiveIndex} />

      {activeIndex === 0 && (
        <FlatList
          showsVerticalScrollIndicator={false}
          // data={pastLobbies.reverse()}
          data={pastActivities}
          keyExtractor={(item) => item.lobby.id}
          renderItem={renderActivityItem}
          style = {{ marginTop: 20 }}
        />
      )}
      {activeIndex === 1 && (
        <FlatList
          showsVerticalScrollIndicator={false}
          data={followers}
          keyExtractor={(item) => item.id}
          renderItem={renderFollowerItem}
          style = {{ marginTop: 20 }}
        />
      )}
    </ScrollView>
  );
};

export default ProfileScreen;
