// Initialize Firebase
export const firebaseConfig = {
  apiKey: 'AIzaSyCK1qHvj7xTN2r_3YhjWdkSRD8Be2TjpbE',
  authDomain: 'playard-indo.firebaseapp.com',
  projectId: 'playard-indo',
  storageBucket: 'playard-indo.appspot.com',
  messagingSenderId: '174201647108',
  appId: '1:174201647108:web:156a8dd86a0aa904890551',
  measurementId: 'G-3N2WX03X2P',
};

export const firebaseConfigStaging = {
  apiKey: 'AIzaSyDrx1_f3kO9OwhI_rooBhQmfH3c-7vGpUc',
  authDomain: 'playard-indo-staging.firebaseapp.com',
  projectId: 'playard-indo-staging',
  storageBucket: 'playard-indo-staging.appspot.com',
  messagingSenderId: '542851318241',
  appId: '1:542851318241:web:74d35fda628979976492a0',
};
