import { doc, getDoc } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';

import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { db } from '@/modules/shared/libs/firebase/app';

type UserDetail = {
  followers: string[];
  following: string[];
  joinedLobbies: string[];
  photoURL: string;
  displayName: string;
  email: string;
  wallet: number;
  experience?: UserExperience;
  uid: string;
};
type UserExperience = {
  progress: number;
  level: number;
  title: string;
}
type Group = {
  id: string;
  name: string;
  description: string;
  photoURL: string;
  members: string[];
  owner: string;
  lobbies: string[];
};

const useProfileDetail = (userId?: string) => {
  const { currentUser } = useAuth();
  const [profileDetail, setProfileDetail] = useState<UserDetail | null>();
  const [groupDetail, setGroupDetail] = useState<Group | null>();

  const fetchProfileDetail = useCallback(async () => {
    if (!currentUser) return console.log('no current user');
    try {
      const userRef = doc(db, 'users', userId ? userId : currentUser?.uid);
      const snapshot = await getDoc(userRef);
      const data = snapshot.data() || null;
      let userDetailData = data as UserDetail;
      if (userDetailData.experience == null) {
        let title: string;
        let progress = 0;
        const level = Math.ceil((userDetailData.joinedLobbies?.length ?? 1) / 2);
        if (userDetailData.joinedLobbies?.length == 0) {
          title = 'Curious Cat'
        } else if (level < 10) {
          progress = 10;
          title = 'Adventurer';
        } else if (level < 20) {
          title = 'Enthusiast';
        } else if (level < 30) {
          title = 'Cultural'
        } else {
          title = 'Maniac'
        }
        userDetailData.experience = { 
          progress: progress, 
          level: level,
          title: title
        }
      }
      setProfileDetail(userDetailData);
    } catch (e) {
      console.log('error getting user', e);
    }
  }, [currentUser, userId]);

  const fetchGroupDetail = useCallback(async () => {
    if (!currentUser) return;
    if (!currentUser.groupId) return;
    try {
      console.log('fetching group', currentUser.groupId);

      const groupRef = doc(db, 'groups', currentUser.groupId);
      const snapshot = await getDoc(groupRef);
      const data = snapshot.data() || null;

      console.log('group', data);
      setGroupDetail(data as Group);
    } catch (e) {
      console.log('error getting group', e);
    }
  }, [currentUser, userId]);

  useEffect(() => {
    fetchProfileDetail();
  }, [fetchProfileDetail]);

  useEffect(() => {
    fetchGroupDetail();
  }, [fetchGroupDetail]);

  return {
    profileDetail,
    refetch: fetchProfileDetail,
    groupDetail,
    refetchGroup: fetchGroupDetail,
  };
};

export default useProfileDetail;
