import Ionicons from '@expo/vector-icons/Ionicons';
import React, { useCallback } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

type Props = {
  value?: string;
  onChange?: (v: string) => void;
  close?: () => void;
};

const SelectBrandShoes = ({ value, onChange, close }: Props) => {
  const handleChange = useCallback(
    (currentValue: string) => {
      onChange?.(currentValue);
      close?.();
    },
    [onChange, close],
  );
  const brandList = [
    'Nike',
    'Adidas',
    'Puma',
    'Jordan',
    'Converse',
    'Anta',
    'Ardiles',
    'Under Armour',
  ];

  return (
    <View style={{ paddingHorizontal: 16, marginBottom: 32 }}>
      <Text style={{ fontSize: 20, fontWeight: '700' }}>Kategori Barang</Text>
      {brandList.map((item: string) => (
        <TouchableOpacity
          key={item}
          onPress={() => handleChange(item)}
          style={{ paddingTop: 20, flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              color: value === item ? '#5F48B9' : '#888',

              marginRight: 4,
              fontSize: 18,
            }}>
            {item}
          </Text>
          {value === item && <Ionicons name="checkmark" size={18} color="#5F48B9" />}
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default SelectBrandShoes;
