import React, { useCallback } from 'react';
import { Dimensions, FlatList, ListRenderItem, View } from 'react-native';

import LobbyCard from '../../../components/LobbyCard';
import useQueryGroupDetail from '../hooks/useQueryGroupDetail';
import useQueryGroupFutureLobbies from '../hooks/useQueryGroupFutureLobbies';

import CreateLobbyBottomSheetPrompt from '@/modules/lobby/components/CreateLobbyBottomSheetPrompt';
import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import Text from '@/modules/shared/components/basic/Text';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { Ionicons } from '@/modules/shared/libs/icons';
import { Pressable } from '@/modules/shared/libs/primitive/rn-primitive';
import { Lobby } from '@/modules/shared/types';

const GroupLobbies = ({ id }: { id?: string }) => {
  const { data } = useQueryGroupFutureLobbies(id);
  const { data: group } = useQueryGroupDetail(String(id));
  const { currentUser } = useAuth();

  const isOwner = currentUser?.uid === group?.userId;

  const handleShow = () => {
    DynamicBottomSheetService.show({
      content: ({ close }) => <CreateLobbyBottomSheetPrompt hide={close} groupId={String(id)} />,
    });
  };

  const emptyStateLobby = () => {
    if (isOwner) {
      return (
        <View
          className="items-center space-y-[12px] justify-center"
          style={{ width: Dimensions.get('window').width - 32 - 8 }}>
          <Pressable onPress={handleShow}>
            <Ionicons name="add-circle" size={28} color="#BDBDBD" />
          </Pressable>
          <Text fontFamily="Inter" style={{ color: '#433F51' }}>
            Buka Lobi
          </Text>
        </View>
      );
    }

    return null;
  };

  const handleRender = useCallback<ListRenderItem<Lobby>>(
    ({ item }) => (
      <View
        key={item.id}
        className="w-full bg-white px-3 rounded-lg mr-2 border-[1px] border-[#D9D9D9]"
        style={{ width: Dimensions.get('window').width - 32 - 8 }}>
        <LobbyCard
          firestoreLobby={{ id: item.id, data: item }}
          currentUserId={String(currentUser?.uid)}
        />
      </View>
    ),
    [currentUser],
  );

  // Handle empty lobby state when visit others group
  if (!isOwner && ((data && data.length === 0) || data === undefined)) {
    return null;
  }

  return (
    <View className="bg-neutral-100 py-6 mt-6">
      <View className="flex-row items-center justify-between px-4">
        <Text className="text-neutral-800 text-[20px]" fontFamily="InterBold">
          Lobi Aktif
        </Text>

        {(data?.length ?? 0) > 0 && isOwner && (
          <Pressable onPress={handleShow}>
            <Ionicons name="add-circle" size={28} color="#5F48B9" />
          </Pressable>
        )}
      </View>

      <FlatList
        data={data ?? []}
        renderItem={handleRender}
        horizontal
        showsHorizontalScrollIndicator={false}
        className="mt-4"
        ListEmptyComponent={emptyStateLobby}
        contentContainerStyle={{ paddingHorizontal: 16 }}
      />
    </View>
  );
};

export default GroupLobbies;
