import { Image } from 'expo-image';
import {
  requestTrackingPermissionsAsync,
  isAvailable,
  getTrackingPermissionsAsync,
  PermissionStatus,
} from 'expo-tracking-transparency';
import { Pressable, Text, View } from 'react-native';

import DynamicBottomSheetService from '../components/DynamicBottomSheet/DynamicBottomSheetService';

export const getTrackingPermission = () => {
  return new Promise<void>(async (resolve) => {
    if (!isAvailable()) {
      resolve();
      return;
    }

    const status = await getTrackingPermissionsAsync();

    if (status.canAskAgain && status.status === PermissionStatus.UNDETERMINED) {
      DynamicBottomSheetService.show({
        content: ({ close }) => {
          return (
            <View className="p-4">
              <Image
                source={require('../../../assets/permission_image.png')}
                className="w-40 h-40 self-center mb-6"
              />
              <Text className="text-center font-semibold mb-2">Kami Perlu Izin Kamu</Text>
              <Text className="text-center text-sm text-gray-600">
                Untuk meningkatkan pengalamanmu, kami perlu izin kamu untuk mengumpulkan dan
                menganalisis data seperti interaksi kamu dan informasi perangkat.
              </Text>

              <Pressable
                className="p-4 bg-playard rounded-lg mt-6"
                onPress={async () => {
                  await requestTrackingPermissionsAsync();
                  close();
                  resolve();
                }}>
                <Text className=" font-semibold text-center text-white">Lanjutkan</Text>
              </Pressable>
            </View>
          );
        },
        bottomSheetProps: {
          enableDismissOnClose: false,
          enableContentPanningGesture: false,
          enablePanDownToClose: false,
          enableHandlePanningGesture: false,
          enableBackdropPress: false,
        },
      });
    }
    resolve();
  });
};
