import * as ImagePicker from 'expo-image-picker';
import { StackActions, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Image } from 'expo-image';
import { StatusBar } from 'expo-status-bar';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ActivityIndicator, Pressable, ScrollView, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { UserResponseData } from 'src/domain/UserResponse';
import { useDebounce } from 'use-debounce';

// @ts-ignore

import ClockIcon from '../../../assets/clock-person.png';
// @ts-ignore
import ImagePlaceholder from '../../../assets/img-placeholder.png';
import TakePhotoPlacholder from '../../../assets/take-photo-placeholder.png';
// @ts-ignore
import TagIcon from '../../../assets/tag-person.png';
// @ts-ignore
import WhiteCloseIcon from '../../../assets/white-x.png';
import { getUserById } from '../../../firebase';
import {
  useCreatePostForm,
  useQueryGetLastLobby,
  useQueryGetusers,
} from '../hooks/useQueryGroupPost';

import { RootStackParamList } from '@/modules/navigation/types';
import { Button } from '@/modules/shared/components/basic/Button';
import Label from '@/modules/shared/components/basic/Label';
import Text from '@/modules/shared/components/basic/Text';
import TextInput from '@/modules/shared/components/basic/TextInput';
import useImagePicker from '@/modules/shared/hooks/useImagePicker';

type FollowerCellProps = {
  user: PlayerData;
  onPressPlayer: () => void;
};

const FollowerCell = ({ user, onPressPlayer }: FollowerCellProps) => {
  return (
    <Pressable className="flex-row space-x-2 my-2 items-center" onPress={onPressPlayer}>
      <Image
        className={`h-12 w-12 rounded-full bg-gray-200 ${user.isSelected ? 'border-[2px] border-[#5F48B9]' : ''}`}
        source={user.photoURL}
        contentFit="contain"
        transition={300}
        cachePolicy="memory-disk"
      />
      <View className={`p-[4px] `}>
        <Text
          fontFamily={user.isSelected ? 'InterBold' : 'Inter'}
          className={`text-xs  ${user.isSelected ? 'text-[#5F48B9]' : 'text-gray-900'}`}>
          {user.name}
        </Text>
      </View>
    </Pressable>
  );
};

interface LobbyState {
  lobbyId: string;
  eventName: string;
  eventDate: string;
  venue: string;
  isSelected: boolean;
  playerList: PlayerData[];
}

interface PlayerData {
  name: string;
  uid: string;
  photoURL: string;
  isSelected: boolean;
  pushToken?: string;
}

type Props = NativeStackScreenProps<RootStackParamList, 'CreateGroupPostScreen'>;

export default function CreateGroupPostScreen(props: Props) {
  // MARK: Hooks
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const groupId = props.route.params.groupId;

  const [lastLobby, setLastLobby] = useState<LobbyState | null>(null);
  const [searchUserValue, setSearchUserValue] = useState<string | undefined>(undefined);
  const [debounceValue] = useDebounce(searchUserValue, 100);
  const [playerList, setPLayerList] = useState<PlayerData[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<PlayerData[]>([]);
  const [combinedPlayerList, setCombinePlayerList] = useState<PlayerData[]>([]);

  const { form, submit, isPending } = useCreatePostForm(groupId);

  const isMutationLoading = isPending || form.formState.isSubmitting;

  const { data: users, refetch: refetchUser } = useQueryGetusers({
    limitBy: 10,
    searchByName: debounceValue,
  });
  const { data: lobby } = useQueryGetLastLobby(groupId);

  const { pickImage } = useImagePicker(ImagePicker.MediaTypeOptions.Images, (groupId == null));

  useEffect(() => {
    navigation.setOptions({
      headerLeft: null,
      headerRight: () => {
        return (
          <Pressable onPress={dismissPage}>
            <Image className="w-[24px] h-[24px]" source={WhiteCloseIcon} />
          </Pressable>
        );
      },
    });
  }, []);

  useEffect(() => {
    if (lobby) {
      const eventDate = DateTime.fromISO(
        new Date(lobby.details.eventDate.toDate()).toISOString(),
      ).toFormat('dd LLL yyyy • t');

      const setLobby = async () => {
        const list = await Promise.all(
          lobby.players.map(async (v) => {
            const data = await getUserById(v.userId);
            return {
              name: v.displayName && v.displayName !== '' ? v.displayName : v.email,
              uid: v.userId,
              photoURL: v.photoURL,
              isSelected: false,
              pushToken: data.pushToken,
            };
          }),
        );

        const lastLobby = {
          lobbyId: lobby.lobbyId,
          eventName: lobby.details.title,
          eventDate,
          venue: lobby.venue.title,
          isSelected: groupId == null,
          playerList: list,
        };
        setLastLobby(lastLobby);
        if (groupId == null) {
          setPLayerList(lastLobby.playerList)
          form.setValue('lobbyId', lobby.lobbyId);
        }
      };

      setLobby();
    }
  }, [lobby]);

  useEffect(() => {
    if (selectedPlayers.length > 0) {
      // Set Form Value for PlayerIDs
      const selected = selectedPlayers.filter((v) => v.isSelected);
      form.setValue(
        'tagPlayerIDs',
        selected.map((v) => v.uid),
      );

      const pushTokens = selected.filter((v) => v.pushToken !== undefined);
      form.setValue(
        'tagPlayerPushTokens',
        pushTokens.map((v) => v.pushToken ?? ''),
      );
    } else {
      form.setValue('tagPlayerIDs', []);
      form.setValue('tagPlayerPushTokens', []);
    }
  }, [selectedPlayers]);

  // MARK: User list Combine Effect
  useEffect(() => {
    let newList: PlayerData[];
    if (selectedPlayers.length > 0) {
      const uniqueIds: string[] = [];
      const listItem =
        debounceValue === '' || debounceValue === undefined
          ? [...selectedPlayers, ...playerList]
          : [...playerList, ...selectedPlayers.filter((value) => value.isSelected)];

      const list = listItem.filter((element) => {
        const isDuplicate = uniqueIds.includes(element.uid);

        if (!isDuplicate) {
          uniqueIds.push(element.uid);

          return true;
        }

        return false;
      });
      newList = list;
    } else {
      newList = playerList;
    }

    setCombinePlayerList(newList);
  }, [selectedPlayers, playerList, debounceValue]);

  // MARK: Search Player Effect
  useEffect(() => {
    if (debounceValue !== undefined) {
      if (lastLobby?.isSelected) {
        const filteredList = lastLobby.playerList
          .map((v) => {
            const selected = selectedPlayers.find((val) => val.uid === v.uid);
            return {
              ...v,
              isSelected: selected?.isSelected ?? false,
            };
          })
          .filter((v) => {
            return v.name.toLowerCase().includes(debounceValue.toLowerCase());
          });
        setPLayerList(filteredList);
      } else {
        refetchUser();
      }
    }
  }, [debounceValue, lastLobby, selectedPlayers]);

  // MARK: Set PlayerList from API
  useEffect(() => {
    if (users) {
      const list: PlayerData[] = users.map((v) => transformUserToPlayer(v.data));
      setPLayerList(list);
    }
  }, [users]);

  // MARK: Function
  const dismissPage = useCallback(() => {
    navigation.dispatch(StackActions.pop());
  }, []);

  const onPressPlayer = (item: PlayerData, index: number) => () => {
    const isSelected = !item.isSelected;
    let newPlayers: PlayerData[] = [];
    const findIndex = selectedPlayers.findIndex((v) => v.uid === item.uid);
    if (findIndex === -1) {
      newPlayers = [
        {
          ...item,
          isSelected,
        },
        ...selectedPlayers,
      ];

      setPLayerList((prevState) => {
        return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
      });
    } else {
      newPlayers = [
        ...selectedPlayers.slice(0, findIndex),
        { ...selectedPlayers[findIndex], isSelected },
        ...selectedPlayers.slice(findIndex + 1),
      ];
    }
    setSelectedPlayers(newPlayers);
  };

  const renderFollowerItem = useCallback(
    (item: PlayerData, index: number) => {
      return (
        <FollowerCell
          key={`${item.uid}-${index}`}
          user={item}
          onPressPlayer={onPressPlayer(item, index)}
        />
      );
    },
    [selectedPlayers],
  );

  const onPressLastLobby = (onChange: (lobbyId?: string) => void) => () => {
    // Reset Selected Players
    setSelectedPlayers([]);
    if (lastLobby) {
      const isSelected = !lastLobby.isSelected;
      setLastLobby((prevState) => ({
        ...prevState!,
        isSelected: !prevState!.isSelected,
      }));

      if (isSelected) {
        onChange(lastLobby.lobbyId);
        setPLayerList(lastLobby.playerList);
      } else if (users) {
        onChange(undefined);
        const list: PlayerData[] = users.map((v) => transformUserToPlayer(v.data));
        setPLayerList(list);
      }
    }
  };

  const searchUserName = (text: string) => {
    setSearchUserValue(text);
  };

  const transformUserToPlayer = (data: UserResponseData) => {
    const selected = selectedPlayers.find((val) => val.uid === data.uid);
    return {
      name: data.displayName && data.displayName !== '' ? data.displayName : data.email,
      uid: data.uid,
      photoURL: data.photoURL,
      isSelected: selected?.isSelected ?? false,
      pushToken: data.pushToken,
    };
  };

  return (
    <>
      <StatusBar style="light" />

      <ScrollView className="flex-1 bg-white pt-2 px-4 space-y-4" keyboardDismissMode="on-drag">
        {/*==== Choose Photo & Text Area Section ====*/}
        <View className="flex flex-row p-[8px] border border-[#D9D9D9] rounded-lg space-x-4 min-h-[168px]">
          <Controller
            name="photoUrl"
            control={form.control}
            render={({ field }) => (
              <Pressable
                className="flex flex-col border border-[#D9D9D9] rounded-lg p-2 items-center space-y-2 h-[99px]"
                onPress={async () => {
                  const image = await pickImage();
                  if (image) {
                    field.onChange(image);
                    console.log('image selected', image);
                  }
                }}
                disabled={isMutationLoading}>
                {field.value ? (
                  <Image source={{ uri: field.value }} className="w-[60px] h-[60px]" />
                ) : (
                  <Image source={ groupId != null ? ImagePlaceholder : TakePhotoPlacholder } className="w-[60px] h-[60px]" />
                )}

                <Text fontFamily="Inter" className="text-neutral-600 text-xs font-normal">
                  { groupId != null ? 'Pilih Foto' : 'Ambil Foto'}
                </Text>
              </Pressable>
            )}
          />

          <Controller
            name="caption"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextInput
                textAlignVertical="top"
                multiline
                className="flex-1 text-neutral-600 text-xs font-normal ml-2"
                placeholder="Ceritakan serunya moment kamu."
                onChangeText={field.onChange}
                value={field.value}
              />
            )}
          />
        </View>

        {form.formState.errors.caption && (
          <Text className="text-xs text-red-500">{form.formState.errors.caption.message}</Text>
        )}

        {form.formState.errors.photoUrl && (
          <Text className="text-xs text-red-500">{form.formState.errors.photoUrl.message}</Text>
        )}

        {/*==== Last Lobi / Event Section ====*/}
        {lastLobby && (
          <View className="border-t-[1px] border-b-[1px] border-[#D9D9D9] py-[8px] space-y-[8px]">
            <View className="flex-row items-center space-x-2">
              <Image source={ClockIcon} className="w-[24px] h-[24px]" />
              <Text fontFamily="InterSemiBold" className="text-black text-base">
                Lobi / Event
              </Text>
            </View>

            <View className="flex flex-row space-x-[17px]">
              <Controller
                name="lobbyId"
                control={form.control}
                render={({ field }) => (
                  <Pressable
                    className={`flex-col just border p-2  rounded-lg items-start space-y-[2px] w-[50%] ${lastLobby.isSelected ? 'bg-[#EFEBFF] border-[#5F48B9]' : 'border-[#D9D9D9]'}`}
                    onPress={onPressLastLobby(field.onChange)}>
                    <Label text="Lobi Terakhir" />
                    <Text fontFamily="InterSemiBold">{lastLobby.eventName}</Text>
                    <Text fontFamily="Inter" className="text-[10px] text-[#565656] font-normal">
                      {lastLobby.eventDate}
                    </Text>
                    <Text fontFamily="Inter" className="text-[10px] text-[#565656] font-normal">
                      {lastLobby.venue}
                    </Text>
                  </Pressable>
                )}
              />
            </View>
          </View>
        )}

        {/*==== Follower Tagging Section ====*/}
        <View className="space-y-[8px]  border-b-[1px] border-[#D9D9D9] pb-[8px]">
          <View className="flex-row items-center space-x-2">
            <Image source={TagIcon} className="w-[24px] h-[24px]" />
            <Text fontFamily="InterSemiBold" className="text-black text-base">
              Tag Pemain
            </Text>
          </View>

          <View className="border border-[#D9D9D9] rounded-lg h-[48px]">
            <TextInput
              textAlignVertical="top"
              multiline
              className="flex-1 text-neutral-600 text-xs font-normal px-[12px] py-[15.5px]"
              style={{ textAlign: 'left', textAlignVertical: 'top' }}
              placeholder="Cari pemain"
              onChangeText={searchUserName}
            />
          </View>

          {combinedPlayerList.map((item, index) => {
            return renderFollowerItem(item, index);
          })}
        </View>
      </ScrollView>
      <View
        className="shadow-sm shadow-black bg-white px-4 pt-4"
        style={{ paddingBottom: insets.bottom }}>
        <Button className="w-full mb-4" size="lg" onPress={submit} disabled={isMutationLoading}>
          <Text fontFamily="InterSemiBold" className="text-white text-base">
            Buat
          </Text>
          {isMutationLoading && <ActivityIndicator size="small" color="#fff" className="ml-2" />}
        </Button>
      </View>
    </>
  );
}
