import { useQuery } from '@tanstack/react-query';

import { detailUser } from '@/modules/shared/api/user';
import { K_USER_DETAIL } from '@/modules/shared/constants/react-query';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

const useFetchUserData = () => {
  const { currentUser } = useAuth();

  return useQuery({
    queryKey: [K_USER_DETAIL, currentUser?.uid],
    queryFn: () => detailUser(String(currentUser?.uid)),
    enabled: Boolean(currentUser?.uid),
  });
};

export default useFetchUserData;
