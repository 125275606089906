import Ionicons from '@expo/vector-icons/Ionicons';
import React from 'react';
import { Text, TouchableOpacity, type TouchableOpacityProps, View } from 'react-native';

type Props = {
  Icon?: React.ReactElement;
  label?: string;
  placeholder?: string;
  textValue?: string;
} & TouchableOpacityProps;

export default function PickerButton({ Icon, label, placeholder, textValue, ...props }: Props) {
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      {...props}
      style={[
        {
          borderWidth: 1,
          position: 'relative',
          paddingHorizontal: 16,
          borderRadius: 8,
          flexDirection: 'row',
          alignItems: 'center',
          borderColor: '#777',
          paddingVertical: 16,
        },
        props.style,
      ]}>
      <View
        style={{
          position: 'absolute',
          zIndex: 2,
          backgroundColor: '#fff',
          top: -8,
          left: 24,
          paddingHorizontal: 4,
        }}>
        <Text style={{ color: '#777', fontSize: 12 }}>{label}</Text>
      </View>
      {Boolean(Icon) && <View style={{ marginRight: 8 }}>{Icon}</View>}
      {textValue ? <Text>{textValue}</Text> : <Text style={{ color: '#777' }}>{placeholder}</Text>}
      <Ionicons name="chevron-down-outline" size={20} color="#777" style={{ marginLeft: 'auto' }} />
    </TouchableOpacity>
  );
}
