import { useEffect, useState } from 'react';

import { getCreditByUserId } from '../../../firebase';

export const useUserCredits = (userId: string) => {
  const [credits, setCredits] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        setLoading(true);
        const { credits } = await getCreditByUserId(userId);
        setCredits(credits);
      } catch (e) {
        setError('Failed to load credits');
      } finally {
        setLoading(false);
      }
    };

    fetchCredits();
  }, [userId]);

  return { credits, loading, error };
};
