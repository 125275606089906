import type { PropsWithChildren } from 'react';
import React from 'react';
import { View, ViewProps } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type Props = ViewProps & {
  safeAreaBottom?: boolean;
  safeAreaTop?: boolean;
};

const ScreenContainer = ({
  children,
  safeAreaBottom = true,
  safeAreaTop = true,
  ...props
}: PropsWithChildren<Props>) => {
  const insets = useSafeAreaInsets();
  const containerStyle = {
    paddingBottom: safeAreaBottom ? insets.bottom : 0,
    paddingTop: safeAreaTop ? insets.top : 0,
    flex: 1,
  };

  return (
    <View {...props} style={[containerStyle, props.style]}>
      {children}
    </View>
  );
};

export default ScreenContainer;
