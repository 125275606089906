import React from 'react';
import { Image, Pressable, Text, View } from 'react-native';

import { assetList } from '@/modules/shared/types';

type Props = {
  onCancel: () => void;
  onOk: () => void;
};

const SuccessJoinLobbyBottomSheet = ({ onCancel, onOk }: Props) => {
  return (
    <View className="px-4 pt-4">
      <Image className="h-32 w-32 self-center" source={{ uri: assetList.PAYMENT_SUCCESS }} />
      <Text className="text-center font-semibold mt-4">Pembayaran Berhasil</Text>
      <Text className="text-center my-4 text-gray-500">
        Kami telah terima pembayaran kamu dan nama kamu sudah terdaftar dalam lobi. Selamat bermain!
      </Text>
      <Pressable className="bg-white rounded-md w-full items-center py-3">
        <Text className="text-playard font-semibold text-base" onPress={onOk}>
          Kembali Ke Home
        </Text>
      </Pressable>
      <Pressable className="bg-playard rounded-md w-full items-center py-3 mt-3">
        <Text className="text-white font-semibold text-base" onPress={onCancel}>
          Lihat Lobby
        </Text>
      </Pressable>
    </View>
  );
};

export default SuccessJoinLobbyBottomSheet;
