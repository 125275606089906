import { Timestamp, collection, getDocs } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';

// import { db } from '../../../firebase';

import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { db } from '@/modules/shared/libs/firebase/app';

type Withdrawal = {
  amount: number;
  date: Timestamp;
  status: string;
  email: string;
  userId: string;
};

const useCheckPendingWithdrawals = (userId?: string) => {
  const { currentUser } = useAuth();
  const [pending, setPending] = useState<boolean>(false);

  const checkPending = useCallback(async () => {
    if (!currentUser) return;
    const withdrawalsRef = await getDocs(collection(db, 'withdrawals'));

    withdrawalsRef.forEach((doc) => {
      const data = doc.data();
      if (data.userId === userId && data.status === 'pending') {
        setPending(true);
      }
    });
  }, [currentUser, userId]);

  useEffect(() => {
    checkPending();
  }, [checkPending]);

  return { pending };
};

export default useCheckPendingWithdrawals;
