import { FirebaseAuthTypes } from '@react-native-firebase/auth';
import axios from 'axios';
import Constants from 'expo-constants';
import { User } from 'firebase/auth';
import { DateTime } from 'luxon';

import { Lobby } from '@/modules/shared/types';

const createDurianPayOrder = async (
  currentUser: FirebaseAuthTypes.User | User | null,
  localLobby?: Lobby,
) => {
  if (!localLobby) return;
  const lobbyId = localLobby.id;
  const eventDate = new Date(localLobby.details.eventDate.toDate());
  const today = new Date();
  const isEarlyBirdActive = today < eventDate;

  let finalPrice = localLobby.details.price;
  if (isEarlyBirdActive && localLobby.details.earlyBirdPrice) {
    finalPrice = localLobby.details.earlyBirdPrice;
  }
  const { data } = await axios.post(
    // __DEV__ ? "http://localhost:3000/create-order" :
    Constants?.manifest?.extra?.paymentServerUrl,
    // 'https://moneys-bucket-staging.fly.dev/create-order',
    {
      amount: String(finalPrice),
      oder_ref_id: `${lobbyId}-${currentUser?.uid}-${Date.now()}`,
      customer_ref_id: currentUser?.uid,
      email: currentUser?.email,
      lobbyImage: localLobby?.creator.photoUrl,
      metadata: { lobbyId, userId: currentUser?.uid },
      lineItemText:
        localLobby?.details.title +
        ', ' +
        localLobby?.details.category +
        ', ' +
        DateTime.fromISO(new Date(localLobby.details.eventDate.toDate()).toISOString()).toFormat(
          'dd LLL yyyy • t',
        ),
    },
  );
  console.log(data);
  return data;
};

type Callbacks = {
  onClose?: (response?: any) => void;
  onSuccess?: (response?: any) => void;
  onFailure?: (error?: any) => void;
};

export const handlePay = async (
  currentUser: FirebaseAuthTypes.User | User | null,
  lobby?: Lobby,
  callbacks?: Callbacks,
) => {
  const {
    data: { access_token, id },
  } = await createDurianPayOrder(currentUser, lobby);

  //@ts-ignore
  const dpay = await window.Durianpay.init({
    locale: 'id',
    environment: 'production', // Value should be 'production' for both sandbox and live mode
    access_key: access_token,
    // access_key:'dp_test_XXXXXXXXX',
    // site_name: "playard.id",
    redirect_url:
      process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
        ? 'https://app.playard.id'
        : 'https://staging.playard.id',
    order_info: {
      id,
      customer_info: {
        id: currentUser?.uid,
        email: currentUser?.email,
      },
    },

    onClose(response: any) {
      callbacks?.onClose?.(response);
    },
    onSuccess(response: any) {
      callbacks?.onSuccess?.(response);
      //   // this happens after the payment is completed successfully
      //   console.log("success", response);
      //   joinLobby(lobbyId, currentUser?.uid);
      //   setShowBottomSheet(true);
      //   setisLoading(false);
    },
    onFailure(error: any) {
      callbacks?.onFailure?.(error);

      //   console.log("paymentFailed", error);
      //   setisLoading(false);
    },
  });
  dpay.checkout();
};
