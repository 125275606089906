import Ionicons from '@expo/vector-icons/Ionicons';
import { zodResolver } from '@hookform/resolvers/zod';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import dayjs from 'dayjs';
import { Image } from 'expo-image';
import { addDoc, collection, doc, getDoc, Timestamp, updateDoc } from 'firebase/firestore';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Keyboard, Alert, ScrollView, View, TouchableOpacity } from 'react-native';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';

import AnimatedTextInput from '../../../components/AnimatedTextInput';
import PlButton from '../../../components/PlButton';
import ScreenContainer from '../../../components/ScreenContainer';
import { useLobby } from '../../../providers/LobbyProvider';
import useFetchUserData from '../../account/hooks/useFetchUserData';
import DynamicBottomSheetService from '../../shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import PickerButton from '../../shared/components/PickerButton';
import DateTimeBottomSheetContent from '../components/DateTimeBottomSheetContent';
import { JoinerTypeSelection } from '../components/JoinerTypeSelection';
import { PrivacySelection } from '../components/PrivacySelection';
import SelectCategoryBottomSheetContent from '../components/SelectCategoryBottomSheetContent';
import SelectGenderBottomSheetContent from '../components/SelectGenderBottomSheetContent';
import { createLobbySchema, CreateLobbySchema, JoinerType } from '../schema/createLobbySchema';

import useQueryGroupDetail from '@/modules/group/hooks/useQueryGroupDetail';
import { RootStackParamList } from '@/modules/navigation/types';
import Text from '@/modules/shared/components/basic/Text';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { db } from '@/modules/shared/libs/firebase/app';
import { assetList } from '@/modules/shared/types';

const ErrorText = ({ text }: { text: string }) => {
  return (
    <Text
      style={{
        color: '#FF5E5E',
        textAlign: 'right',
        marginRight: 8,
        marginTop: 4,
        fontSize: 12,
      }}>
      {text}
    </Text>
  );
};

type Props = NativeStackScreenProps<RootStackParamList, 'CreateLobbyScreen'>;

const CreateLobbyScreen = (props: Props) => {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateLobbySchema>({
    resolver: zodResolver(createLobbySchema),
    defaultValues: {
      durationInMinutes: 120,
      joinerType: JoinerType.Internal,
      hasPhotographer: false,
      isPrivate: false,
    },
  });
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const auth = useAuth();
  const { data: userData } = useFetchUserData();

  const { refreshData } = useLobby();
  const { data: groupData } = useQueryGroupDetail(userData?.groupAdmin);

  const handleShowDateTime = useCallback(() => {
    DynamicBottomSheetService.show({
      content: ({ close }) => (
        <DateTimeBottomSheetContent
          close={close}
          value={dayjs(watch('eventDate'))}
          onChange={(v) => setValue('eventDate', new Date(v?.toISOString()))}
        />
      ),
      bottomSheetProps: {
        enablePanDownToClose: false,
        enableContentPanningGesture: false,
        enableHandlePanningGesture: false,
      },
    });
  }, [watch, setValue]);

  console.log(JSON.stringify(errors, null, 2));

  const handleShowGender = useCallback(() => {
    DynamicBottomSheetService.show({
      content: ({ close }) => (
        <SelectGenderBottomSheetContent
          close={close}
          value={watch('genderGroup')}
          onChange={(c) => setValue('genderGroup', c)}
        />
      ),
    });
  }, []);

  const handleShowCategory = useCallback(() => {
    DynamicBottomSheetService.show({
      content: ({ close }) => (
        <SelectCategoryBottomSheetContent
          close={close}
          value={watch('category')}
          onChange={(c) => setValue('category', c)}
        />
      ),
    });
  }, []);

  const onSubmit = async (submitData: CreateLobbySchema) => {
    try {
      setLoading(true);
      console.log(submitData, '<<<<<<');
      const eventDate = new Date(submitData.eventDate);

      const { venue, googlePlaceId, address, ...formData } = {
        ...submitData,
        eventDate: Timestamp.fromDate(eventDate),
      };

      const lobbyRef = collection(db, 'lobbies');
      const result = await addDoc(lobbyRef, {
        venue: {
          title: venue,
          address,
          gMapsLink:
            'https://www.google.com/maps/search/?api=1&query=<address>&query_place_id=' +
            googlePlaceId,
          googlePlaceId,
        },
        details: formData,
        creator: {
          name: groupData ? groupData?.name : auth.currentUser?.displayName,
          photoUrl: groupData ? groupData?.profileImg : auth.currentUser?.photoURL,
          userId: auth.currentUser?.uid,
        },
        players: [],
        groupId: props.route.params.groupId,
      });

      // Append the id to Group

      const ref = doc(
        db,
        'groups',
        Array.isArray(props.route.params.groupId)
          ? props.route.params.groupId[0]
          : props.route.params.groupId,
      );
      const snapshot = await getDoc(ref);
      const data = snapshot.data();

      if (data) {
        try {
          if (!data.createdLobbies) {
            await updateDoc(ref, {
              createdLobbies: [result.id],
            });
            return;
          } else {
            const lobbies = [...data.createdLobbies, result.id];
            await updateDoc(ref, {
              createdLobbies: lobbies,
            });
          }
        } catch (e) {
          console.log('error update createdLobbies', e);
        }
      }

      refreshData();
      Alert.alert('Sip, Berhasil', 'Lobi sudah dibuat dan bisa dibagikan ke teman kamu');
      navigation.reset({
        index: 0,
        routes: [{ name: 'HomeTabs' }],
      });
    } catch (error) {
      console.log(error, '<<<<<');
      alert('Gagal Buat Lobi!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScreenContainer style={{ backgroundColor: 'white' }} safeAreaTop={false}>
      <ScrollView contentContainerStyle={{ padding: 16 }} keyboardShouldPersistTaps="handled">
        {userData?.groupAdmin && groupData && (
          <View className="flex-row bg-neutral-100 p-4 rounded-lg mb-6 items-center">
            <View style={{ paddingRight: 12, paddingTop: 1, paddingLeft: 0 }}>
              <Image
                style={{
                  alignSelf: 'center',
                  height: 36,
                  width: 36,
                  borderRadius: 18,
                  backgroundColor: '#EBEBEB',
                }}
                source={groupData?.profileImg || assetList.PLACEHOLDER_USER}
              />
            </View>

            <Text style={{ fontSize: 18, fontWeight: '600' }}>{groupData.name}</Text>
          </View>
        )}
        <Text fontFamily="InterSemiBold" style={{ fontWeight: '600', marginBottom: 16 }}>
          General
        </Text>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState: { error } }) => {
            return (
              <AnimatedTextInput
                label="Nama Lobi"
                onChangeText={field.onChange}
                value={field.value}
                isError={!!error?.message}
                message={error?.message}
              />
            );
          }}
        />
        <View style={{ height: 16 }} />
        <Controller
          control={control}
          name="category"
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <PickerButton
                  Icon={<Ionicons name="cellular-outline" size={20} color="#777" />}
                  placeholder="Pilih"
                  label="Tingkat Bermain"
                  textValue={field.value}
                  onPress={() => {
                    Keyboard.dismiss();
                    handleShowCategory();
                  }}
                />
                {!!error?.message && <ErrorText text={error?.message} />}
              </>
            );
          }}
        />

        <View style={{ height: 16 }} />
        <Controller
          control={control}
          name="capacity"
          render={({ field, fieldState: { error } }) => {
            return (
              <AnimatedTextInput
                label="Kuota Pemain"
                keyboardType="numeric"
                Icon={<Ionicons name="person-outline" size={20} color="#777" />}
                value={field.value ? String(field.value) : undefined}
                isError={!!error?.message}
                message={error?.message}
                onChangeText={(text) => field.onChange(+text)}
              />
            );
          }}
        />

        <View style={{ height: 16 }} />
        <Controller
          control={control}
          name="genderGroup"
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <PickerButton
                  Icon={<Ionicons name="male-outline" size={20} color="#777" />}
                  placeholder="Pilih"
                  onPress={() => {
                    Keyboard.dismiss();
                    handleShowGender();
                  }}
                  textValue={field.value}
                  label="Jenis Kelamin"
                />
                {!!error?.message && <ErrorText text={error?.message} />}
              </>
            );
          }}
        />

        <View style={{ height: 16 }} />
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState: { error } }) => {
            return (
              <AnimatedTextInput
                label="Deskripsi"
                value={field.value}
                isError={!!error?.message}
                message={error?.message}
                onChangeText={field.onChange}
              />
            );
          }}
        />

        <View
          style={{ height: 2, backgroundColor: '#eee', marginHorizontal: -32, marginVertical: 22 }}
        />

        <Text fontFamily="InterSemiBold" style={{ marginBottom: 24 }}>
          Detail Lapangan
        </Text>

        <ScrollView className="flex-1" keyboardShouldPersistTaps="handled">
          <GooglePlacesAutocomplete
            keyboardShouldPersistTaps="always"
            disableScroll
            placeholder="Lapangan"
            styles={{
              textInput: {
                height: 54,
                borderColor: '#777',
                borderWidth: 1,
                borderRadius: 8,
              },
            }}
            onPress={(data, details = null) => {
              // 'details' is provided when fetchDetails = true
              Keyboard.dismiss();
              console.log('venue selected', data);
              if (!data) return;
              setValue('venue', data.structured_formatting.main_text);
              setValue('googlePlaceId', data.place_id);
              setValue('address', data.description);
            }}
            query={{
              key: 'AIzaSyBWAdL9BJoW8jh4wMcfP_nP8wtSGIw7WAs',
              language: 'id',
              components: 'country:id',
            }}
          />
        </ScrollView>

        <View style={{ height: 16 }} />
        <Controller
          control={control}
          name="eventDate"
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <PickerButton
                  Icon={<Ionicons name="time-outline" size={20} color="#777" />}
                  placeholder="Pilih"
                  label="Jadwal"
                  // onPress={handleShowDateTime}
                  onPress={() => {
                    Keyboard.dismiss();
                    handleShowDateTime();
                  }}
                  textValue={
                    field.value ? dayjs(field.value).format('DD MMMM YYYY HH:mm') : undefined
                  }
                />
                {!!error?.message && <ErrorText text={error?.message} />}
              </>
            );
          }}
        />

        <View style={{ height: 16 }} />
        <Controller
          control={control}
          name="price"
          render={({ field, fieldState: { error } }) => {
            return (
              <AnimatedTextInput
                keyboardType="numeric"
                label="Tarif Per Pemain"
                Icon={<Ionicons name="cash-outline" size={20} color="#777" />}
                value={field.value ? String(field.value) : String(0)}
                isError={!!error?.message}
                message={error?.message}
                onChangeText={(text) => field.onChange(+text)}
              />
            );
          }}
        />
        <View style={{ height: 16 }} />
        <Controller
          control={control}
          name="hasPhotographer"
          render={({ field, fieldState: { error } }) => {
            return (
              <TouchableOpacity
                className="flex-row items-center space-x-4"
                onPress={() => field.onChange(!field.value)}>
                <View className="h-8 w-8 rounded-full border border-gray-300 items-center justify-center">
                  {field.value && <View className="bg-playard h-5 w-5 rounded-full" />}
                </View>
                <View className="flex-1">
                  <Text fontFamily="InterSemiBold">Ada fotografer</Text>
                </View>
              </TouchableOpacity>
            );
          }}
        />
        <View
          style={{ height: 2, backgroundColor: '#eee', marginHorizontal: -32, marginVertical: 22 }}
        />

        <Text fontFamily="InterSemiBold" style={{ marginBottom: 16 }}>
          Privasi Lobi
        </Text>
        <Controller
          control={control}
          name="isPrivate"
          render={({ field, fieldState: { error } }) => (
            <PrivacySelection value={field.value} onPress={field.onChange} />
          )}
        />
        <View style={{ height: 24 }} />
        <View
          style={{
            backgroundColor: '#FFF2C5',
            padding: 12,
            borderRadius: 6,
            flexDirection: 'row',
          }}>
          <Ionicons name="information-circle" size={20} color="#C97B07" />
          <Text style={{ fontSize: 10, color: '#C97B07', marginLeft: 8, marginRight: 8, flex: 1 }}>
            Kamu akan menerima uang sebesar tarif per pemain dikali dengan jumlah pemain yang daftar
            dalam lobi kamu. Uang tersebut akan dapat dilihat dan ditarik melalui halaman profil
            kamu, setelah dipotong 10% untuk biaya komisi.
          </Text>
        </View>

        <View style={{ height: 200 }} />
      </ScrollView>
      <View
        style={{
          alignSelf: 'stretch',
          padding: 20,
          justifyContent: 'flex-end',
          shadowColor: '#ABABAB',
          backgroundColor: '#fff',
          shadowOffset: {
            width: 0,
            height: -4,
          },
          shadowOpacity: 0.25,
          shadowRadius: 4,
        }}>
        <PlButton
          isLoading={loading}
          type="normal"
          title="Buat Lobi"
          customStyle={{ borderRadius: 8 }}
          onPress={handleSubmit(onSubmit)}
        />
      </View>
    </ScreenContainer>
  );
};

export default CreateLobbyScreen;
