import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import DateTimePicker from 'react-native-ui-datepicker';

import PlButton from '../../../components/PlButton';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Jakarta');

type Props = {
  value?: dayjs.Dayjs | Date;
  onChange?: (v: dayjs.Dayjs | Date) => void;
  close?: () => void;
};

const DateTimeBottomSheetContent = ({ value = dayjs(), onChange, close }: Props) => {
  const [currentValue, setCurrentValue] = useState(value);

  const handleChange = useCallback(() => {
    onChange?.(currentValue);
    close?.();
  }, [currentValue, onChange, close]);

  return (
    <View style={{ paddingHorizontal: 16 }}>
      <DateTimePicker
        value={currentValue}
        onValueChange={(v) => setCurrentValue(dayjs(v))}
        minimumDate={dayjs().subtract(1, 'day')}
        selectedItemColor="#5F48B9"
      />
      <PlButton
        type="normal"
        title="Pilih Tanggal"
        customStyle={{ borderRadius: 8 }}
        onPress={handleChange}
      />
    </View>
  );
};

export default DateTimeBottomSheetContent;
