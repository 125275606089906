export const areAllValuesNotEmpty = (obj: any, optionalKeys: string[] = []) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (optionalKeys.includes(key)) {
        continue;
      }
      if (obj[key] === '' || obj[key] == null) {
        return false;
      }
    }
  }
  return true;
};
