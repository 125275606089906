import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import * as z from 'zod';

import { uploadFile } from './cloudinary';
import { UserDetail } from '../types';

import { db } from '@/modules/shared/libs/firebase/app';

export const createGroupSchema = z.object({
  name: z.string().min(1, 'Nama grup tidak boleh kosong'),
  city: z.string().min(1, 'Kota tidak boleh kosong'),
  year: z.string().min(1, 'Tahun tidak boleh kosong'),
  profileImg: z.string().min(1, 'Foto profil tidak boleh kosong'),
  coverImg: z.string().min(1, 'Foto sampul tidak boleh kosong'),
  description: z.string().min(1, 'Deskripsi tidak boleh kosong'),
  instagram: z.string(),
  whatsapp: z.string().min(1, 'Nomor whatsapp tidak boleh kosong'),
  userId: z.string().min(1, 'User id tidak boleh kosong'),
  createdLobbies: z.string().array().default([]),
});

export type GroupSchema = z.infer<typeof createGroupSchema>;
export type Group = z.infer<typeof createGroupSchema> & {
  id: string;
  followers?: string[];
};

export const createGroup = async (input: GroupSchema, userId: string) => {
  console.log('createGroup', input, userId);
  const userRef = doc(db, 'users', userId);
  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists) {
    throw new Error('User not found');
  }

  // TODO look at this
  // const [profileImg, coverImg] = await Promise.all([
  // uploadFile(input.profileImg, `groups`),
  // uploadFile(input.coverImg, `groups`),
  // ]);
  console.log('image urls', input.profileImg, input.coverImg);
  const ref = await addDoc(collection(db, 'groups'), {
    ...input,
    profileImg: input.profileImg,
    coverImg: input.coverImg,
  });

  const currentUser = userSnapshot.data() as UserDetail;

  let groups = new Set();

  if (currentUser.groups) {
    groups = new Set(currentUser.groups);
  }
  let groupAdmin = new Set();

  if (currentUser.groupAdmin) {
    groupAdmin = new Set(currentUser.groupAdmin);
  }

  groups.add(ref.id);
  groupAdmin.add(ref.id);

  updateDoc(userRef, {
    groups: Array.from(groups),
    groupAdmin: Array.from(groupAdmin),
  });
};

export const updateGroup = async (input: GroupSchema & { id: string }, userId: string) => {
  const userRef = doc(db, 'users', userId);
  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists) {
    throw new Error('User not found');
  }

  const user = userSnapshot.data() as UserDetail;

  if (user.groupAdmin && !user.groupAdmin.includes(input.id)) {
    throw new Error('User is not admin of this group');
  }

  const profileImg = input.profileImg;
  const coverImg = input.coverImg;

  await updateDoc(doc(db, 'groups', input.id), {
    ...input,
    profileImg,
    coverImg,
  });
};

export const followGroup = async (groupId: string, userId: string) => {
  const groupRef = doc(db, 'groups', groupId);
  const groupSnapshot = await getDoc(groupRef);

  const userRef = doc(db, 'users', userId);
  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists || !groupSnapshot.exists) {
    return;
  }

  const group = groupSnapshot.data() as Group;
  const user = userSnapshot.data() as UserDetail;

  let groupFollowers = new Set();
  let userGroups = new Set();

  if (user.groups) {
    userGroups = new Set(user.groups);
  }

  if (group.followers) {
    groupFollowers = new Set(group.followers);
  }

  groupFollowers.add(userId);
  userGroups.add(groupId);

  await Promise.all([
    updateDoc(groupRef, {
      followers: Array.from(groupFollowers),
    }),
    updateDoc(userRef, {
      groups: Array.from(userGroups),
    }),
  ]);
};

export const unfollowGroup = async (groupId: string, userId: string) => {
  const groupRef = doc(db, 'groups', groupId);
  const groupSnapshot = await getDoc(groupRef);

  const userRef = doc(db, 'users', userId);
  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists || !groupSnapshot.exists) {
    return;
  }

  const group = groupSnapshot.data() as Group;
  const user = userSnapshot.data() as UserDetail;

  let groupFollowers = new Set();
  let userGroups = new Set();

  if (user.groups) {
    userGroups = new Set(user.groups);
  }

  if (group.followers) {
    groupFollowers = new Set(group.followers);
  }

  groupFollowers.delete(userId);
  userGroups.delete(groupId);

  await Promise.all([
    updateDoc(groupRef, {
      followers: Array.from(groupFollowers),
    }),
    updateDoc(userRef, {
      groups: Array.from(userGroups),
    }),
  ]);
};

export const getDetailGroup = async (id: string) => {
  console.log('getDetailGroup', id);
  try {
    const snapshot = await getDoc(doc(db, 'groups', id));

    if (!snapshot.exists) {
      return null;
    }

    return {
      id,
      ...snapshot.data(),
    } as Group;
  } catch (e) {
    console.log(e);
  }
};

export const getUserDetailGroup = async (userId: string) => {
  const ref = collection(db, 'groups');
  const q = query(ref, where('userId', '==', userId));
  const groupSnapshots = await getDocs(q);

  if (groupSnapshots.empty) {
    return null;
  }

  return groupSnapshots.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }))[0] as Group;
};

export const getAllGroups = async () => {
  const querySnapshot = await getDocs(collection(db, 'groups'));
  const groups = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return groups as Group[];
};

export const getFollowedGroups = async (userId: string) => {
  const userRef = doc(db, 'users', userId);
  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists) {
    return [];
  }

  const user = userSnapshot.data() as UserDetail;

  const groupIds = user.groups ?? [];

  const groups = await Promise.all(groupIds.map((id) => getDetailGroup(id)));

  return groups.filter((group) => group !== null) as Group[];
};
