import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { JoinerType } from '../schema/createLobbySchema';

import Text from '@/modules/shared/components/basic/Text';

type Props = {
  onPress: (value: JoinerType) => void;
  value: JoinerType;
};

export function JoinerTypeSelection({ value, onPress }: Props) {
  return (
    <View>
      <TouchableOpacity
        className="flex-row items-center space-x-4"
        onPress={() => onPress(JoinerType.Internal)}>
        <View className="h-8 w-8 rounded-full border border-gray-300 items-center justify-center">
          {value === JoinerType.Internal && <View className="bg-playard h-5 w-5 rounded-full" />}
        </View>
        <View className="flex-1">
          <Text fontFamily="InterSemiBold">Semua pemain daftar lewat Playard</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        className="flex-row space-x-4 mt-4"
        onPress={() => onPress(JoinerType.External)}>
        <View className="h-8 w-8 rounded-full border border-gray-300 items-center justify-center">
          {value === JoinerType.External && <View className="bg-playard h-5 w-5 rounded-full" />}
        </View>
        <View className="flex-1">
          <Text fontFamily="InterSemiBold">Tambahan dari luar member grup</Text>
          <Text className="mt-2 text-gray-600">
            Member grup adalah yang sudah rutin bermain di dalam grup, sehingga tidak akan mendaftar
            lewat Playard
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}
