import { useMutation, useQueryClient } from '@tanstack/react-query';

import { followGroup } from '@/modules/shared/api/group';
import { K_GROUP_DETAIL_QUERY_KEY } from '@/modules/shared/constants/react-query';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

const useMutateFollowGroup = () => {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ groupId }: { groupId: string }) => {
      if (!currentUser) return;

      await followGroup(groupId, currentUser.uid);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [K_GROUP_DETAIL_QUERY_KEY],
      });
      // if (!__DEV__) {
      //   customEvent('group-followed', {
      //     description: `followed group`,
      //     user: currentUser,
      //   });
      // }
    },
  });
};

export default useMutateFollowGroup;
