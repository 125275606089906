import { zodResolver } from '@hookform/resolvers/zod';
import { useRoute } from '@react-navigation/native';
import React, { forwardRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ActivityIndicator, Platform, ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { z } from 'zod';

import UploadPhoto from '../components/UploadPhoto';
import useFetchUserData from '../hooks/useFetchUserData';
import useMutateUpdateAccount from '../hooks/useMutateUpdateAccount';

import { useNavigation } from '@/modules/navigation/RootNavigation';
import { RouteProp } from '@/modules/navigation/types';
import LoadingModal from '@/modules/shared/components/LoadingModal';
import { Button } from '@/modules/shared/components/basic/Button';
import Text from '@/modules/shared/components/basic/Text';
import TextInput from '@/modules/shared/components/basic/TextInput';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { cn } from '@/modules/shared/utils/cn';

const schema = z.object({
  displayName: z.string().min(1, 'Required'),
  username: z.string().min(1, 'Required'),
  photoURL: z.string(),
});

type Schema = z.infer<typeof schema>;

const EditProfileScreen = () => {
  const { params } = useRoute<RouteProp<'EditProfileScreen'>>();

  const canBack = params?.canBack ?? false;
  const { data: userData } = useFetchUserData();
  const navigation = useNavigation();
  const { currentUser, setCurrentUser } = useAuth();

  const { control, handleSubmit, setValue, watch } = useForm<Schema>({
    defaultValues: {
      displayName: '',
      username: '',
      photoURL: '',
    },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (userData) {
      setValue('displayName', userData?.displayName ?? '');
      setValue('username', userData?.username ?? '');
      setValue('photoURL', userData?.photoURL ?? '');
    }
  }, [userData]);
  const { mutateAsync, isPending } = useMutateUpdateAccount(
    Boolean(currentUser?.username),
    // TODO iyan look at this
    Boolean(currentUser?.photoURL) && currentUser?.photoURL === watch('photoURL'),
  );

  const handleUpdateAccount = async (data: Schema) => {
    console.log('photo data should be a link', data.photoURL);
    try {
      console.log('current user in account update', currentUser);
      await mutateAsync({ ...data, id: String(userData?.uid) });
      setCurrentUser({
        ...currentUser,
        displayName: data.displayName,
        username: data.username,
        photoURL: data.photoURL,
      });
      if (Platform.OS === 'web') {
        window.location.href = '/home';
      }

      setTimeout(() => {
        if (canBack) {
          navigation?.goBack();
          return;
        }
        navigation?.reset({ index: 0, routes: [{ name: 'HomeTabs' }] });
      }, 500);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <SafeAreaView className="flex-1 bg-white">
      <ScrollView className="p-4 ">
        <Text fontFamily="InterBold" className="text-center">
          Lengkapi Profil
        </Text>
        <View className="mt-6">
          <Controller
            control={control}
            name="photoURL"
            render={({ field }) => <UploadPhoto onChange={field.onChange} value={field.value} />}
          />
        </View>

        <View className="space-y-4">
          <View>
            <Controller
              control={control}
              name="displayName"
              render={({ field, fieldState }) => (
                <TextInput
                  placeholder="Nama Kamu"
                  className={cn('border border-neutral-300 rounded-md text-base leading-5 p-4', {
                    'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                  })}
                  onChangeText={field.onChange}
                  value={field.value}
                />
              )}
            />
          </View>

          <View>
            <Controller
              control={control}
              name="username"
              render={({ field, fieldState }) => (
                <TextInput
                  placeholder="Username"
                  className={cn('border border-neutral-300 rounded-md text-base leading-5 p-4', {
                    'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                  })}
                  onChangeText={field.onChange}
                  value={field.value}
                />
              )}
            />
          </View>
          <Button
            className="w-full mb-4"
            size="default"
            onPress={handleSubmit(handleUpdateAccount)}
            disabled={isPending}>
            <Text fontFamily="InterSemiBold" className="text-white text-base">
              Simpan
            </Text>
            {isPending && <ActivityIndicator size="small" color="#fff" className="ml-2" />}
          </Button>
          {canBack && (
            <Button className="w-full mb-4 bg-white" size="sm" onPress={() => navigation?.goBack()}>
              <Text fontFamily="InterSemiBold" className="text-gray-600 text-sm">
                Lewati Untuk Sekarang
              </Text>
            </Button>
          )}
        </View>
      </ScrollView>
      <LoadingModal visible={isPending} />
    </SafeAreaView>
  );
};

export default EditProfileScreen;
