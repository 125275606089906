import { Image, ImageSourcePropType, Text, View } from 'react-native';
import { NumericFormat } from 'react-number-format';

import { s } from '../../../styles';

type LobbyTraitProps = {
  iconSource: ImageSourcePropType;
  text: string;
  parseTextAsCurrency?: boolean;
};

const LobbyTrait = ({ iconSource, text, parseTextAsCurrency }: LobbyTraitProps) => {
  // console.log(iconSource);
  return (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        width: '25%',
        height: '100%',
      }}>
      <Image source={iconSource} style={{ width: 36, height: 36, borderRadius: 18 }} />
      <Text style={{ ...s.lightText, marginTop: 8, textAlign: 'center' }}>
        {parseTextAsCurrency ? (
          <NumericFormat
            value={text}
            displayType="text"
            thousandSeparator
            renderText={(formattedValue) => <Text>{formattedValue} IDR / pax</Text>} // <--- Don't forget this!
          />
        ) : (
          text
        )}
      </Text>
    </View>
  );
};

export default LobbyTrait;
