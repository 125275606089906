import { Image } from 'expo-image';
import React from 'react';
import { View, Text, Modal, TouchableOpacity } from 'react-native';

import Shop from '../../../assets/shop.png';

interface WarehousePopUpConfirmationProps {
  visible: boolean;
  onClose: () => void;
  onAddItem: () => void;
}

const WarehousePopUpConfirmation: React.FC<WarehousePopUpConfirmationProps> = ({
  visible,
  onClose,
  onAddItem,
}) => {
  return (
    <Modal animationType="slide" transparent visible={visible} onRequestClose={onClose}>
      <View className="flex-1 justify-center items-center bg-black/50">
        <View className="w-80 h-auto m-5 bg-white rounded-lg p-5 shadow-lg">
          <View className="flex-row justify-center items-center pb-4">
            <Image source={Shop} className="h-10 w-10 m-1" />
            <Text className="text-sm font-bold">Gudang Headshoes</Text>
          </View>
          <Text className="text-base pb-4 text-center">
            Gudang berhasil dibuka, tambahkan barang pertama untuk dijual sekarang?
          </Text>
          <View className="w-full items-center self-center">
            <TouchableOpacity className="bg-transparent p-2 items-center" onPress={onClose}>
              <Text className="text-base text-center text-gray-600">Nanti Saja</Text>
            </TouchableOpacity>
            <TouchableOpacity className="p-2 bg-transparent items-center" onPress={onAddItem}>
              <Text className="text-base text-center text-[#5F48B9]">Tambah Barang</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default WarehousePopUpConfirmation;
