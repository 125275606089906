import * as ImagePicker from 'expo-image-picker';
import { useState } from 'react';

import { uploadImageFromBase64 } from '../api/cloudinary';

const useImagePicker = (
  mediaTypes: ImagePicker.MediaTypeOptions = ImagePicker.MediaTypeOptions.Images,
  openCamera: boolean
) => {
  const [_libraryPermission, requestPermission] = ImagePicker.useMediaLibraryPermissions();
  const [_cameraPermission, requestCameraPermission] = ImagePicker.useCameraPermissions();
  const [loading, setLoading] = useState(false);

  const pickImage = async () => {
    setLoading(true);
    try {
      let request;
      if (openCamera) {
        request = await requestCameraPermission()
      } else {
        request = await requestPermission()
      }
      if (request.granted) {
        // No permissions request is necessary for launching the image library
        let result;
         if (openCamera) {
          result = await ImagePicker.launchCameraAsync({
            mediaTypes,
            selectionLimit: 1,
            quality: 0.7,
            base64: true
        });
        } else {
          result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes,
            selectionLimit: 1,
            quality: 0.7,
            base64: true,
            allowsEditing: true,
          })
        }
        if (!result.canceled) {
          const { uri, base64 } = result.assets[0];
          const imageUrl = await uploadImageFromBase64(uri, base64 as string);
          setLoading(false);
          return imageUrl;
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      console.log('Error picking image', e);
      // NOTE edge case, filename public.jpeg cannot be uploaded
      setLoading(false);
    }
  };

  return { pickImage, loading };
};

export default useImagePicker;
