import Ionicons from '@expo/vector-icons/Ionicons';
import React from 'react';
import { View, TextInput, TextInputProps, TouchableOpacity, Platform } from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

type Props = {
  label?: string;
  required?: boolean;
} & TextInputProps;

const AnimatedTextInput = ({ label, required, ...props }: Props) => {
  const topValue = useSharedValue(16);
  const isFocused = useSharedValue(false);
  const ref = React.useRef<TextInput>(null);

  const [isPassword, setIsPassword] = React.useState(props.secureTextEntry);

  const togglePassword = () => {
    setIsPassword((prev) => !prev);
  };

  const handleBlur = () => {
    isFocused.value = false;
    if (props.value) {
      topValue.value = -10;
      return;
    }
    if (topValue.value === -10) {
      topValue.value = 16;
    }
  };

  const handleFocus = () => {
    isFocused.value = true;
    topValue.value = -10;
  };

  const labelContainerStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      zIndex: 2,
      backgroundColor: '#fff',
      left: 16,
      top: -10,
      paddingHorizontal: 4,
    };
  });

  const labelStyle = useAnimatedStyle(() => ({
    fontSize: withTiming(isFocused.value ? 12 : 14, {
      duration: 100,
      easing: Easing.inOut(Easing.ease),
    }),
    opacity: withTiming(isFocused.value ? 1 : 0.5, {
      duration: 100,
      easing: Easing.inOut(Easing.ease),
    }),
    color: '#777',
  }));

  return (
    <View
      style={{
        borderWidth: 1,
        paddingHorizontal: 16,
        borderRadius: 8,
        borderColor: '#777',
        position: 'relative',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <Animated.View style={labelContainerStyle} pointerEvents="none">
        <Animated.Text style={labelStyle}>
          {label} {required && <Animated.Text style={{ color: 'red' }}>*</Animated.Text>}
        </Animated.Text>
      </Animated.View>
      <TextInput
        ref={ref}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{
          margin: 0,
          padding: 0,
          fontSize: 12,
          paddingVertical: Platform.select({
            android: 12,
            ios: 16,
            web: 16,
          }),
          includeFontPadding: false,
          flex: 1,
        }}
        {...props}
        secureTextEntry={isPassword}
      />
      {props.secureTextEntry && (
        <TouchableOpacity onPress={togglePassword}>
          {isPassword ? (
            <Ionicons name="eye-off-outline" size={20} />
          ) : (
            <Ionicons name="eye-outline" size={20} />
          )}
        </TouchableOpacity>
      )}
    </View>
  );
};

export default AnimatedTextInput;
