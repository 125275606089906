import { USE_EMULATOR } from '@env';
import nativeAuth from '@react-native-firebase/auth';
import { connectAuthEmulator, getAuth } from 'firebase/auth';

import { app } from './app';

// NOTE use this for all auth related functions
export const firebaseNativeAuth = () => {
  const auth = nativeAuth;

  if (__DEV__ && USE_EMULATOR === 'true') {
    auth().useEmulator('http://localhost:9099');
  }

  return auth;
};

export const firebaseWebAuth = () => {
  const auth = getAuth(app);
  // NOTE env vars are type string
  if (__DEV__ && process.env.USE_EMULATOR === 'true') {
    connectAuthEmulator(auth, 'http://127.0.0.1:9099');
  }

  return auth;
};
