import React from 'react';
import { Controller } from 'react-hook-form';
import { View, ScrollView, ActivityIndicator } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import CreateGroupAvatar from '../components/CreateGroupAvatar';
import CreateGroupCover from '../components/CreateGroupCover';
import useCreateGrupForm from '../hooks/useCreateGrupForm';

import { Button } from '@/modules/shared/components/basic/Button';
import Text from '@/modules/shared/components/basic/Text';
import TextInput from '@/modules/shared/components/basic/TextInput';
import { FeatherIcons, FontAwesomeIcons } from '@/modules/shared/libs/icons';
import { cn } from '@/modules/shared/utils/cn';

const CreateGroupScreen = () => {
  const { bottom } = useSafeAreaInsets();
  const { form, submit, isPending } = useCreateGrupForm();

  const isLoading = isPending || form.formState.isSubmitting;

  return (
    <View className="flex-1 bg-white">
      <ScrollView
        className="bg-white px-4 pt-4"
        automaticallyAdjustKeyboardInsets
        contentContainerStyle={{ paddingBottom: 42 }}>
        <View className="border border-neutral-200 rounded-md p-3 space-y-3">
          <View className="space-y-2">
            <Text fontFamily="InterSemiBold" className="font-semibold">
              Nama Group
            </Text>
            <View>
              <Controller
                name="name"
                control={form.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    className={cn('border border-neutral-200 rounded-md text-base leading-5 p-2', {
                      'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                    })}
                    onChangeText={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </View>
            {form.formState.errors.name && (
              <Text className="text-xs text-red-500">{form.formState.errors.name.message}</Text>
            )}
          </View>

          <View className="space-y-2">
            <Text fontFamily="InterSemiBold" className="font-semibold">
              Kota Pendirian
            </Text>
            <View>
              <Controller
                name="city"
                control={form.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    className={cn('border border-neutral-200 rounded-md text-base leading-5 p-2', {
                      'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                    })}
                    onChangeText={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </View>
            {form.formState.errors.city && (
              <Text className="text-xs text-red-500">{form.formState.errors.city.message}</Text>
            )}
          </View>

          <View className="space-y-2">
            <Text fontFamily="InterSemiBold" className="font-semibold">
              Tahun Pendirian
            </Text>
            <View>
              <Controller
                name="year"
                control={form.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    className={cn('border border-neutral-200 rounded-md text-base leading-5 p-2', {
                      'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                    })}
                    keyboardType="numeric"
                    onChangeText={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </View>
            {form.formState.errors.year && (
              <Text className="text-xs text-red-500">{form.formState.errors.year.message}</Text>
            )}
          </View>
        </View>

        <View className="border border-neutral-200 rounded-md p-3 space-y-3 mt-4">
          <View>
            <Controller
              name="profileImg"
              control={form.control}
              render={({ field }) => (
                <CreateGroupAvatar onChange={field.onChange} value={field.value} />
              )}
            />
            {form.formState.errors.profileImg && (
              <Text className="text-xs text-red-500">
                {form.formState.errors.profileImg.message}
              </Text>
            )}
          </View>

          <View>
            <Controller
              name="coverImg"
              control={form.control}
              render={({ field }) => (
                <CreateGroupCover onChange={field.onChange} value={field.value} />
              )}
            />
            {form.formState.errors.coverImg && (
              <Text className="text-xs text-red-500">{form.formState.errors.coverImg.message}</Text>
            )}
          </View>
        </View>

        <View className="border border-neutral-200 rounded-md p-3 space-y-3 mt-4">
          <View className="space-y-2">
            <Text fontFamily="InterSemiBold" className="font-semibold">
              Deskripsi Group
            </Text>
            <View>
              <Controller
                name="description"
                control={form.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    className={cn('border border-neutral-200 rounded-md text-base leading-5 p-2', {
                      'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                    })}
                    onChangeText={field.onChange}
                    value={field.value}
                    multiline
                  />
                )}
              />
            </View>
            {form.formState.errors.description && (
              <Text className="text-xs text-red-500">
                {form.formState.errors.description.message}
              </Text>
            )}
          </View>

          <View className="space-y-2">
            <Text fontFamily="InterSemiBold" className="font-semibold">
              Kontak
            </Text>
            <View>
              <Controller
                name="instagram"
                control={form.control}
                render={({ field, fieldState }) => (
                  <View
                    className={cn(
                      'border border-neutral-200 rounded-md text-base p-2 flex-row items-center',
                      {
                        'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                      },
                    )}>
                    <FeatherIcons name="instagram" size={20} />
                    <TextInput
                      className="leading-4 flex-1 ml-4"
                      onChangeText={field.onChange}
                      value={field.value}
                    />
                  </View>
                )}
              />
              {form.formState.errors.instagram && (
                <Text className="text-xs text-red-500">
                  {form.formState.errors.instagram.message}
                </Text>
              )}
            </View>

            <View>
              <Controller
                name="whatsapp"
                control={form.control}
                render={({ field, fieldState }) => (
                  <View
                    className={cn(
                      'border border-neutral-200 rounded-md text-base p-2 flex-row items-center',
                      {
                        'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                      },
                    )}>
                    <FontAwesomeIcons name="whatsapp" size={20} />
                    <TextInput
                      keyboardType="numeric"
                      className="leading-4 flex-1 ml-4"
                      onChangeText={field.onChange}
                      value={field.value}
                    />
                  </View>
                )}
              />
              {form.formState.errors.whatsapp && (
                <Text className="text-xs text-red-500">
                  {form.formState.errors.whatsapp.message}
                </Text>
              )}
            </View>
          </View>
        </View>
      </ScrollView>

      <View style={{ paddingBottom: bottom }} className="px-4 border-t  border-t-neutral-200 pt-4">
        <Button className="w-full mb-4" size="lg" onPress={submit} disabled={isLoading}>
          <Text fontFamily="InterSemiBold" className="text-white text-base">
            Simpan
          </Text>
          {isLoading && <ActivityIndicator size="small" color="#fff" className="ml-2" />}
        </Button>
      </View>
    </View>
  );
};

export default CreateGroupScreen;
