import { initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';

import { firebaseConfig, firebaseConfigStaging } from './config';

const isEnvProductionWeb = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
const isEnvProductionNative = process.env.EXPO_PUBLIC_CHANNEL === 'production';

//NOTE only use prod when NOT in local AND in web or native production
export const app = initializeApp(
  !__DEV__ && (isEnvProductionNative || isEnvProductionWeb)
    ? firebaseConfig
    : firebaseConfigStaging,
);
export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
  // @ts-ignore
  useFetchStreams: false,
});

console.log('Firebase app initialized', app.options.projectId);
console.log(
  `${
    app.options.projectId === 'playard-indo-staging'
      ? `
███████╗████████╗ █████╗  ██████╗ ██╗███╗   ██╗ ██████╗     ██████╗ ██████╗
██╔════╝╚══██╔══╝██╔══██╗██╔════╝ ██║████╗  ██║██╔════╝     ██╔══██╗██╔══██╗
███████╗   ██║   ███████║██║  ███╗██║██╔██╗ ██║██║  ███╗    ██║  ██║██████╔╝
╚════██║   ██║   ██╔══██║██║   ██║██║██║╚██╗██║██║   ██║    ██║  ██║██╔══██╗
███████║   ██║   ██║  ██║╚██████╔╝██║██║ ╚████║╚██████╔╝    ██████╔╝██████╔╝
╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═╝╚═╝  ╚═══╝ ╚═════╝     ╚═════╝ ╚═════╝
`
      : `
 ██████╗ ██████╗  ██████╗ ██████╗     ██████╗ ██████╗
 ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗    ██╔══██╗██╔══██╗
 ██████╔╝██████╔╝██║   ██║██║  ██║    ██║  ██║██████╔╝
 ██╔═══╝ ██╔══██╗██║   ██║██║  ██║    ██║  ██║██╔══██╗
 ██║     ██║  ██║╚██████╔╝██████╔╝    ██████╔╝██████╔╝
 ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═════╝     ╚═════╝ ╚═════╝
 `
  } `,
);
