import { useNavigation } from '@react-navigation/native';
import { Image } from 'expo-image';
import { DateTime } from 'luxon';
import {
  Pressable,
  View,
  Text,
  TouchableOpacity,
  Dimensions
} from 'react-native';
import {
  HandlerStateChangeEvent,
  State,
  TapGestureHandler,
  TapGestureHandlerEventPayload,
} from 'react-native-gesture-handler';

import LikeIcon from '../assets/like.png';
import LikedIcon from '../assets/liked.png';
import CommentIcon from '../assets/comment.png';
import { LikeState } from '@/modules/shared/api/post';
import { PostData } from '../src/domain/PostResponse';

type PostItemProps = {
    postData: PostData;
    userId: string;
    onLike: (fromDoubleTap: boolean) => void;
    onComment: () => void;
    onClickGroup: () => void;
    onClickUser: () => void;
};

interface PostLikeState {
  state: LikeState | null;
  likesByUsers: string[];
}


export function PostItem({postData, userId, onLike, onComment, onClickGroup, onClickUser }: PostItemProps) {
  const isLiked = postData.likes.some((id: string) => userId == id);

  return <View style = {{ marginBottom: 32 }}>
    <TapGestureHandler onHandlerStateChange={(event: HandlerStateChangeEvent<TapGestureHandlerEventPayload>) => {
      if (event.nativeEvent.state === State.ACTIVE) {
        onLike(true);
      }
    }} numberOfTaps={2}>
      <View className="relative" style = {{ backgroundColor: '#d9d9d9' }}>
        <Image
          source={{ uri: postData.photoUrl }}
          style={{ height: Dimensions.get('screen').width, backgroundColor: '#d9d9d9' }}
          cachePolicy="memory-disk"
          contentFit="cover"
          className="flex"
        />
      </View>
    </TapGestureHandler>

    <View className="pl-4 pr-4">
      <View className="flex-row justify-between items-center" style = {{ marginTop: 8 }}>
        <View className = "flex-row" style = {{ alignContent: 'center', gap: 8}}>
          <TouchableOpacity className= "flex-row items-center"
              onPress= { onComment }>
            <Image source = {CommentIcon} className = "w-6 h-6" cachePolicy="memory"/>
          </TouchableOpacity>
          <View style = {{flexDirection: 'row', alignContent: 'center', gap: 8}} >
            <Pressable onPress= { () => {onLike(false)}}>
              <Image
                source={
                  isLiked ? LikedIcon : LikeIcon
                }
                className="w-6 h-6"
                cachePolicy="memory"
              />
            </Pressable>
            <View style = {{ justifyContent: 'center' }}>
              <Text style = {{ fontFamily: 'InterSemiBold', fontSize: 12, color: '#121212'}}>
                {(postData.likes as string[]).length ?? 0} Menyukai
              </Text>
            </View>
          </View>
        </View>
        <View style = {{ justifyContent: 'center'}}>
          <Text style = {{ fontFamily: 'InterSemiBold', fontSize: 12, color: '#121212'}}>
            {DateTime.fromISO(new Date(postData.createdAt.toDate()).toISOString()).toFormat(
              'dd LLL yyyy',
            )}
          </Text>
        </View>
      </View>

      <TouchableOpacity onPress = { onClickUser }>
        <Text className="text-sm text-[#121212] mt-2">
          <Text style = {{ fontFamily:"InterSemiBold" }}>
            { postData.userDisplayName }
          </Text>{' '}
          {postData.caption}
        </Text>
      </TouchableOpacity>
    </View>

{ postData.lobbyDetails != null && (
  <View className="flex flex-row space-x-[17px]">
      <TouchableOpacity className="flex-col just items-start space-y-[2px]" style = {{ paddingHorizontal: 16, paddingTop: 8, marginTop: 4 }}
        onPress= {onClickGroup}
      >
        <View className= "flex-row items-center" style = {{ gap: 8 }}>
          <Image source={{uri: postData.lobbyDetails?.creatorPhotoUrl}} 
                style = {{ 
                  height: 28, 
                  width: 28, 
                  borderRadius: 8, 
                  borderWidth: 1,
                  borderColor: '#d9d9d9',
                  overflow: 'hidden'
                }}/>
          <View>
            <Text style = {{ fontFamily: "InterSemiBold", fontSize: 12}}>Diambil dari lobi {postData.lobbyDetails?.creatorName}</Text>
            <Text className="text-[#6B7280] font-normal" style = {{ fontFamily: "Inter", fontSize: 12}}>
              {postData.lobbyDetails?.location}
            </Text>
          </View>
          
        </View>
      </TouchableOpacity>
    </View>
)}
    
  </View>
}