import Ionicons from '@expo/vector-icons/Ionicons';
import { useBottomSheet } from '@gorhom/bottom-sheet';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import SelectCityBottomSheetContent from './SelectCityBottomSheetContent';
import PlButton from '../../../components/PlButton';

import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import PickerButton from '@/modules/shared/components/PickerButton';
import { db } from '@/modules/shared/libs/firebase/app';

const preferences: Record<any, any> = {
  monday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  tuesday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  wednesday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  thursday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  friday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  saturday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  sunday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
};

const dayMapping = {
  monday: 'Senin',
  tuesday: 'Selasa',
  wednesday: 'Rabu',
  thursday: 'Kamis',
  friday: 'Jumat',
  saturday: 'Sabtu',
  sunday: 'Minggu',
};

const timeMapping = {
  morning: '8:00-14:00',
  afternoon: '14:00-18:00',
  evening: '18:00-22:00',
};

const PreferencesBottomSheet = ({ userId }: { close?: () => void; userId?: string }) => {
  const [selectedCity, setSelectedCity] = React.useState<string | undefined>();
  const [userPreferences, setUserPreferences] = React.useState<Record<any, any>>(preferences);
  const { close } = useBottomSheet();

  const handleSave = async () => {
    if (!userId) {
      return;
    }
    if (!selectedCity) {
      alert('Pilih kota terlebih dahulu');
      return;
    }
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      preferences: {
        city: selectedCity,
        ...userPreferences,
      },
    });

    close?.();
  };

  useEffect(() => {
    const fetchPref = async () => {
      if (!userId) {
        return;
      }
      const userRef = doc(db, 'users', userId);
      const snapshot = await getDoc(userRef);
      const data = snapshot.data() || {};

      if ('preferences' in data) {
        const { preferences } = data;
        const { city, ...rest } = preferences;
        const desiredOrder = {
          days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
          periods: ['morning', 'afternoon', 'evening'],
        };
        // Helper function to sort time periods within a day
        const sortedData = Object.fromEntries(
          Object.entries(rest).sort((a, b) => {
            return desiredOrder.days.indexOf(a[0]) - desiredOrder.days.indexOf(b[0]);
          }),
        );

        // Update the order of time periods
        for (const day in sortedData) {
          sortedData[day] = Object.fromEntries(
            //@ts-ignore
            Object.entries(sortedData[day]).sort((a, b) => {
              const timePeriodsOrder = ['morning', 'afternoon', 'evening'];
              return timePeriodsOrder.indexOf(a[0]) - timePeriodsOrder.indexOf(b[0]);
            }),
          );
        }

        setSelectedCity(city);
        setUserPreferences(sortedData);
      }
    };

    fetchPref();
  }, [userId]);

  return (
    <View style={{ flex: 1 }}>
      <View style={{ padding: 20 }}>
        <Text style={{ fontSize: 18, fontWeight: '700' }}>Atur Preferensi</Text>
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingHorizontal: 20 }}>
        <Image
          source={require('../../../assets/pref.png')}
          style={{ width: 260, height: 250, resizeMode: 'contain', alignSelf: 'center' }}
          resizeMode="contain"
        />
        <Text style={{ textAlign: 'center', color: '#565656', marginBottom: 32 }}>
          Pilih preferensi kamu agar lobi dapat dibuka sesuai keinginan kamu. Kamu bisa ganti
          preferensi kapanpun dari halaman profil kamu.
        </Text>
        <PickerButton
          Icon={<Ionicons name="business-outline" size={20} color="#777" />}
          placeholder="Pilih Kota"
          label="Kota"
          textValue={selectedCity}
          onPress={() => {
            DynamicBottomSheetService.show({
              content: ({ close: closeDynamic }) => (
                <SelectCityBottomSheetContent
                  close={closeDynamic}
                  onChange={setSelectedCity}
                  value={selectedCity as any}
                />
              ),
              bottomSheetProps: {
                enableBackdropPress: false,
              },
            });
          }}
        />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginVertical: 20,
          }}>
          <View style={{ flex: 1 }} />
          {['morning', 'afternoon', 'evening'].map((item) => (
            <View style={{ flex: 1, alignItems: 'center', paddingHorizontal: 4 }} key={item}>
              <View
                style={{
                  backgroundColor: 'rgba(147, 120, 255, 0.09)',
                  paddingVertical: 8,
                  borderRadius: 99,
                  width: '100%',
                }}>
                <Text style={{ fontSize: 10, color: '#3A3745', textAlign: 'center' }}>
                  {timeMapping[item as keyof typeof timeMapping]}
                </Text>
              </View>
            </View>
          ))}
        </View>

        {Object.entries(userPreferences).map(([day, times]) => (
          <View
            key={day}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 20,
            }}>
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: 14, fontWeight: '700' }}>
                {dayMapping[day as keyof typeof dayMapping]}
              </Text>
            </View>
            {Object.entries(times).map(([time, value]) => (
              <View key={time} style={{ alignItems: 'center', flex: 1 }}>
                <TouchableOpacity
                  onPress={() => {
                    const clone = { ...userPreferences };
                    clone[day][time] = !value;
                    setUserPreferences(clone);
                    //  preferences[day][time] = !value;
                  }}
                  style={{
                    width: 24,
                    height: 24,
                    borderWidth: 1,
                    borderRadius: 12,
                    borderColor: '#888',
                    backgroundColor: value ? '#5F48B9' : 'white',
                  }}
                />
              </View>
            ))}
          </View>
        ))}
      </ScrollView>
      <View
        style={[
          {
            padding: 16,
            backgroundColor: 'white',
            shadowColor: '#ABABAB',
            shadowOffset: {
              width: 0,
              height: -2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            paddingBottom: Platform.select({
              web: 64,
            }),
          },
        ]}>
        <PlButton
          title="Simpan Preferensi"
          customStyle={{ borderRadius: 8 }}
          onPress={handleSave}
        />
      </View>
    </View>
  );
};

export default PreferencesBottomSheet;
