import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import React from 'react';
import { Text } from 'react-native';

import MainRoute from './MainRoute';
import { navigationRef } from './RootNavigation';
import { RootStackParamList, UnauthorizedStackParamList } from './types';
import BottomSheet from '../shared/components/BottomSheet/BottomSheet';
import BottomSheetService from '../shared/components/BottomSheet/BottomSheetService';
import DynamicBottomSheet from '../shared/components/DynamicBottomSheet/DynamicBottomSheet';
import DynamicBottomSheetService from '../shared/components/DynamicBottomSheet/DynamicBottomSheetService';

const prefix = Linking.createURL('playard');
const linking: LinkingOptions<RootStackParamList & UnauthorizedStackParamList> = {
  prefixes: [prefix, 'https://app.playard.id', 'https://playard.id'],
  config: {
    screens: {
      WelcomeScreen: 'welcome',
      CreateLobbyScreen: 'create-lobby',
      HomeTabs: {
        screens: {
          Home: 'home',
          Search: 'search',
          Profile: 'profile',
        },
      },
      Lobby: 'lobby/:lobbyId',
      LoginScreen: 'login',
      RegisterScreen: 'register',
      ProfileScreen: 'profile/:uid',
      CreateGroupScreen: 'create-group',
      InAppBrowserErrorScreen: 'in-app-browser-error',
      GroupDetailScreen: 'group/:groupId',
      GroupPostDetailScreen: 'post/:postId',
      GroupPostCommentScreen: 'post/:postId/comment',
      LobbyListScreen: 'home/lobbyList'
    },
  },
};

export const MainNavigation = () => {
  return (
    <>
      <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>} ref={navigationRef}>
        <MainRoute />
      </NavigationContainer>
      <DynamicBottomSheet ref={(ref) => DynamicBottomSheetService.setBottomSheet(ref)} />
      <BottomSheet ref={(ref) => BottomSheetService.setBottomSheet(ref)} />
    </>
  );
};

export default MainNavigation;
