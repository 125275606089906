import { doc, getDoc } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';

import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { db } from '@/modules/shared/libs/firebase/app';
import { type Lobby, Creator } from '@/modules/shared/types';
import { PostData } from 'src/domain/PostResponse';
import { getPostByID } from '@/modules/shared/api/post';
import { LobbyResponse } from 'src/domain/LobbyResponse';

type UserDetail = {
  followers: string[];
  following: string[];
  joinedLobbies: string[];
  photoURL: string;
  displayName: string;
  posts: string[];
};

export type UserActivity = {
  lobby: Lobby;
  lobbyId: string; 
  post?: PostData
}

const useUserActivities = (userId?: string) => {
  const { currentUser } = useAuth();
  const [activities, setActivities] = useState<UserActivity[]>([]);

  const fetchActivity = useCallback(async () => {
    if (!currentUser) return;

    const userRef = doc(db, 'users', userId ? userId : currentUser?.uid);
    const snapshot = await getDoc(userRef);
    const data = (snapshot.data() || null) as UserDetail | null;
    const userLobbies = data?.joinedLobbies ?? [];
    const userPosts = data?.posts ?? [];

    const userPostsData = await Promise.all(
      userPosts.map(async (post) => {
        const resultPost = await getPostByID(post);
        return {
          ...resultPost,
          id: post
        }
      })
    );
    const activities = await Promise.all(
      userLobbies.map(async (lobbyId) => {
        const lobbyRef = doc(db, 'lobbies', lobbyId);
        const lobbyDoc = await getDoc(lobbyRef);
        const lobbyData = lobbyDoc.data();
        console.log('lobbyData is ', lobbyData);
        return {
          lobby: {
            ...lobbyData,
            id: lobbyId
          } as Lobby,
          lobbyId: lobbyId,
          post: userPostsData.find( (post) => (post.lobbyId ?? '') == lobbyId)
        } as UserActivity;
      }),
    );

    console.log('activities are ', activities);
    setActivities(activities);
  }, [currentUser, userId]);

  useEffect(() => {
    fetchActivity();
  }, [fetchActivity]);

  return activities;
};

export default useUserActivities;
