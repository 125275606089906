import React, { Component } from 'react';
import {
  Pressable,
  Text,
  StyleSheet,
  View,
  ActivityIndicator,
  GestureResponderEvent,
} from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#5F48B9',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    justifyContent: 'center',
    fontWeight: '700',
    marginVertical: 16,
  },
});

type PlButtonProps = {
  title: string;
  onPress?: (e?: GestureResponderEvent) => void;
  type?: 'normal' | 'rounded' | 'square' | 'secondary' | 'danger' | 'warn' | 'black';
  customStyle?: any | null;
  textVerticalMargin?: number | null;
  fontSize?: number;
  Icon?: () => JSX.Element;
  isLoading?: boolean;
};

class PlButton extends Component<PlButtonProps> {
  getBorderRadius = () => {
    switch (this.props.type) {
      case 'normal':
        return 30;
      case 'warn':
        return 30;
      case 'secondary':
        return 30;
      case 'rounded':
        return 30;
      case 'danger':
        return 30;
      case 'black':
        return 30;
    }
  };
  getBgColour = () => {
    if (!this.props.type) {
      return '#5F48B9';
    }
    switch (this.props.type) {
      case 'normal':
        return '#5F48B9';
      case 'secondary':
        return '#f4f4f4';
      case 'warn':
        return '#5F48B9';
      case 'danger':
        return '#FF0000';
      case 'rounded':
        return '#5F48B9';
      case 'square':
        return '#f4f4f4';
      case 'black':
        return '#000';
    }
  };

  render() {
    const Icon = this.props.Icon;
    const isLoading = this.props.isLoading ?? false;

    return (
      <Pressable
        disabled={isLoading}
        onPress={this.props.onPress}
        style={(state) => ({
          ...styles.container,
          backgroundColor: this.getBgColour(),
          borderRadius: this.getBorderRadius(),
          opacity: state.pressed ? 0.8 : 1,
          flexDirection: 'row',
          ...((!!this.props.customStyle && this.props.customStyle) ?? {}),
          position: 'relative',
          overflow: 'hidden',
        })}>
        {!!Icon && <Icon />}
        <Text
          style={{
            ...styles.text,
            fontSize: this.props.fontSize ?? 16,
            color: this.props.type === 'secondary' ? '#777' : 'white',
            marginVertical: this.props.textVerticalMargin ?? 16,
            marginLeft: 4,
          }}>
          {this.props.title}
        </Text>
        {isLoading && (
          <View
            style={{
              ...StyleSheet.absoluteFillObject,
              backgroundColor: 'rgba(255,255,255,0.8)',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <ActivityIndicator color="#5F48B9" />
          </View>
        )}
      </Pressable>
    );
  }
}

export default PlButton;
