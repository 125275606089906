import { useRoute } from '@react-navigation/native';
import { KeyboardAvoidingView, Platform, View} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { CommentInputField } from './GroupPostDetailScreen';
import { useQueryGetPostComments } from '../hooks/useQueryGroupPost';

import { RouteProp } from '@/modules/navigation/types';
import { FlatList } from 'react-native-gesture-handler';
import { renderComment } from './GroupPostDetailScreen';

const GroupPostCommentScreen = () => {
  const { params } = useRoute<RouteProp<'GroupPostCommentScreen'>>();
  const { postId } = params;

  const {  goToProfile } = params;
  const { data: response } = useQueryGetPostComments(postId);

  // TODO sort out types
  return (
    <View className="flex-1 bg-white">
      <SafeAreaView className="flex-1 bg-white" edges={['bottom']} style = {{ marginTop: 24 }}>
        <FlatList
          data= {response?.comments ?? [] }
          keyExtractor={ (comment) => comment.uid }
          renderItem={ (item) => {
            return renderComment(item.item, goToProfile);
          }}
        />
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          keyboardVerticalOffset={120}>
          <CommentInputField postId={postId} />
        </KeyboardAvoidingView>
      </SafeAreaView>
    </View>
  );
};
export default GroupPostCommentScreen;
