import { useQuery } from '@tanstack/react-query';

import { getUserDetailGroup } from '@/modules/shared/api/group';
import { K_USER_GROUP_DETAIL_QUERY_KEY } from '@/modules/shared/constants/react-query';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

const useQueryUserGroup = () => {
  const { currentUser } = useAuth();
  return useQuery({
    queryKey: [K_USER_GROUP_DETAIL_QUERY_KEY, currentUser?.uid],
    queryFn: () => getUserDetailGroup(String(currentUser?.uid)),
    enabled: !!currentUser?.uid,
  });
};

export default useQueryUserGroup;
