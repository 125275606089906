import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { DocumentData } from 'firebase/firestore';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Modal, View } from 'react-native';

import { AuthContext } from './AuthContext';
import { createOrUpdateFirebaseUser, getUserById } from '../../../../firebase';
import { checkInAppBrowser } from '../../../../utils/checkInAppBrowser';
import { UserDetail } from '../../types';

import { firebaseWebAuth } from '@/modules/shared/libs/firebase/auth';

const auth = firebaseWebAuth();

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [isInAppBrowser, setInAppBrowser] = useState(false);

  // TODO types
  const [currentUser, setCurrentUser] = useState<DocumentData | UserDetail | null>();

  const signInGoogle = useCallback(async () => {
    try {
      setLoading(true);

      const provider = new GoogleAuthProvider();

      if (window.location.href === 'playard-indo.firebaseapp.com') {
        await signInWithRedirect(auth, provider);
      } else {
        await signInWithPopup(auth, provider);
      }
    } catch (error) {
      alert('Gagal Login');
    } finally {
      setLoading(false);
    }
  }, []);

  const signInWithApple = useCallback(async () => {}, []);

  const _signInWithEmailAndPassword = useCallback(async (email: string, password: string) => {
    try {
      setLoading(true);
      const user = await signInWithEmailAndPassword(auth, email, password);
      const userData = await createOrUpdateFirebaseUser(user.user as UserDetail);
      setCurrentUser(userData);
    } catch (error) {
      alert('Gagal Login');
    } finally {
      setLoading(false);
    }
  }, []);

  const registerWithEmailAndPassword = useCallback(async (email: string, password: string) => {
    try {
      setLoading(true);
      const user = await createUserWithEmailAndPassword(auth, email, password);
      const userData = await createOrUpdateFirebaseUser(user.user as UserDetail);
      setCurrentUser(userData);
    } catch (error) {
      alert('Gagal Register');
    } finally {
      setLoading(false);
    }
  }, []);

  const _signOut = useCallback(async () => {
    try {
      setLoading(true);
      await Promise.all([signOut(auth)]);
      setCurrentUser(null);
    } catch (error) {
      alert('Gagal Logout');
    } finally {
      setLoading(false);
    }
  }, []);

  const refetchUserData = useCallback(async (userId: string) => {
    try {
      setLoading(true);
      console.log('loadinggggg');
      const nowUser = await getUserById(userId);
      console.log('user fetched is ', nowUser);
      setCurrentUser(nowUser as UserDetail);
    } catch (error) {
      alert('Gagal mengulang data');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setInAppBrowser(checkInAppBrowser());
    const subscriber = onAuthStateChanged(auth, async (user) => {
      const userData = await createOrUpdateFirebaseUser(user as UserDetail);
      setCurrentUser(userData);
      if (initializing) setInitializing(false);
    });

    return subscriber;
  }, []);

  const value = useMemo(
    () => ({
      loading,
      setLoading,
      currentUser,
      signInGoogle,
      signInWithEmailAndPassword: _signInWithEmailAndPassword,
      registerWithEmailAndPassword,
      signInWithApple,
      signOut: _signOut,
      initializing,
      isInAppBrowser,
      setCurrentUser,
      refetchUserData
    }),
    [
      loading,
      setLoading,
      currentUser,
      signInGoogle,
      _signInWithEmailAndPassword,
      registerWithEmailAndPassword,
      signInWithApple,
      _signOut,
      initializing,
      isInAppBrowser,
      setCurrentUser,
      refetchUserData
    ],
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
      <Modal visible={loading} transparent>
        <View className="flex-1 bg-slate-400/80 items-center justify-center">
          <ActivityIndicator size="large" color="#5F48B9" />
        </View>
      </Modal>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
