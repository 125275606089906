import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Image } from 'expo-image';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import PlButton from '../components/PlButton';
import { s } from '../styles';

import { RootStackParamList, UnauthorizedStackParamList } from '@/modules/navigation/types';
import { assetList } from '@/modules/shared/types';

const blurhash =
  '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';
export const WelcomeScreen = () => {
  const navigation =
    useNavigation<NavigationProp<UnauthorizedStackParamList & RootStackParamList>>();

  // const { data } = useFetchUserData();

  const handleLogin = () => {
    navigation.navigate('LoginScreen');
  };
  const handleRegister = () => {
    navigation.navigate('RegisterScreen');
  };

  // useEffect(() => {
  //   if (!data) return;

  //   if (data.username) {
  //     navigation.reset({
  //       index: 0,
  //       routes: [{ name: 'HomeTabs' }],
  //     });
  //   }

  //   if (!data.username) {
  //     navigation.reset({
  //       index: 0,
  //       routes: [
  //         {
  //           name: 'EditProfileScreen',
  //           params: {
  //             canBack: false,
  //           },
  //         },
  //       ],
  //     });
  //   }
  // }, [data]);

  return (
    <SafeAreaView style={styles.container}>
      <Image
        style={styles.image}
        source={assetList.LANDING_ASSET}
        placeholder={blurhash}
        contentFit="contain"
        transition={200}
      />

      <View style={styles.subContainer}>
        <View style={{ flex: 1, alignItems: 'center' }}>
          <Text style={{ ...s.lobbyTitleText, padding: 8, fontSize: 18 }}>Jalani Lifestyle Yang Aktif</Text>
          <Text style={{ color: '#565656', fontWeight: '500', fontFamily: 'Inter', fontSize: 14, lineHeight: 30, padding: 8, textAlign: 'center' }}>
            Bergabung dalam lobi untuk bermain bersama dan bertemu teman olahraga baru di kota kamu
          </Text>
        </View>
      </View>

      <View style={{ alignSelf: 'stretch', padding: 20, marginBottom: 40 }}>
        <PlButton onPress={handleRegister} type="normal" title="Daftar Sekarang" />

        <View style={{ marginTop: 30 }}>
          <Text style={{ textAlign: 'center' }}>
            Sudah Punya akun?{' '}
            <Text style={{ color: '#5F48B9' }} onPress={handleLogin}>
              Log In
            </Text>
          </Text>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 129,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subContainer: {
    textAlign: 'center',
    flex: 1,
    flexDirection: 'column',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    alignSelf: 'stretch',
  },

  image: {
    flex: 2,
    width: '80%',
  },
});
export default WelcomeScreen;
