import { zodResolver } from '@hookform/resolvers/zod';
import { StackActions, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Image } from 'expo-image';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Text,
  View,
  TouchableOpacity,
  Keyboard,
  ActivityIndicator,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  Alert,
  Dimensions,
} from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { z } from 'zod';

import SelectBrandShoes from './components/SelectBrandName';
import SelectItemCategoryBottomSheetContent from './components/SelectCategoryOfItem';
import ScreenContainer from '../../components/ScreenContainer';
import { addItemForWarehouse } from '../../firebase';
import { areAllValuesNotEmpty } from '../lobby/utils/isThereAnyEmptyValues';

import { RootStackParamList } from '@/modules/navigation/types';
import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import TextInput from '@/modules/shared/components/basic/TextInput';
import useImagePicker from '@/modules/shared/hooks/useImagePicker';
import { Ionicons } from '@/modules/shared/libs/icons';
import { cn } from '@/modules/shared/utils/cn';

const schema = z.object({
  itemName: z.string(),
  description: z.string(),
  category: z.string(),
  brand: z.string(),
  level: z.string(),
  Euro: z.string(),
  US: z.string(),
  CM: z.string(),
});
type Schema = z.infer<typeof schema>;
type Props = NativeStackScreenProps<RootStackParamList, 'AddItem'>;
const AddItem = ({ route }: Props) => {
  const { control, handleSubmit, setValue, watch } = useForm<Schema>({
    defaultValues: {
      itemName: '',
      description: '',
      category: '',
      brand: '',
      level: '',
      Euro: '',
      US: '',
      CM: '',
    },
    resolver: zodResolver(schema),
  });

  const us = watch('US');
  const euro = watch('Euro');
  const cm = watch('CM');

  const [images, setImages] = useState([]);
  const navigation = useNavigation();

  const { pickImage, loading } = useImagePicker();

  const handlePress = async () => {
    console.log('pres');
    const image = await pickImage();
    if (image) {
      setImages((prev) => [...prev, image]);
    }
  };

  const handleAddItem = async (data: any) => {
    try {
      const newData = { ...data, images, warehouseId: route.params.warehouseId };
      const isAllFilled = areAllValuesNotEmpty(newData, ['description']);
      if (isAllFilled) {
        const result = await addItemForWarehouse(newData, route.params.warehouseId);
        if (result) {
          navigation.dispatch(StackActions.pop(2));
          Alert.alert('Berhasil menambahkan item ke gudang');
        }
      } else {
        Alert.alert('Anda harus melengkapi data yang kosong');
      }
    } catch (_) {
      Alert.alert('Sepertinya ada kesalahan');
    }
  };

  const handleShowCategory = useCallback(() => {
    Keyboard.dismiss();
    DynamicBottomSheetService.show({
      content: ({ close }) => (
        <SelectItemCategoryBottomSheetContent
          close={close}
          value={watch('category')}
          onChange={(c) => setValue('category', c)}
        />
      ),
    });
  }, []);

  const handleShowBrandShoes = useCallback(() => {
    Keyboard.dismiss();
    DynamicBottomSheetService.show({
      content: ({ close }) => (
        <SelectBrandShoes
          close={close}
          value={watch('brand')}
          onChange={(c) => setValue('brand', c)}
        />
      ),
    });
  }, []);
  const styles = {
    container: 'flex-row items-center mb-2 p-5 self-center justify-between',
    label: 'text-lg font-bold pl-1',
    input: 'w-24 h-12 rounded-md border border-gray-300 p-2 m-2 mt-4',
  };

  const handleShowFootSize = useCallback(() => {
    Keyboard.dismiss();
    DynamicBottomSheetService.show({
      content: ({ close }) => (
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          keyboardVerticalOffset={Dimensions.get('screen').height / 4}>
          <TouchableWithoutFeedback
            onPress={() => Keyboard.dismiss()}
            style={{
              paddingHorizontal: 16,
              marginBottom: 32,
              flex: 1,
              height: 350,
            }}>
            <View>
              <Text style={{ fontSize: 20, fontWeight: '700' }}>Ukuran Sepatu</Text>
              <View className={styles.container}>
                <View>
                  <Text className={styles.label}>EUR</Text>
                  <Controller
                    control={control}
                    name="Euro"
                    render={({ field, fieldState }) => (
                      <TextInput
                        placeholder=""
                        keyboardType="numeric"
                        className={cn(styles.input, {
                          'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                        })}
                        onChangeText={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                </View>
                <View>
                  <Text className={styles.label}>US</Text>
                  <Controller
                    control={control}
                    name="US"
                    render={({ field, fieldState }) => (
                      <TextInput
                        placeholder=""
                        keyboardType="numeric"
                        className={cn(styles.input, {
                          'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                        })}
                        onChangeText={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                </View>
                <View>
                  <Text className={styles.label}>CM</Text>
                  <Controller
                    control={control}
                    name="CM"
                    render={({ field, fieldState }) => (
                      <TextInput
                        placeholder=""
                        keyboardType="numeric"
                        className={cn(styles.input, {
                          'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                        })}
                        onChangeText={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
          <TouchableOpacity
            className="bg-[#5F48B9] p-3 rounded-lg items-center absolute bottom-0 w-11/12 self-center py-4"
            onPress={() => {
              const us = watch('US');
              const euro = watch('Euro');
              const cm = watch('CM');
              if (us === '' || euro === '' || cm === '') {
                Alert.alert('Ukuran Sepatu Tidak boleh kosong');
              }
              close();
            }}>
            <Text className="text-white text-base font-bold">Simpan</Text>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      ),
    });
  }, [watch, control]);

  const categoryValue = watch('category');
  return (
    <ScreenContainer className="bg-white" safeAreaTop={false}>
      <ScrollView className="flex-1 pl-4 pr-4 pb-12 mb-8">
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <View className="bg-white rounded-lg p-4 mb-5 border border-gray-300">
            <Text className="text-sm font-semibold py-2">Nama Barang</Text>
            <View>
              <Controller
                control={control}
                name="itemName"
                render={({ field, fieldState }) => (
                  <TextInput
                    placeholder=""
                    className={cn('border border-neutral-300 rounded-md text-base leading-5 p-3', {
                      'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                    })}
                    onChangeText={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </View>
            <Text className="text-sm font-semibold py-2">Deskripsi Barang</Text>
            <View>
              <Controller
                control={control}
                name="description"
                render={({ field, fieldState }) => (
                  <TextInput
                    placeholder=""
                    multiline
                    numberOfLines={4}
                    className={cn(
                      'border border-neutral-300 rounded-md text-base leading-5 p-3 h-20',
                      {
                        'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                      },
                    )}
                    onChangeText={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </View>

            <Text className="text-sm font-semibold py-2">Kategori</Text>
            <View>
              <Controller
                control={control}
                name="category"
                render={({ field, fieldState }) => (
                  <TouchableWithoutFeedback onPress={handleShowCategory}>
                    <TextInput
                      editable={false}
                      placeholder=""
                      className={cn(
                        'border border-neutral-300 rounded-md text-base leading-5 p-3',
                        {
                          'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                        },
                      )}
                      onChangeText={field.onChange}
                      value={field.value}
                    />
                  </TouchableWithoutFeedback>
                )}
              />
            </View>
          </View>
          <View className="bg-white rounded-lg p-4 mb-5 border border-gray-300">
            <Text className="text-lg font-bold mb-2">Foto Barang</Text>

            <ScrollView
              scrollEnabled={images.length > 4}
              className="w-full h-20 pl-2 pt-4 border border-gray-300 rounded-lg bg-[#F3f3f3] flex-row space-x-3 align-center"
              horizontal>
              {loading && <ActivityIndicator size="small" />}
              {images.length > 0 &&
                images.map((item: string, index: number) => (
                  <Image
                    key={index}
                    source={{ uri: item }}
                    className="w-[50px] h-[50px] object-cover rounded-md"
                  />
                ))}
              <TouchableOpacity
                onPress={handlePress}
                disabled={loading}
                className="w-12 h-12 border border-gray-300 border-dashed justify-center items-center rounded-lg bg-[#F3f3f3]">
                <Ionicons name="add" size={24} color="gray" />
              </TouchableOpacity>
            </ScrollView>
          </View>
          {categoryValue === 'Sepatu' && (
            <>
              <View className="bg-white rounded-lg p-4 mb-5 border border-gray-300">
                <Text className="text-sm font-semibold py-2">Kondisi Barang</Text>
                <View>
                  <Controller
                    control={control}
                    name="level"
                    render={({ field, fieldState }) => (
                      <TextInput
                        placeholder="0/10"
                        keyboardType="number-pad"
                        className={cn(
                          'border border-neutral-300 rounded-md text-base leading-5 p-3',
                          {
                            'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                          },
                        )}
                        onChangeText={(value) => {
                          let numericValue = value.replace(/[^0-9]/g, '');

                          if (numericValue.startsWith('0') && numericValue !== '0') {
                            numericValue = '';
                          }
                          const intValue = parseInt(numericValue, 10);
                          if (numericValue === '' || (intValue >= 0 && intValue <= 10)) {
                            field.onChange(numericValue);
                          } else {
                            field.onChange(field.value);
                          }
                        }}
                        value={field.value}
                      />
                    )}
                  />
                </View>
                <Text className="text-sm font-semibold py-2">Brand Sepatu</Text>
                <View>
                  <Controller
                    control={control}
                    name="brand"
                    render={({ field, fieldState }) => (
                      <TouchableWithoutFeedback onPress={handleShowBrandShoes}>
                        <TextInput
                          editable={false}
                          placeholder=""
                          className={cn(
                            'border border-neutral-300 rounded-md text-base leading-5 p-3',
                            {
                              'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                            },
                          )}
                          onChangeText={field.onChange}
                          value={field.value}
                        />
                      </TouchableWithoutFeedback>
                    )}
                  />
                </View>
                <Text className="text-sm font-semibold py-2">Ukuran Sepatu</Text>
                <View>
                  <Controller
                    control={control}
                    name=""
                    render={({ field, fieldState }) => (
                      <TouchableWithoutFeedback onPress={handleShowFootSize}>
                        <TextInput
                          editable={false}
                          placeholder=""
                          className={cn(
                            'border border-neutral-300 rounded-md text-base leading-5 p-3',
                            {
                              'border-red-500': fieldState.invalid || !!fieldState.error?.message,
                            },
                          )}
                          value={euro && cm && us ? `${euro} Eur / ${us} US / ${cm} cm` : ''}
                        />
                      </TouchableWithoutFeedback>
                    )}
                  />
                </View>
              </View>
            </>
          )}
        </KeyboardAvoidingView>
      </ScrollView>
      {categoryValue !== '' && (
        <TouchableOpacity
          className="bg-[#5F48B9] p-3 rounded-lg items-center absolute bottom-4 w-11/12 self-center"
          onPress={handleSubmit(handleAddItem)}>
          <Text className="text-white text-base font-bold">Tambahkan</Text>
        </TouchableOpacity>
      )}
    </ScreenContainer>
  );
};

export default AddItem;
