import { useQuery } from '@tanstack/react-query';

import { getAllGroupLobbies } from '@/modules/shared/api/lobby';
import { K_GROUP_LOBBIES_QUERY_KEY } from '@/modules/shared/constants/react-query';

const useQueryGroupLobbies = (id?: string, fetchAll = false) => {
  return useQuery({
    queryKey: [K_GROUP_LOBBIES_QUERY_KEY, id, fetchAll],
    queryFn: () => getAllGroupLobbies(String(id)),
    enabled: !!id,
  });
};

export default useQueryGroupLobbies;
