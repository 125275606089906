import Ionicons from '@expo/vector-icons/Ionicons';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import PreferencesBottomSheet from './PreferencesBottomSheet';

import BottomSheetService from '@/modules/shared/components/BottomSheet/BottomSheetService';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';

const PreferencesButton = () => {
  const auth = useAuth();
  return (
    <TouchableOpacity
      onPress={() => {
        BottomSheetService.show({
          content: ({ close }) => (
            <PreferencesBottomSheet close={close} userId={auth.currentUser?.uid} />
          ),
          bottomSheetProps: {
            snapPoints: ['90%'],
            keyboardBehavior: 'extend',
          },
        });
      }}>
      <Ionicons name="md-calendar-outline" size={24} color="black" />
    </TouchableOpacity>
  );
};

export default PreferencesButton;
