import React, { memo } from 'react';
import { View, Text } from 'react-native';

import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';
import Tag from '@/modules/shared/components/Tag/Tag';

interface Credit {
  tag: string;
  count: number;
}

interface TagListProps {
  amountOfCredits: Credit[];
  user: string;
}

const TagList: React.FC<TagListProps> = ({ amountOfCredits, user }) => {
  const handlePress = () => {
    DynamicBottomSheetService.show({
      content: ({ close }) => (
        <View className="pl-2  pr-2 ">
          <View className="justify-center mb-4 border-b border-b-gray-200">
            <Text className="font-semibold text-xl text-center pb-4 ">Kredit {user}</Text>
          </View>
          <View className="flex-row flex-wrap ">
            {amountOfCredits.map((item, idx) => (
              <Tag key={idx} item={item.tag + ' (' + item.count + ')'} />
            ))}
          </View>
        </View>
      ),
    });
  };
  const creditCloned = [...amountOfCredits];
  const finalCredit = creditCloned.length > 2 ? creditCloned.slice(0, 2) : creditCloned;

  return (
    <View className="flex-row flex-wrap">
      {finalCredit.map((item, idx) => (
        <Tag key={idx} onPress={handlePress} item={item.tag + ' (' + item.count + ')'} />
      ))}
    </View>
  );
};

export default memo(TagList);
