export const K_GROUPS_QUERY_KEY = 'all_groups';
export const K_FOLLOWED_GROUPS_QUERY_KEY = 'all_followed_groups';
export const K_GROUP_DETAIL_QUERY_KEY = 'group_detail';
export const K_LOBBY_DETAIL_QUERY_KEY = 'lobby_detail';
export const K_USER_GROUP_DETAIL_QUERY_KEY = 'user_group_detail';
export const K_GROUP_LOBBIES_QUERY_KEY = 'group_lobbies';
export const K_POST_GET_USERS = 'post_get_users';
export const K_POST_GET_LAST_LOBBY = 'post_get_last_lobby';
export const K_POST_GET_USER_LAST_LOBBY = 'post_get_user_last_lobby';
export const K_POST_GET_POSTS = 'post_get_posts';
export const K_POST_GET_DETAIL = 'post_get_detail';
export const K_POST_GET_COMMENTS = 'post_get_comments';
export const K_POST_GET_LOBBY = 'post_get_lobby';
export const K_POST_GET_BATCH_USERS = 'post_get_batch_userS';
export const K_USER_DETAIL = 'user_detail';
