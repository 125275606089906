import { zodResolver } from '@hookform/resolvers/zod';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Toast from 'react-native-root-toast';

import useNeedRefetch from './useNeedRefetch';
import { GetAllUserParameter, getLobbyById } from '../../../firebase';

import {
  PostSchema,
  createComment,
  createPost,
  createPostSchema,
  getAllUser,
  getCommentsByIds,
  getLastLobby,
  getUserLastLobby,
  getPostByID,
  getPosts,
  getUserByIds,
  sendPostPushNotification,
  updateLike,
} from '@/modules/shared/api/post';
import {
  K_POST_GET_BATCH_USERS,
  K_POST_GET_COMMENTS,
  K_POST_GET_DETAIL,
  K_POST_GET_LAST_LOBBY,
  K_POST_GET_USER_LAST_LOBBY,
  K_POST_GET_LOBBY,
  K_POST_GET_POSTS,
  K_POST_GET_USERS,
} from '@/modules/shared/constants/react-query';
import { useAuth } from '@/modules/shared/context/auth/AuthContext';
import { saveNotificationToUsers } from '@/modules/shared/api/pushNotifications';
import { serverTimestamp } from 'firebase/firestore';

export const useQueryGetusers = (parameter?: GetAllUserParameter) => {
  const { currentUser } = useAuth();
  return useQuery({
    queryKey: [K_POST_GET_USERS, currentUser?.uid],
    queryFn: () => getAllUser(parameter),
    enabled: !!currentUser?.uid,
  });
};

export const useQueryGetLastLobby = (groupId?: string) => {
  const { currentUser } = useAuth();
  if (groupId != null) {
    return useQuery({
      queryKey: [K_POST_GET_LAST_LOBBY, groupId],
      queryFn: () => getLastLobby(groupId),
    });
  } else {
    const lasLobby = useQuery({
      queryKey: [K_POST_GET_USER_LAST_LOBBY, groupId],
      queryFn: () => getUserLastLobby(currentUser?.uid ?? '')
    })
    return lasLobby;
  }
};

export const useQueryGetPosts = (groupID: string) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: [K_POST_GET_POSTS, groupID],
    queryFn: ({ pageParam }) => {
      //@ts-ignore:disable-next-line
      return getPosts(groupID, pageParam.cursor);
    },
    initialPageParam: {},
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.cursor) {
        return {
          cursor: lastPage.cursor,
        };
      }
    },
  });

  return infiniteQuery;
};

export const useCreatePostForm = (groupId?: string) => {
  const { mutateAsync, isPending } = useMutateCreatePost();
  const { currentUser } = useAuth();
  const form = useForm<PostSchema>({
    resolver: zodResolver(createPostSchema),
    defaultValues: {
      caption: '',
      photoUrl: '',
      lobbyId: '',
      groupId: groupId ?? '',
      tagPlayerIDs: [],
      tagPlayerPushTokens: [],
      userId: currentUser?.uid ?? '',
    },
  });

  const submit = form.handleSubmit(async (data) => {
    console.log('submit', data);
    await mutateAsync(data);
  });

  return { form, submit, isPending };
};
export const useMutateCreateComment = (postId: string, userId?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['createComment', postId, userId || '0'],
    mutationFn: createComment,
    onError: () => {
      Toast.show('Gagal membuat komentar', {
        backgroundColor: '#ef4444',
        textColor: '#fef2f2',
        opacity: 1,
      });
    },
    onSuccess: () => {
      Toast.show('Berhasil membuat komentar', {
        backgroundColor: '#22c55e',
        textColor: '#f0fdf4',
        opacity: 1,
      });
      queryClient.invalidateQueries({
        queryKey: [K_POST_GET_DETAIL, K_POST_GET_COMMENTS, K_POST_GET_LOBBY],
      });
    },
  });
};
const useMutateCreatePost = () => {
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const setNeedRefetch = useNeedRefetch((state) => state.setNeedRefetch);

  return useMutation({
    mutationFn: createPost,
    onError: () => {
      Toast.show('Gagal membuat post', {
        backgroundColor: '#ef4444',
        textColor: '#fef2f2',
        opacity: 1,
      });
    },
    onSuccess: async (data) => {
      setNeedRefetch(true);
      Toast.show('Berhasil membuat post', {
        backgroundColor: '#22c55e',
        textColor: '#f0fdf4',
        opacity: 1,
      });
      console.log('why tf createpostSuccess', data);
      const createdAt = serverTimestamp();
      const notification = {
        title: 'Ada post baru',
        body: 'Baru saja mengetag kamu dalam postingan',
        appLink: `playard://group/${data.groupId}?postId=${data.postId}`,
        read: false,
        type: 'post',
        createdAt,
        iconUrl: '',
      };
      if (data.tagPlayerIDs) {
        saveNotificationToUsers(data.tagPlayerIDs, notification);
      }
      if (data.groupId != null) {
        sendPostPushNotification(data.postId, data.groupId ?? '', data.tagPlayerPushTokens);
      }

      queryClient.invalidateQueries({
        queryKey: [K_POST_GET_LAST_LOBBY, K_POST_GET_USER_LAST_LOBBY],
      });

      navigation.dispatch(StackActions.pop());
    },
  });
};

export const useQueryGetPostDetail = (postId: string) => {
  const { currentUser } = useAuth();
  return useQuery({
    queryKey: [K_POST_GET_DETAIL, currentUser?.uid ?? '0', postId],
    queryFn: async () => {
      let post, comments;
      try {
        post = await getPostByID(postId);
      } catch (e) {
        console.log('error getPostByID', e);
      }
      if (!post) {
        return null;
      }
      if (!post.commentsList) {
        return post;
      }
      try {
        comments = await getCommentsByIds(post.commentsList);
      } catch (e) {
        console.log('error getCommentsByIds', e);
      }
      console.log('post', post, 'comments', comments);
      return { ...post, comments };
    },
    enabled: !!currentUser?.uid,
  });
};

export const useQueryGetPostComments = (postId: string) => {
  const { currentUser } = useAuth();
  return useQuery({
    queryKey: [K_POST_GET_COMMENTS, currentUser?.uid ?? '0', postId],
    queryFn: async () => {
      let post, comments;
      try {
        post = await getPostByID(postId);
      } catch (e) {
        console.log('error getPostByID', e);
      }
      if (!post) {
        console.log('invalid post');
        return null;
      }
      if (!post.commentsList) {
        console.log('no commentList');
        return post;
      }
      try {
        comments = await getCommentsByIds(post.commentsList);
      } catch (e) {
        console.log('error getCommentsByIds', e);
      }
      console.log('post', post, 'comments', comments);
      return { comments };
    },
    enabled: !!currentUser?.uid,
  });
};

export const useQueryGetLobbyById = (lobbyId?: string) => {
  const { currentUser } = useAuth();
  return useQuery({
    queryKey: [K_POST_GET_LOBBY, currentUser?.uid ?? '0', lobbyId],
    queryFn: () => getLobbyById(lobbyId ?? ''),
    enabled: lobbyId !== undefined,
  });
};

export const useQueryGetUserByIds = (postId: string, userIds?: string[]) => {
  const { currentUser } = useAuth();
  return useQuery({
    queryKey: [K_POST_GET_BATCH_USERS, currentUser?.uid ?? '0', postId],
    queryFn: () => getUserByIds(userIds),
    enabled: userIds !== undefined,
  });
};

export const useMutateUpdateLike = (currentLikes: string[], postId: string) => {
  return useMutation({
    mutationFn: () => updateLike(currentLikes, postId),
    // onError: () => {
    //   Toast.show('Gagal like post', {
    //     backgroundColor: '#ef4444',
    //     textColor: '#fef2f2',
    //     opacity: 1,
    //   });
    // },
    // onSuccess: () => {
    //   Toast.show('Berhasil like post', {
    //     backgroundColor: '#22c55e',
    //     textColor: '#f0fdf4',
    //     opacity: 1,
    //   });
    // },
  });
};
