import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

import CreateLobbyBottomSheetPrompt from './CreateLobbyBottomSheetPrompt';
import useFetchUserData from '../../account/hooks/useFetchUserData';

import DynamicBottomSheetService from '@/modules/shared/components/DynamicBottomSheet/DynamicBottomSheetService';

export const CreateLobbyButton = () => {
  const { data } = useFetchUserData();

  if (!data?.groupAdmin) return null;

  const handleShow = () => {
    DynamicBottomSheetService.show({
      content: ({ close }) => (
        <CreateLobbyBottomSheetPrompt hide={close} groupId={String(data.groupAdmin)} />
      ),
    });
  };

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={handleShow}
      style={{
        position: 'relative',
        backgroundColor: '#5F48B9',
        borderRadius: 999,
        padding: 2,
      }}>
      <View
        style={{
          backgroundColor: '#5F48B9',
          borderWidth: 1.5,
          borderColor: '#fff',
          borderRadius: 999,
          alignItems: 'center',
          justifyContent: 'center',
          paddingHorizontal: 12,
          paddingVertical: 4,
        }}>
        <Text
          style={{
            textAlign: 'center',
            color: 'white',
            fontSize: 14,
            includeFontPadding: false,
            fontWeight: '500',
            textAlignVertical: 'center',
            lineHeight: 20,
          }}>
          <Text style={{ fontSize: 20 }}>+</Text> Lobi
        </Text>
      </View>
    </TouchableOpacity>
  );
};
