const includesAlternative = (stringToSearch: string, searchValue: string) =>
  stringToSearch.indexOf(searchValue) > -1;

export const checkInAppBrowser = () => {
  if (!window.navigator.userAgent) return false;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isInstagram =
    includesAlternative(userAgent, 'Instagram') || includesAlternative(userAgent, 'instagram');
  const isFacebook =
    includesAlternative(userAgent, 'FBAN') ||
    includesAlternative(userAgent, 'FBAV') ||
    includesAlternative(userAgent, 'FB_IAB') ||
    includesAlternative(userAgent, 'FB4A');
  const isTiktok =
    includesAlternative(userAgent, 'tiktok') ||
    includesAlternative(userAgent, 'bytedance') ||
    includesAlternative(userAgent, 'bytelo');
  const isLine =
    includesAlternative(userAgent, 'line') ||
    includesAlternative(userAgent, 'liff') ||
    includesAlternative(userAgent, '_line');
  const isInAppBrowser = isInstagram || isFacebook || isTiktok || isLine;
  console.log('userAgent', userAgent);
  console.log('isInAppBrowser', isInAppBrowser);
  return isInAppBrowser;
};
