import MiniSearch from 'minisearch';
import { createContext, useContext, useEffect, useMemo, useRef } from 'react';

import { getAllUsers } from '../../../firebase';

type AllUserContextType = {
  searchInstance: MiniSearch<any>;
};

//
export const AllUserContext = createContext<AllUserContextType>(undefined as never);

export const AllUserProvider = ({ children }: { children: React.ReactNode }) => {
  const miniSearch = useRef(
    new MiniSearch({
      fields: ['displayName', 'email'],
      idField: 'uid',
      storeFields: ['displayName', 'email', 'photoURL', 'uid', 'joinedLobbies'],
      autoVacuum: true,
    }),
  );

  useEffect(() => {
    const loadAllUsers = async () => {
      const users = await getAllUsers();
      const uniqueUsersMap = new Map(
        users.map((user) => [user.id, { ...user.data, uid: user.id }]),
      );
      miniSearch.current.addAll(Array.from(uniqueUsersMap.values()));
    };

    loadAllUsers();
  }, []);

  const values = useMemo<AllUserContextType>(() => ({ searchInstance: miniSearch.current }), []);

  return <AllUserContext.Provider value={values}>{children}</AllUserContext.Provider>;
};

export function useAllUsers() {
  const context = useContext(AllUserContext);
  if (!context) {
    throw new Error('useAllUsers must be used within a AllUserProvider');
  }
  return context;
}
